import React from 'react'
import { StyleSheet, View, Text, Dimensions, Pressable } from 'react-native'

// utils
import { colors, defaultStyles } from '../../../utils/constants'

// components
import Ionicons from '@expo/vector-icons/Ionicons'

export default (props) => {
  const { handleOnPress, icon, title, schema } = props;
  let colr = [];

  switch(schema) {
    case "google":
      colr = {
        primary: '#fc1515',
        secondary: '#db0505'
      }
    break;
    case "email":
      colr = {
        primary: '#009cff',
        secondary: '#008fe9'
      }
    break;
    case "apple":
      colr = {
        primary: '#000',
        secondary: '#333'
      }
    break;
  }
  return (
    <Pressable 
      onPress={() => handleOnPress()} 
      style={[styles.button, 
      {
        backgroundColor: colr.primary, 
        borderColor: colr.secondary
      }
    ]}>
      <View style={[styles.buttonIcon]}>
        <Ionicons name={icon} size={18} color='#fff' />
      </View>
      <View>
        <Text style={{textAlign: 'center', fontSize: 18, fontWeight: '700', color: colors.white}}>{title}</Text>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row', 
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: defaultStyles.borderRadius, 
    borderWidth: 1, 
    marginVertical: 12,
    paddingHorizontal: 24,
  },
  buttonIcon: {
    flexDirection: 'row',
    justifyContent:'center',
    height: 35,
    width: 35,
    alignItems: 'center',
    marginVertical: 8,
    borderRadius: 35,
  }
});