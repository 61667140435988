import React, { useEffect, useContext } from 'react'
import { StyleSheet, View, Text, Pressable, Linking } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons'
import { useNavigation } from '@react-navigation/native'
import { getDistance, convertDistance } from 'geolib'

import { defaultStyles, colors } from '../../utils/constants'
import { GeolocationContext } from '../../lib/context/Geolocation'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default (props) => {
  const {item, handleNavigtion, background, mapsLink } = props;
  const [LocationContext, setLocationContext] = useContext(GeolocationContext);
  const navigation = useNavigation();
  const { 
    name, 
    place_id,
    vicinity,
    geometry
  } = item;

  const distance = getDistance(
    { latitude: LocationContext.coords.latitude, longitude: LocationContext.coords.longitude },
    { latitude: geometry.location.lat, longitude: geometry.location.lng }
  );

  const dataload = {
    id: place_id,
    name: name,
    address: vicinity
  }

  return (
    <Pressable key={place_id} style={[styles.card, {backgroundColor: background || '#f9f9f9', flexDirection: 'row', justifyContent: 'space-between'}]} onPress={() => { mapsLink ? navigation.navigate('locationDetail', { id: place_id, name: name, address: vicinity, geometry: geometry }) : handleNavigtion(dataload) }}>
      <View style={{width: '90%'}}>
        <>
        <Text style={styles.title}>{name}</Text>
        <Text>{vicinity}</Text>
        <View style={{flexDirection:'row', marginTop: 6, justifyContent: 'flex-start'}}>
        {distance <= 350 && <Text style={{fontSize: 12, color: colors.gray}}>{convertDistance(distance, 'yd').toFixed(1)} yards</Text> }
        {distance >= 351 && <Text style={{fontSize: 12, color: colors.gray}}>{convertDistance(distance, 'mi').toFixed(2)} mi</Text> }
        </View>
        </>
      </View>
      {mapsLink && 
      <View style={[styles.button,{width: '10%'}]}>
        <Ionicons name="chevron-forward-outline" size={20} color="#eaeaea" />
      </View>}
    </Pressable>
  );
}

const styles = StyleSheet.create({  
  imageContainer: {
    height: 200,
    width: '100%',
  },
  profileImage: {
    height: 40,
    width: 40
  },
  card: {
    marginVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayLightest,
    padding: 16,
    borderRadius: defaultStyles.borderRadius,
    width: '100%',
  },
  title: {
    fontSize: 18,
    lineHeight: 22,
    marginBottom: 6,
    fontFamily: defaultStyles.interReg,
  },
  container: {
    paddingHorizontal: 8
  },
  button: {
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontWeight: "bold",
    marginRight: 8
  }
});