import React from 'react'
import { View, Text, Pressable, ScrollView, Dimensions } from 'react-native'
import { buttons } from '../../utils/constants'
import TermsAndConditions from '../../components/legal/terms'

export default ({ navigation }) => {
  return (
    <View style={{flex: 1, backgroundColor: '#fff'}}>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end', paddingVertical: 18, paddingHorizontal: 8 }}>
        <Pressable onPress={() => navigation.goBack()} style={buttons.primary}>
          <Text style={{ color: '#fff' }}>Close</Text>
        </Pressable>
      </View>
      <ScrollView style={{height: (Dimensions.get('window').height - 40)}} contentContainerStyle={{paddingHorizontal: 16, paddingBottom: 60}}>
        <TermsAndConditions />
      </ScrollView>
    </View>
  );
}