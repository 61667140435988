import { PottyMouthFireStore, PottyMouthAuth } from '../lib/firebase'
import { doc, setDoc, collection, updateDoc, getDoc, addDoc, deleteDoc, serverTimestamp } from 'firebase/firestore'
import { createUserWithEmailAndPassword, updateProfile, deleteUser } from 'firebase/auth'
import { uploadAsset } from './assets'
import { getUserReviews } from './getters'

import uuid from 'react-native-uuid'

const average = array => array.reduce((a, b) => a + b) / array.length;

const addNewRating = async (info) => {
  const { id, image } = info;
  const docRef = doc(PottyMouthFireStore, 'pottymouth-ratings', id);
  const docSnap = await getDoc(docRef);
  const itemId = uuid.v4();
  let totalReviews = 0;
  let downloadLink;

  if(!!image){
    try {
      downloadLink = await uploadAsset(image);
      info['image'] = downloadLink;
    } catch (err) {
    }
  }

  if (docSnap.exists()) {
    const d = docSnap.data();
    totalReviews = Object.entries(d.reviews).length;
    const fieldName = `reviews.${itemId}`;
   
    await updateDoc(docRef, {
      [fieldName]: {
        timestamp: serverTimestamp(),
        ...info
      }
    })

  } else {
    await setDoc(doc(PottyMouthFireStore, 'pottymouth-ratings', id), {
      "reviews": {
        [itemId]: {
          ratings: {
            cleaniness: info.ratings.cleaniness,
            overall: info.ratings.overall,
            privacy: info.ratings.privacy,
            stall: info.ratings.stall,
            tpquality: info.ratings.tpquality,
          },
          timestamp: serverTimestamp(),
          flagged: false,
          ...info
        }
      }
    });
  }
  updatedRatings(id);
  updateUserReviews({
    reviewId: itemId,
    ...info
  })
  return {text: 'complete', status: 200, id: id, total: (totalReviews + 1)}
}

const updatedRatings = async (id) => {
  const docRef = doc(PottyMouthFireStore, 'pottymouth-ratings', id);
  const docSnap = await getDoc(docRef);
  let ratings_cleaniness = [];
  let ratings_overall = [];
  let ratings_privacy = [];
  let ratings_stall = [];
  let ratings_tpquality = [];
  
  if (docSnap.exists()) {
    const d = docSnap.data();

    Object.keys(d.reviews).map((parentId) => {
      // loop through all the reviews of this location
      const ratingsItem = d.reviews[parentId].ratings;
      ratings_cleaniness.push(ratingsItem.cleaniness);
      ratings_overall.push(ratingsItem.overall);
      ratings_privacy.push(ratingsItem.privacy);
      ratings_stall.push(ratingsItem.stall);
      ratings_tpquality.push(ratingsItem.tpquality);
    });

    const average_cleaniness = average(ratings_cleaniness);
    const average_overall = average(ratings_overall);
    const average_privacy = average(ratings_privacy);
    const average_stall = average(ratings_stall);
    const average_tpquality = average(ratings_tpquality);

    await updateDoc(docRef, {
      ratings: {
        cleaniness: average_cleaniness,
        overall: average_overall,
        privacy: average_privacy,
        stall: average_stall,
        tpquality: average_tpquality
      }
    });

  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
}
const updateFields = async (uid, fields) => {
  const docRef = doc(PottyMouthFireStore, 'pottymouth-users', uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    await updateDoc(docRef, {...fields})
  } else {
    console.log("Document not found")
  }

  return {text: 'complete', status: 200}
}

const addNewUserByEmail = (formData) => {
  const { email, password, displayName } = formData;

  createUserWithEmailAndPassword(PottyMouthAuth, email, password)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    // update profile so it gets added to firebase db
    updateProfile(PottyMouthAuth.currentUser, {
      displayName: displayName, photoURL: "", phoneNumber: null
    });
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  });
}

const updateUserReviews = async (data) => {
  const docRef = doc(PottyMouthFireStore, 'pottymouth-user-reviews', data.user.id);
  const userRef =  doc(PottyMouthFireStore, 'pottymouth-users', data.user.id);
  const docSnap = await getDoc(docRef);
  const userDocSnap = await getDoc(userRef);

  const userReviews = await getUserReviews(data.user.id);
  const userAverage = [data.ratings.overall];
  const totalReviews = (userReviews?.length || 0) + 1;

  if (docSnap.exists()) {
    await updateDoc(docRef, {
      [uuid.v4()]: {
        ...data,
        timestamp: serverTimestamp()
      }
    })
  } else {
    console.log("Document not found")
  }

  if(userReviews?.length) userReviews.map((review) => userAverage.push(review?.ratings?.overall))
  if (userDocSnap.exists()) {
    await updateDoc(userRef, {
        total: totalReviews,
        average: average(userAverage)
    })
  } else {
    console.log("Document not found")
  }

  return {text: 'complete', status: 200}
}

const addFeedback = async (info) => {
  const { uid, handle, displayName, message } = info;

  await addDoc(collection(PottyMouthFireStore, 'pottymouth-feedback'), {
    user_name: displayName,
    user_handle: handle,
    feedback_message: message,
    user_id: uid,
    timestamp: serverTimestamp(),
  });
  
  return {text: 'complete', status: 200}
}

const deleteAccount = async (uid) => {
  await deleteDoc(doc(PottyMouthFireStore, "pottymouth-user-reviews", uid));
  await deleteDoc(doc(PottyMouthFireStore, "pottymouth-users", uid));
  const user = PottyMouthAuth.currentUser;

    deleteUser(user).then(() => {
      // User deleted.
      console.log("user deleted")
    }).catch((error) => {
      // An error ocurred
      // ...
    });

    return {text: 'complete', status: 200}
}

const updateUserIfNotExist = async (data, flag) => {
  const { uid, displayName, email, phoneNumber, photoURL, providerId } = data[0];

  try {
    const docRef = doc(PottyMouthFireStore, 'pottymouth-users', uid);
    const docSnap = await getDoc(docRef);
    const userInfo = { 
      displayName: displayName,
      email: email || null,
      firstReview: true,
      firstTime: true,
      handle: '',
      phoneNumber: phoneNumber || null,
      photoURL: photoURL || "",
      providerId: providerId,
      average: 0,
      total: 0,
      uid: uid
    }

    if (docSnap.exists()) {
       return docSnap.data();
    } else {
      await setDoc(docRef, userInfo);
      await setDoc(doc(PottyMouthFireStore, 'pottymouth-user-reviews', uid), { 
        createdAt: serverTimestamp()
      });
      return userInfo;
    }
  } catch (error) {
    console.log('getUserRecord error:',error);
  } 
}

const addNewSticker = async (data) => {
  await addDoc(collection(PottyMouthFireStore, 'pottymouth-stickers'), {
      timestamp: serverTimestamp(),
      ...data
  });
  return {text: 'complete', status: 200 }
}
export { addNewRating, updatedRatings, updateFields, addNewUserByEmail, addFeedback, deleteAccount, updateUserIfNotExist, addNewSticker };