import React, {useEffect, useState, useContext} from 'react'
import { StyleSheet, View, Text, Pressable, Image, Dimensions, Linking } from 'react-native'
import { circleImage, circleImageSmall, defaultStyles, colors, buttons, fallbackImage} from '../../utils/constants'
import { GeolocationContext } from '../../lib/context/Geolocation'
import { getLatestReview, getUserRecord } from '../../data/getters'
import StarRating from 'react-native-star-rating-widget'
import Ionicons from '@expo/vector-icons/Ionicons'
import TimeAgo from '@andordavoti/react-native-timeago'
import { getDistance, convertDistance } from 'geolib'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default (props) => {
  const {id, name, address, geometry, navigation } = props;
  const [review, setReview] = useState();
  const [dateFormat, setDateFormat] = useState();
  const [reviewerInfo, setReviewerInfo] = useState();
  const [LocationContext, setLocationContext] = useContext(GeolocationContext);
  const distance = getDistance(
    { latitude: LocationContext.coords.latitude, longitude: LocationContext.coords.longitude },
    { latitude: geometry.location.lat, longitude: geometry.location.lng }
  );

  const GenderIcon = () => {
    if(!!review?.gender) {
      let icon;
      switch(review.gender) {
        case "women":
          icon = "woman-outline";
        break;
        case "men":
          icon = "man-outline";
        break;
        case "unisex":
          icon = "people-outline";
        break;
        case "family":
          icon = "basket-outline";
        break;
      }

      return (
        <View style={styles.restroomTypeWrapper}>
          <View style={styles.restroomTypeContainer}>
            <Ionicons name={icon} size={18} color={colors.gray} />
          </View>
          <Text style={{fontSize: 10, textAlign: 'center', color: colors.graySecondary}}>{review.gender}</Text>
        </View>
      )
    }
  }

  const getLocationLastestReview = async () => {
   const reviewInfo = await getLatestReview(id);
   if(reviewInfo[0]) {
    reviewInfo['ratings'] = reviewInfo[0]?.ratings;
   }
   setReview(reviewInfo);
  }

  useEffect(() => {
    getLocationLastestReview();
  },[]);
  
  useEffect(() => {
    if(!!review) {
      //console.log(review);
      const date = (review?.timestamp?.seconds * 1000);
      setDateFormat(date);

      const getReviewerInfo = async () => {
        const userInfo = await getUserRecord([{ uid: review[0]?.user?.id}]);
        setReviewerInfo(userInfo);
      }
      getReviewerInfo();
    }
  },[review]);

  return (
    <View style={ {flexDirection: 'row', justifyContent: 'center'}}>
      <View style={styles.card}>
        <GenderIcon />
        <View style={{paddingTop: 8, paddingBottom: 4, flexDirection:"row", alignItems: "center"}}>
          <View style={[circleImage,circleImageSmall, {marginRight: 4}]}>
            <Image source={{ uri: reviewerInfo?.photoURL || fallbackImage }} style={styles.profileImage} />
          </View> 
          <View>
            <Text style={{fontSize: 18, textTransform: 'lowercase', fontFamily: defaultStyles.interReg}}>
            {reviewerInfo?.handle || 'account removed'}
            </Text>
            <Text style={{fontSize: 10, color: colors.graySecondary, textTransform: 'capitalize'}}>Reviews: {reviewerInfo?.total} : Average Rating: {reviewerInfo?.average.toFixed(1)}</Text>
          </View>
        </View>
        <View style={[{flexDirection: "row", justifyContent:"flex-start", alignItems: "center", marginTop: 12, marginBottom: 4}]}>
          <View>
            <Text style={{fontWeight: "600", textTransform: "uppercase", fontSize: 24}}>{name}</Text>
            <Text style={{fontSize: 16}}>{address}</Text>
            <View style={{flexDirection: 'row', alignItems: 'center', marginTop: 4}}>
              <Ionicons name="location" size={14} color={colors.gray} />
              <Text style={{marginLeft: 2, fontSize: 12, color: colors.gray}}>{convertDistance(distance, 'mi').toFixed(1)} mi</Text>
            </View>
          </View>
        </View>
        <View style={{marginVertical: 8}}>
          <StarRating
            disabled={true}
            enableSwiping={false}
            maxStars={5}
            enableHalfStar={true}
            rating={review?.ratings?.overall || review?.ratings[0].overall}
            starSize={26}
            emptyStarColor={colors.gray}
            starStyle={{width: 5, marginLeft: 0, marginRight: 24}}
            onChange={() => {}}
            color={colors.primary}
          />
        </View>
        {review?.title &&
          <View style={{marginTop:16}}>
            <Text style={[styles.title]}>{review.title}</Text>
          </View>
        }
        {!!review?.image && <View style={{marginBottom: 8}}>
          <Image resizeMode={'cover'} source={{ uri: review?.image, cache: 'force-cache' }} style={styles.imageContainer} />
        </View> }

        {!!review?.description && 
        <View style={{paddingVertical:8}}>
          <Text style={{fontSize: 16, lineHeight: 21, fontFamily: defaultStyles.interReg}}>{review?.description}</Text>
        </View>
        }
        <View>
          <Text style={{fontSize: 10, color: colors.graySecondary, textTransform: 'uppercase'}}>Cleanliness: {review?.ratings?.cleaniness} | Privacy: {review?.ratings?.privacy} | Stall: {review?.ratings?.stall} | Toiletries: {review?.ratings?.tpquality}</Text>
        </View>
        <View style={{flexDirection: "row", justifyContent: "flex-start", marginTop: 16}}>
          <Pressable style={buttons.primary} onPress={() => navigation.navigate('locationDetail', {id: id, name: name, address: address, totalreviews: review?.totalreviews, geometry: geometry})}>
            <Text style={{color: "#fff"}}>View Report Card</Text>
          </Pressable>
          <Pressable style={buttons.primary} onPress={() => { Linking.openURL(`maps://?address=${encodeURIComponent(address)}&dirflg=r`)}}>
            <Text style={{color: "#fff"}}>Get Directions</Text>
          </Pressable>
        </View>

       {!!dateFormat && <Text style={{fontSize: 12, color: colors.graySecondary, paddingVertical: 8, marginTop: 4}}>
          <TimeAgo dateTo={new Date(dateFormat)} />
        </Text>
        }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({  
  imageContainer: {
    height: 200,
    width: "100%"
  },
  profileImage: {
    height: 40,
    width: 40,
  },
  subhead: {
    fontFamily: defaultStyles.headingFont,
    fontSize: defaultStyles.headerSize_3,
    textTransform: "uppercase"
  },
  card: {
    marginVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayLightest,
    padding: 16,
    borderRadius: defaultStyles.borderRadius,
    width: (Dimensions.get("window").width - 20),
    backgroundColor: '#fff',
  },
  cardBody: {
    fontSize: 21,
    lineHeight: 26
  },
  title: {
    fontSize: 20,
    lineHeight: 24,
    fontFamily: defaultStyles.interReg,
  },
  container: {
    paddingHorizontal: 8
  },
  restroomTypeContainer: {
    textAlign: 'center',
    height: 40,
    width: 40,
    borderRadius: defaultStyles.borderRadius,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.grayLightest,
  },
  restroomTypeWrapper: {
    width: 40,
    position: 'absolute',
    top: 12,
    right: 12
  }
});