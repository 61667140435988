import React from 'react'
import { Text } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons'

export default (props) => {
  return (
    <>
      <Ionicons name="arrow-back-outline" size={24} color="#000" />
      <Text style={{fontSize: 18, marginLeft: 4, color: '#000'}}> Back</Text>
    </>
  )
}