import React, { useState, useEffect } from 'react'
import { StyleSheet, View, Text, Pressable, ScrollView } from 'react-native'
import SimpleCardItem from '../components/cards/simpleCardItem'
import { getEstablishmentReviews } from '../data/getters'
import { buttons } from '../utils/constants'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default ({route, navigation}) => {
  const { id, name } = route.params;
  const [reviews, setReviews] = useState();

  useEffect(() => {
    const runAsync = async () => {
      const getAllReviews = await getEstablishmentReviews(id);
      setReviews(getAllReviews);
    }
    runAsync();
  },[])

  return (
    <View style={styles.container}>
      <View style={
        {
          width: '100%', 
          flexDirection: 'row', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          paddingHorizontal: 10, 
          paddingVertical: 32
        }}>
        <Text style={{fontSize: 14}}>{name}</Text>
        <Pressable onPress={() => navigation.goBack()} style={[buttons.primary, {marginRight: 0, width: '25%'}]}>
          <Text style={{color: '#fff'}}>Close</Text>
        </Pressable>
      </View>
      <ScrollView 
      contentContainerStyle={{paddingBottom: 120}}
      vertical={true}
      alwaysBounceVertical={false}
      showsVerticalScrollIndicator={false}
      >
        {
          !!reviews && Object.entries(reviews).map((item) => {
            const entryId = item[0];
            const location = item[1];
            return <SimpleCardItem key={entryId} {...location} />
          })
        }
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
    paddingVertical: 4
  }
});