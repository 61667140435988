import React, { useEffect, useState } from 'react'
import {ActivityIndicator, View} from 'react-native'
import { PottyMouthAuth } from '../firebase'
import { updateUserIfNotExist } from '../../data/setters'
//import { signOut } from "firebase/auth";
import { getLocalItem } from '../localStorage'
import { colors } from '../../utils/constants'
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);

  /*signOut(PottyMouthAuth).then(() => {
    // Sign-out successful
    }).catch((error) => {
      // An error happened.
    });*/
  // Handle user state changes
  async function onAuthStateChanged(profileData)  {
    let flag = false;
    let uid;
    const deviceId = await getLocalItem('deviceId');
    if(!!profileData) {
      if(profileData?.providerData[0]?.providerId == 'google.com' || profileData?.providerData[0]?.providerId == 'password') {
        uid = profileData?.uid;
      } else {
        const appleProfile = JSON.parse(JSON.stringify(profileData));
          uid = deviceId;
          profileData.providerData[0] = {
            displayName: appleProfile.displayName || "Unknown Name",
            email: appleProfile.email || "anonymous",
            phoneNumber: appleProfile.phoneNumber || null,
            photoURL: appleProfile.photoURL || '',
            providerId: 'apple.com',
            uid: deviceId
          }
          flag = true;
      }
      profileData.providerData[0]['uid'] = uid;
      const userDbData = await updateUserIfNotExist(profileData.providerData, flag);
      profileData.providerData[0]['photoURL'] = userDbData.photoURL;
      profileData.providerData[0]['displayName'] = userDbData.displayName;
      profileData.providerData[0]['firstTime'] = userDbData.firstTime;
      profileData.providerData[0]['firstReview'] = userDbData.firstReview;
      profileData.providerData[0]['handle'] = userDbData.handle;
    }
    setCurrentUser(profileData);
    if (pending) setPending(false);
  }

  useEffect(() => {
    const subscriber = PottyMouthAuth.onAuthStateChanged(onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, []);


  if(pending){
    return (
    <View style={[{position: 'absolute', top: 300, flexDirection: 'row', justifyContent: 'center', alignItems:'center', width: '100%', zIndex: 10, flexWrap: 'wrap', backgroundColor:'#fff', paddingVertical: 40}]}>
      <ActivityIndicator size="large" color={colors.primary} />
    </View>
    );
  }
  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
