import React from 'react'
import { View, Text, Pressable, ScrollView, Dimensions, Linking, StyleSheet } from 'react-native'
import { buttons, colors } from '../../utils/constants'
import { defaultStyles } from '../../utils/constants'

export default ({ navigation }) => {
  return (
    <View style={{flex: 1, backgroundColor: '#fff'}}>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-end', paddingVertical: 18, paddingHorizontal: 8 }}>
        <Pressable onPress={() => navigation.goBack()} style={buttons.primary}>
          <Text style={{ color: '#fff' }}>Close</Text>
        </Pressable>
      </View>
      <ScrollView style={{height: (Dimensions.get('window').height - 40)}} contentContainerStyle={{paddingHorizontal: 16, paddingBottom: 60}}>
        <Text style={styles.title}>illustrations</Text>
        <Text style={{fontSize: 16, lineHeight: 19}}>Illustrations used in the Pottymouth app and website are provided by <Pressable onPress={() => Linking.openURL('https://www.freepik.com/')} style={{marginTop: -4, padding: 0}}><Text style={{fontSize: 16, lineHeight: 21, textDecorationLine: 'underline'}}>Freepik</Text></Pressable>. We have legal free use of illustration for non-print material with attribution as outlined.</Text>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  title: {
    fontFamily: defaultStyles.interSemi,
    fontSize: 36,
    marginTop: 32,
    marginBottom: 16,
    paddingRight: 32
  }
});