import 'firebase/auth'
import 'firebase/storage'
import { getFirestore} from 'firebase/firestore'
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics';
import Constants from 'expo-constants'

// Initialize Firebase
const firebaseConfig = {
  appId: Constants.expoConfig.extra.firebaseAppId,
  apiKey: Constants.expoConfig.extra.firebaseApiKey,
  authDomain: 'primary-4a0e7.firebaseapp.com',
  databaseURL: 'https://primary-4a0e7.firebaseio.com',
  projectId: 'primary-4a0e7',
  storageBucket: 'primary-4a0e7.appspot.com',
  messagingSenderId: '761783469358',
  measurementId: "G-NJNB0JRR09"
}

const firebaseApp = initializeApp(firebaseConfig);
const PottyMouthFireStore = getFirestore();
const PottyMouthAuth = getAuth(firebaseApp);
/*
const PottyMouthAnalytics = getAnalytics(firebaseApp);
*/
export  { firebaseApp, PottyMouthFireStore, PottyMouthAuth }