import React, { useState } from "react";
export const GeolocationContext = React.createContext();

export const GeolocationProvider = ({ children }) => {
  const [currentLocation, setCurrentLocation] = useState({});
  return (
    <GeolocationContext.Provider
      value={[currentLocation, setCurrentLocation]}
    >
      {children}
    </GeolocationContext.Provider>
  );
};