import React, { useContext, useState, useEffect } from 'react'
import { StyleSheet, View, ActivityIndicator, Text, Pressable, SafeAreaView, ScrollView, Image, Dimensions, Platform } from 'react-native'

import Ionicons from '@expo/vector-icons/Ionicons'

import StaticCardItem from '../components/cards/staticCardItem'
import localImage from '../components/images'

import { getUserReviews } from '../data/getters'
import { buttons, colors, defaultStyles } from '../utils/constants'
import { AuthContext } from '../lib/context/Auth'
import BackButton from '../components/global/buttons/Back'


Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default ({route, navigation}) => {
  const { currentUser } = useContext(AuthContext);
  const [reviews, setReviews] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const uiPadding = Platform.OS === 'ios' ? 0 : 30;
  let numRes = 0;

  useEffect(() => {
    const runAsync = async () => {
      setIsLoading(true);
      const getAllReviews = await getUserReviews(currentUser?.providerData[0]?.uid);
      setReviews(getAllReviews);
      setIsLoading(false);
    }
    runAsync();
  },[])

  return (
    <SafeAreaView style={[styles.container, {paddingTop: uiPadding}]}>
      {!!isLoading && <View style={[{position: 'absolute', top: 300, flexDirection: 'row', justifyContent: 'center', width: '100%'}]}><ActivityIndicator size="large" color={colors.primary} /></View> }
      <View style={[{flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 4, marginBottom: 16}]}>
        <Pressable onPress={() => navigation.goBack()} style={{flexDirection: "row", alignItems: "center"}}>
          <BackButton />
        </Pressable>
        <View style={{paddingRight: 8}}>
          <Text>Your Reviews</Text>
        </View>
      </View>
      <ScrollView 
      contentContainerStyle={{paddingBottom: 120}}
      vertical={true}
      alwaysBounceVertical={false}
      showsVerticalScrollIndicator={false}
      >
        {
          !isLoading && !!reviews && Object.entries(reviews).map((item) => {
            const entryId = item[0];
            const location = item[1];
            if(location.user) {
              numRes = numRes + 1;
              return <StaticCardItem key={entryId} navigation={navigation} {...location} />
            }
          })
        }
        {
          !isLoading && numRes == 0 &&
          <View style={styles.container}>
            <View style={{flexDirection: 'row', justifyContent: 'center', marginVertical: 16}}>
              <Image resizeMode={'contain'} defaultSource={localImage.default_profile} style={styles.empty_set} />
            </View>
            <Text style={styles.title}>Looks like you haven't posted any reviews!</Text>
            <Text style={{textAlign:'center', marginVertical: 8}}>Get out there and get posting. If you need help finding a place tap the button below</Text>
            <Pressable style={[buttons.primary, {paddingVertical: 16, marginTop: 16}]} onPress={() => navigation.navigate('create', {id: null, address: null, name: null, geometry: null })}>
              <Text style={{color: '#fff'}}>Get Started</Text>
            </Pressable>
          </View>
        }
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 4,
    position: 'relative',
    backgroundColor: colors.background
  },
  title: {
    fontSize: 26,
    lineHeight: 31,
    marginBottom: 6,
    fontFamily: defaultStyles.interSemi,
    marginVertical: 16,
    textAlign: 'center'
  },
  empty_set: {
    width: (Dimensions.get('window').width / 2),
    height: (Dimensions.get('window').width / 2)
  }
});