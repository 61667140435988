import { PottyMouthFireStore } from '../lib/firebase'
import { doc, getDoc, getDocs, collection, serverTimestamp, query, where, orderBy, documentId } from 'firebase/firestore'
import ChunkArray from '../utils/chunk';

const sortByTimeStampDate = (data) => {
  const sortedArray = [];
  Object.entries(data).map((item) => {
    const reviewItem = item[1];
    if(!reviewItem.id) {
      return;
    }
    const date = new Date((reviewItem?.timestamp?.seconds * 1000));
    const payload = {
      date: date,
      ...reviewItem
    }
    sortedArray.push(payload);
  });
  
  sortedArray.sort(function(a,b){
    return new Date(b.date) - new Date(a.date);
  });

  return sortedArray;
}

const sortByTimeStampDatePrimaryKey = (data) => {
  const sortedArray = [];
  Object.entries(data).map((item) => {
    
    const reviewItem = item[1];
    if(!reviewItem.locationId) {
      return;
    }
    const date = new Date((reviewItem?.timestamp?.seconds * 1000));
    const payload = {
      date: date,
      ...reviewItem
    }
    sortedArray.push(payload);
  });
  
  sortedArray.sort(function(a,b){
    return new Date(b.date) - new Date(a.date);
  });

  return sortedArray;
}

const getAllReviewedIds = async (googleData) => {
  if(!!googleData) {
    const allIds = googleData?.map((item) => item.place_id);
    const arrayChunk = ChunkArray(allIds);
    let matchedResults = [];
    const promises = arrayChunk.map(async (ids) => {
      const res = query(collection(PottyMouthFireStore, 'pottymouth-ratings'), where(documentId(), 'in', ids));
      const querySnapshot = await getDocs(res);
      querySnapshot.forEach((doc) => {
        matchedResults = [...matchedResults, doc.id];
      });
    })
    return Promise.all(promises).then(() => matchedResults)
  }
  
}

const getLatestReview = async (id) => {
  const docRef = doc(PottyMouthFireStore, 'pottymouth-ratings', id);
  const docSnap = await getDoc(docRef);
  const sortedArray = [];
  let selectedReview = [];

  if (docSnap.exists()) {
    const d = docSnap.data();
    const totalReviews = Object.entries(d.reviews).length;
    const reviewsByDate = sortByTimeStampDate(d.reviews);
    selectedReview.push(reviewsByDate[0]);
    selectedReview['totalreviews'] = totalReviews;
    return selectedReview;
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
}

const getReviewCount = async (id) => {
  const docRef = doc(PottyMouthFireStore, 'pottymouth-ratings', id);
  const docSnap = await getDoc(docRef);
  
  if (docSnap.exists()) {
    const d = docSnap.data();
    return Object.entries(d.reviews).length
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    return 0
  }
}

const getRatingScoresById = async (id) => {
  const docRef = doc(PottyMouthFireStore, 'pottymouth-ratings', id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const d = docSnap.data();
    const t = Object.keys(d.reviews).length;

    const payload = {
      total: t,
      ratings: d.ratings
    }
    return payload;
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    const payload = {
      total: 0,
      ratings: null
    }
    return payload;
  }
}

const getEstablishmentReviews = async (id) => {
  const docRef = doc(PottyMouthFireStore, 'pottymouth-ratings', id);
  const docSnap = await getDoc(docRef);
  const sortedArray = [];
  let mostrecent = 0;

  if (docSnap.exists()) {
    const d = docSnap.data();
    const reviewsByDate = sortByTimeStampDate(d.reviews);
    return reviewsByDate;
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
}

const getUserRecord = async (data) => {
  const { uid } = data[0];

  try {
    const docRef = doc(PottyMouthFireStore, 'pottymouth-users', uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
       return docSnap.data();
    } else {
      return {text: 'user-record', status: 404}
    }
  } catch (error) {
    console.log('getUserRecord error:',error);
  }
  
}

const getUserHandleValidation = async (userhandle) => {
  const userRef = collection(PottyMouthFireStore, "pottymouth-users");
  const q = query(userRef, where("handle", "==", userhandle));
  const querySnapshot = await getDocs(q);
  let isValid = true;

  querySnapshot.forEach((doc) => {
    //console.log(doc.id, " => ", doc.data());
    isValid = false;
  });

  return isValid;
}

const getExisitingEmailValidation = async (email) => {
  const userRef = collection(PottyMouthFireStore, "pottymouth-users");
  const q = query(userRef, where("email", "==", email));
  const querySnapshot = await getDocs(q);
  let emailExists = false;

  querySnapshot.forEach((doc) => {
    //console.log(doc.id, " => ", doc.data());
    emailExists = true;
  });

  return emailExists;
}

const getUserReviews = async (uid) => {
  try {
    const docRef = doc(PottyMouthFireStore, 'pottymouth-user-reviews', uid);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const d = docSnap.data();
      const sortedPayload = sortByTimeStampDate(d);
      return sortedPayload;
    }
  } catch (error) {
    console.log('getUserRecord error:',error);
  }
}

const getByFilter = async (props) => {
  const { category, type } = props;
  const otwRef = collection(PottyMouthFireStore, "pottymouth-wow");
  let data;
  // Create a query against the collection.
  const q = query(otwRef, where("active", "==", true), where ("category", "==", category), where ("type", "==", type));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data = doc.data();
  });
  return data;
}

const getLocationByStickerId = async (id) => {
  const stickerRef = collection(PottyMouthFireStore, "pottymouth-stickers");
  let data;
  // Create a query against the collection.
  const q = query(stickerRef, where ("stickerId", "==", id));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    data = doc.data();
  });
  return data;
}

const getStickerLocations = async () => {
  let data = [];
  const querySnapshot = await getDocs(collection(PottyMouthFireStore, "pottymouth-stickers"));
  querySnapshot.forEach((doc) => {
    const resData = doc.data();
    data.push(resData);
  });
  return sortByTimeStampDatePrimaryKey(data);
}

const getAllUsers = async () => {
  let data = [];
  const querySnapshot = await getDocs(collection(PottyMouthFireStore, "pottymouth-users"));
  querySnapshot.forEach((doc) => {
    const resData = doc.data();
    data.push(resData);
  });
  return data;
}
const getAllReviewLocations = async () => {
  let data = [];
  const querySnapshot = await getDocs(collection(PottyMouthFireStore, "pottymouth-ratings"));
  querySnapshot.forEach((doc) => {
    const resData = doc.data();
    data.push(resData);
  });
  return data;
}

export { 
  getAllReviewedIds,
  getLatestReview,
  getRatingScoresById,
  getEstablishmentReviews,
  getUserRecord,
  getUserHandleValidation,
  getExisitingEmailValidation,
  getUserReviews,
  getReviewCount,
  getByFilter,
  getLocationByStickerId,
  getStickerLocations,
  getAllUsers,
  getAllReviewLocations
};