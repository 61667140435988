import React, { useEffect } from 'react'
import { View } from 'react-native'
import ReviewsListItem from './reviewsListItem'


export default (props) => {
  const { onLocationSelect, itemsData, condensed, reviewsids } = props; 
  useEffect(() => {

  },[props]);

  return (
    <View style={{paddingBottom: 24}}>
      {itemsData && itemsData.map((item, index) => {
        if(condensed && reviewsids?.includes(item.place_id)) {
          return;
        }
        return (
          <ReviewsListItem
            key={item.place_id}
            handleNavigtion={(i) => { onLocationSelect(i)}}
            item={item}
            background='#fff'
            mapsLink={condensed}
          />
        )
      })}
    </View>
  );
}