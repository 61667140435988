import Ionicons from '@expo/vector-icons/Ionicons'
import React, {useEffect, useState, useContext, useRef} from 'react'
import axios from 'axios'
import { View, Text, TextInput, Pressable, ActivityIndicator, Platform, Linking, AppState } from 'react-native'

import * as Location from 'expo-location'
import { GeolocationContext } from '../../lib/context/Geolocation'
import { NearbyLocationsContext } from '../../lib/context/NearbyLocations'
import Constants from 'expo-constants';

// components
import { buttons, colors, defaultStyles, formStyles } from '../../utils/constants'
import ReviewsList from '../../components/reviews/reviewsList'
import Cards from '../cards/index'

import {setLocalObjectValue, getLocalObjectValue, removeLocalStorageItem} from '../../lib/localStorage'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.
TextInput.defaultProps = Text.defaultProps || {};
TextInput.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.


export default (props) => {
  const googleApiKey = Constants.expoConfig.extra.googleApiKey;
  const {background, resultsType, onLocationSelect, navigation, containerBackground, onUpdateComplete} = props;
  const [LocationContext, setLocationContext] = useContext(GeolocationContext);
  const [NearbyLocations, setNearbyLocations] = useContext(NearbyLocationsContext);
  const appState = useRef(AppState.currentState);

  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [appStateVisible, setAppStateVisible] = useState(appState.current);
  const [locationLoading, setLocationLoading] = useState(true);
  const [localAddress, setLocalAddress] = useState();

  const geoCodeAddress = async (force) => {
    const cachedLocation = await getLocalObjectValue('lastlocation');
    const savedAddress = await getLocalObjectValue('addressFromGeoCode');
    if(!force && !LocationContext?.coords?.latitude || !force && !LocationContext?.coords?.longitude) {
      return;
    }
    //console.log("geo code called", cachedLocation?.coords?.latitude, LocationContext?.coords?.latitude, force);
    if(!force && cachedLocation?.coords?.latitude == LocationContext?.coords?.latitude && cachedLocation?.coords?.longitude == LocationContext?.coords?.longitude) { 
      if(!!savedAddress && Object.keys(savedAddress).length) {
        setLocalAddress(savedAddress);
        return;
      }
      return;
    }
    // console.log("condition not met", cachedLocation, savedAddress);
    const geoLocationVals = !!force ? force : LocationContext;
    axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${geoLocationVals?.coords?.latitude},${geoLocationVals?.coords?.longitude}&key=${googleApiKey}`, 
      [{}],
      {
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Access-Control-Allow-Origin': '*',
        }
      }
    ).then(res => {
      console.log("$$ google geocode");
      const locationCenter = res.data.results.filter((item) => item.geometry.location_type == "GEOMETRIC_CENTER");
      let nearbyAddress = {};
      locationCenter[0]?.address_components.map((option) => {
        if(option.types.includes("route")) {
          nearbyAddress["street"] = option.short_name
        }
        if(option.types.includes("locality")) {
         nearbyAddress["city"] = option.short_name
        }
        if(option.types.includes("administrative_area_level_1")) {
          nearbyAddress["state"] = option.short_name
        }
        if(option.types.includes("postal_code")) {
          nearbyAddress["zip"] = option.short_name
        }
      });
      nearbyAddress["lat"] = LocationContext?.coords?.latitude;
      nearbyAddress["lon"] = LocationContext?.coords?.longitude;
      setLocalObjectValue('addressFromGeoCode',nearbyAddress);
      setLocalAddress(nearbyAddress);
      setIsLoading(false);
    }).catch(err => { 
        console.log(err);
        setIsLoading(false);
    });
  }
  const performGoogleSearch = (forcedLocation) => {
    const params = `&keyword=${encodeURIComponent(searchValue.toLowerCase())}&rankby=distance`;
    const locationObj = forcedLocation || LocationContext;
    if(!locationObj?.coords?.latitude && !locationObj?.coords?.longitude) {
      return
    }
    const delayDebounceFn = setTimeout(() => {
      const placeesURL = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${locationObj?.coords?.latitude},${locationObj?.coords?.longitude}${params}&key=${googleApiKey}`;
      if(Platform.OS === 'ios'){
        axios.get(placeesURL, 
          [{}],
          {
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Access-Control-Allow-Origin': '*',
            }
          }
          ).then( async (res) => {
            console.log('$$$ google request ran');
            if(searchValue?.length > 0) {
              setSearchResults(res.data.results)
            } else {
              setNearbyLocations(res.data.results);
              await setLocalObjectValue('searchresults', res.data.results);
            }
            setIsLoading(false);
            if(onUpdateComplete) onUpdateComplete();
            return;
        }).catch(err => { 
            console.log(err);
            setIsLoading(false);
            if(onUpdateComplete) onUpdateComplete();
        });
      } else {
        console.log("make request", locationObj?.coords?.latitude);

        axios.post('https://pottymouth-web-proxy.vercel.app/places/', {
            location: `${locationObj?.coords?.latitude},${locationObj?.coords?.longitude}`,
            params: params
          },
          {
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
              'Access-Control-Allow-Origin': '*',
            }
          }).then(res => {
          if(searchValue.length) {
            setSearchResults(res.data.results)
          } else {
            setNearbyLocations(res.data.results);
            setLocalObjectValue('searchresults', res.data.results);
          }
          setIsLoading(false);
          if(onUpdateComplete) onUpdateComplete();
            return;
        }).catch(err => { 
            setIsLoading(false);
            if(onUpdateComplete) onUpdateComplete();
        });
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn)
  }
  const validateLocation = async () => {
    if(!LocationContext.coords) {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== 'granted') {
        return;
      }
    }
    let location = await Location.getCurrentPositionAsync({});
    let locationEnabled = await Location.hasServicesEnabledAsync();
    
    if(location && !locationEnabled) {
      setLocationLoading('not-found');
      return;
    } else {
      setLocationLoading(false);
    }

    // if location is the same, use cache / context
    const cachedLocation = await getLocalObjectValue('lastlocation');
    const cachedResults = await getLocalObjectValue('searchresults');

    if(!!cachedLocation && !!location && (cachedLocation?.coords?.latitude == location?.coords?.latitude && cachedLocation?.coords?.longitude == location?.coords?.longitude)) {
      console.log("location is same");
      const savedAddress = await getLocalObjectValue('addressFromGeoCode');
      setLocalAddress(savedAddress);
      
      if(cachedResults && cachedResults.length) {
        setNearbyLocations(cachedResults);
      } else {
        performGoogleSearch(location);
      }
      setIsLoading(false);
      return;
    } else {
      await setLocalObjectValue('lastlocation', location);
      setLocationContext(location);
      setTimeout(async () => {
        console.log("location has changed", cachedLocation, location);
        performGoogleSearch(location);
        setLocationLoading(false);
        setIsLoading(false);
      },2500);
    }
  }

  const updateUserLocation = async () => {
    setIsLoading(true);
    const currentLocation = await Location.getCurrentPositionAsync();
    const cachedLocation = await getLocalObjectValue('lastlocation');
    //const cachedAddress = await getLocalObjectValue('addressFromGeoCode');
    if(cachedLocation?.coords?.latitude == currentLocation?.coords?.latitude && cachedLocation?.coords?.longitude == currentLocation?.coords?.longitude) { 
      setIsLoading(false);
      return;
    }
    setNearbyLocations({});
    setSearchResults({});
    await removeLocalStorageItem('@searchresults');
    setLocationContext(currentLocation);
    geoCodeAddress(currentLocation);
    validateLocation();
  }

  const handleSearch = async () => {
     setIsLoading(true);
     performGoogleSearch();
  }

  useEffect(() => {
    setSearchValue('');
    setTimeout(() => {
      if(!LocationContext?.coords) {
        setLocationLoading('not-found');
      }
    },10000);

    const subscription = AppState.addEventListener('change', nextAppState => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === 'active'
      ) {
        validateLocation();
      }

      appState.current = nextAppState;
      setAppStateVisible(appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if(LocationContext?.coords) {
      validateLocation();
      setLocationLoading(false);
      return;
    } else {
      validateLocation();
    }
  },[]);

  useEffect(() => {
    // handles pull down to refresh
    setTimeout(() => {
      if(onUpdateComplete) props.onUpdateComplete();
    },300);
  },[props.updateProp]);

  useEffect(() => {
    // clear results if search is cleared
    if(searchValue.length <= 0 ) {
      setSearchResults({});
    }
  },[searchValue]);

  useEffect(() => {
    geoCodeAddress();
  },[LocationContext]);
/*
  useEffect(() => {
    const clearLocalStorage = async () => {
      await removeLocalStorageItem('@lastlocation');
      await removeLocalStorageItem('@searchresults');
    }
    clearLocalStorage();
  },[])*/

  return (
    <>
      <View style={{backgroundColor: background ? background : 'transparent', position: 'relative', zIndex: 22, marginTop: 24, paddingVertical: 12,}}>
        {!!isLoading && !locationLoading && locationLoading != 'not-found' && <View style={[{position: 'absolute', top: 300, flexDirection: 'row', justifyContent: 'center', width: '100%', zIndex: 15}]}><ActivityIndicator size="large" color={colors.primary} /></View> }
        <View style={{paddingVertical: 8, marginHorizontal: 16}}>
          <View style={{flexDirection: 'row', alignItems: 'center', width: '100%', backgroundColor: '#fff', borderRadius: defaultStyles.borderRadius, borderWidth: 1, borderColor: colors.grayLightest}}>
            <TextInput
              value={searchValue}
              placeholder={'Search nearby places'}
              onChangeText={(val) => setSearchValue(val)}
              style={[formStyles.searchInput,{width: '90%'}]}
              clearButtonMode="always"
              returnKeyType="search"
              onSubmitEditing={() => handleSearch()}
            />
            <Pressable onPress={() => handleSearch()}>
              <Ionicons name='search-outline' size={21} color={colors.grayDarkest} />
            </Pressable>
          </View>
        </View>
        <View style={{flexDirection:'row', justifyContent: 'space-between', alignItems:'center', paddingBottom: 8, marginBottom: 16, zIndex: 5}}>
          <View style={{width: '50%', position: 'relative', marginLeft: 12 }}>
          </View>
          {!!localAddress &&
          <View style={{width: '45%'}}>
            <Pressable style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}} onPress={() => updateUserLocation()}>
              <Ionicons name='locate-outline' size={14} color={colors.black} />
              <Text style={{textAlign:'right', fontSize: 12, paddingRight: 16}}>{localAddress?.city}, {localAddress?.state}</Text>
            </Pressable>
          </View>
          }
        </View>
        {locationLoading && locationLoading != 'not-found' &&
          <View style={{paddingHorizontal: 16}}>
            <Text style={{fontSize:21, fontFamily: defaultStyles.interSemi, marginBottom: 8, textAlign: 'center'}}>Please wait while we obtain your location.</Text>
            <ActivityIndicator size="large" color={colors.graySecondary} />
          </View>
        }
        {!LocationContext?.coords && locationLoading == 'not-found' && 
          <View style={{paddingHorizontal: 16}}>
            <Text style={{fontSize:21, fontFamily: defaultStyles.interSemi, marginBottom: 8, textAlign: 'center'}}>We don't have access to your location.</Text>
            <Text style={{fontSize: 10, textAlign: 'center'}}>Location permission are required in order to use this app.</Text>
            <View style={{flexDirection: 'row', justifyContent: 'center', marginTop: 24}}>
              <Pressable onPress={() => Linking.openSettings()} style={buttons.primary}><Text style={{color: '#fff'}}>Update in your settings</Text></Pressable>
            </View>
          </View>
        }
        <View style={{backgroundColor: containerBackground ? containerBackground : 'transparent'}}>
          {LocationContext?.coords && NearbyLocations && NearbyLocations.length <= 0 && 
            <View style={{position: 'relative', zIndex: 0, paddingBottom: 52}}>
              <Text style={{fontSize: 18, textAlign: 'center'}}>There were no results.</Text>
              <Text style={{fontSize: 12, textAlign: 'center', marginTop: 8}}>Please check your spelling or try a new search.</Text>
            </View>
            }
          {resultsType == "locations" && NearbyLocations && NearbyLocations.length > 0 &&
            <ReviewsList itemsData={searchResults?.length ? searchResults : NearbyLocations} onLocationSelect={(item) => onLocationSelect(item) } />
          }
          {resultsType == "reviews" && NearbyLocations && NearbyLocations.length > 0 &&
            <Cards itemsData={searchResults?.length ? searchResults : NearbyLocations} navigation={navigation} updateCards={props.updateProp} />
          }
        </View>
      </View>
    </>
  )
}