import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Text, Pressable, Image, Dimensions, Linking } from 'react-native'
import { defaultStyles, colors, buttons } from '../../utils/constants'
import StarRating from 'react-native-star-rating-widget'
import { getReviewCount } from '../../data/getters'
import TimeAgo from '@andordavoti/react-native-timeago'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default (props) => {
  const {id, name, address, navigation, timestamp, ratings, title, image, description} = props;
  const [dateFormat, setDateFormat] = useState();
  const [totalReviews, setTotalReviews] = useState(0);

  useEffect(() => {
    const date = (timestamp?.seconds * 1000);
    setDateFormat(date);

    const getReviewsTotal = async () => {
      const c = await getReviewCount(id);
      setTotalReviews(c);
    }
    getReviewsTotal();
  },[])

  return (
    <View style={ {flexDirection: 'row', justifyContent: 'center'}}>
      <View style={styles.card}>
        {!!image && 
        <View style={{marginBottom: 8}}>
          <Image resizeMode={'cover'} source={{ uri: image }} style={styles.imageContainer} />
        </View> 
        }
        <View style={{flexDirection:"row", alignItems: "center", padding:8}}>
          <StarRating
            disabled={true}
            enableSwiping={false}
            maxStars={5}
            enableHalfStar={true}
            rating={ratings?.overall}
            starSize={26}
            emptyStarColor={colors.gray}
            starStyle={{width: 5, marginLeft: 0, marginRight: 24}}
            onChange={() => {}}
            color={colors.primary}
          />
        </View>

        <View style={[styles.container, {flexDirection: "row", justifyContent:"flex-start", alignItems: "center"}]}>
          <View>
            <Text style={{fontWeight: "600", textTransform: "uppercase", fontSize: 12}}>{name}</Text>
            <Text style={{fontSize: 12}}>{address}</Text>
          </View>
        </View>
        
        {title &&
          <View style={{fontSize: 10, paddingHorizontal: 8, paddingTop: 8}}>
            <Text style={[styles.title]}>{title}</Text>
          </View>
        }
        {!!description && 
          <View style={[styles.container, {marginTop: 8}]}>
            <Text style={{fontSize: 16, lineHeight: 24, fontFamily: defaultStyles.interReg}}>{description}</Text>
          </View> 
        }
        <View style={[styles.container, {flexDirection: "row", justifyContent: "flex-start", marginTop: 16}]}>
          <Pressable 
            style={buttons.primary} 
            onPress={() => navigation.navigate('locationDetail', {
              id: id,
              name: name,
              address: address,
              totalreviews: totalReviews
            })}
          >
            <Text style={{color: "#fff"}}>View Report Card</Text>
          </Pressable>
          <Pressable style={buttons.primary} onPress={() => { Linking.openURL(`maps://?address=${encodeURIComponent(address)}&dirflg=r`)}}>
            <Text style={{color: "#fff"}}>Get Directions</Text>
          </Pressable>
        </View>
        {!!dateFormat && 
        <View style={{flexDirection: 'row', justifyContent: 'space-between', padding: 8, marginTop:8}}>
          <Text style={{textAlign: 'right', fontSize: 10, color: colors.graySecondary}}>
            <TimeAgo dateTo={new Date(dateFormat)} />
          </Text>
        </View> }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({  
  imageContainer: {
    height: 200,
    width: '100%'
  },
  profileImage: {
    height: 40,
    width: 40,
  },
  subhead: {
    fontFamily: defaultStyles.headingFont,
    fontSize: defaultStyles.headerSize_3,
    textTransform: "uppercase"
  },
  card: {
    marginVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayLightest,
    padding: 16,
    borderRadius: defaultStyles.borderRadius,
    width: (Dimensions.get("window").width - 20),
    backgroundColor: '#fff',
  },
  cardBody: {
    fontSize: 21,
    lineHeight: 26
  },
  title: {
    fontSize: 28,
    lineHeight: 34,
    fontFamily: defaultStyles.interReg,
  },
  container: {
    paddingHorizontal: 8
  }
});