import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'

// pages
import HomePage from '../../../pages/home'
import ProfilePage from '../../../pages/profile'
import CreatePage from '../../../pages/create'
import DetailsPage from '../../../pages/details'
import ReviewsPage from '../../../pages/reviews'
import IntroPage from '../../../pages/intro'
import UserReviewsPage from '../../../pages/userReviews'
import FeedbackPage from '../../../pages/feedback'
import TermsPage from '../../../pages/static/terms'
import PolicyPage from '../../../pages/static/policy'
import AccountPage from '../../../pages/account'
import LicensePage from '../../../pages/static/license'
import WowPage from '../../../pages/wow'
import DynamicReview from '../../../pages/dynamicReview'
// admin screens
import AdminPage from '../../../pages/admin'
import StickerPage from '../../../pages/admin/newSticker'
import StickerLocations from '../../../pages/admin/stickerLocations'
import UserStats from '../../../pages/admin/userStats'
import ReviewStats from '../../../pages/admin/reviewStats'

const Stack = createNativeStackNavigator();

const screenOptionStyle = {
  headerShown: false,
};

const HomeNavigator = () => {
  return (
    <>
      <Stack.Navigator screenOptions={screenOptionStyle} initialRouteName='homefeed'>
        <Stack.Group>
          <Stack.Screen name='homefeed' component={HomePage} options= {() => ({ title: "Pottymouth | Home"})}  />
          <Stack.Screen name='locationDetail' component={DetailsPage}  />
          <Stack.Screen name='create' component={CreatePage} options= {() => ({ title: "Pottymouth | New Review"})}  />
          <Stack.Screen name='dynamicReview' component={DynamicReview} options= {() => ({ title: "Pottymouth | Review from Sticker"})}  />
        </Stack.Group>
        <Stack.Group>
          <Stack.Screen name='admin' component={AdminPage} options= {() => ({ title: "Pottymouth | Admin"})}  />
          <Stack.Screen name='newStickerLocation' component={StickerPage} options= {() => ({ title: "Pottymouth | New Sticker location"})}  />
          <Stack.Screen name='stickerLocations' component={StickerLocations} options= {() => ({ title: "Pottymouth | Sticker locations"})}  />
          <Stack.Screen name='userStats' component={UserStats} options= {() => ({ title: "Pottymouth | User Stats"})}  />
          <Stack.Screen name='reviewStats' component={ReviewStats} options= {() => ({ title: "Pottymouth | Review Stats"})}  />
        </Stack.Group>
        <Stack.Group screenOptions={({ navigation }) => ({
          presentation: 'modal',
          headerShown: false
        })}>
          <Stack.Screen name='reviews' component={ReviewsPage}  />
        </Stack.Group>
      </Stack.Navigator>
    </>
  );
};

const WowNavigator = () => {
  return (
    <>
      <Stack.Navigator screenOptions={screenOptionStyle} initialRouteName='wow'>
        <Stack.Group>
          <Stack.Screen name='wow' component={WowPage} options= {() => ({ title: "Pottymouth | WoW"})}  />
          <Stack.Screen name='create' component={CreatePage} options= {() => ({ title: "Pottymouth | New Review"})}  />
        </Stack.Group>
      </Stack.Navigator>
    </>
  );
};

const CreateNavigator = () => {
  return (
    <>
      <Stack.Navigator screenOptions={screenOptionStyle} initialRouteName='create'>
        <Stack.Group>
          <Stack.Screen name='create' component={CreatePage} options= {() => ({ title: "Pottymouth | New Review"})}  />
        </Stack.Group>
      </Stack.Navigator>
    </>
  );
};

const MyReviewsNavigator = () => {
  return (
    <>
      <Stack.Navigator screenOptions={screenOptionStyle} initialRouteName='userReviews'>
        <Stack.Group>
          <Stack.Screen name='userreviews' component={UserReviewsPage} options= {() => ({ title: "Pottymouth | My Reviews"})}  />
          <Stack.Screen name='locationDetail' component={DetailsPage}  />
        </Stack.Group>
        <Stack.Group screenOptions={({ navigation }) => ({
          presentation: 'modal',
          headerShown: false
        })}>
          <Stack.Screen name='reviews' component={ReviewsPage}  />
        </Stack.Group>
      </Stack.Navigator>
    </>
  );
};

const AccountNavigator = () => {
  return (
    <>
      <Stack.Navigator screenOptions={screenOptionStyle} initialRouteName='homefeed'>
        <Stack.Group>
          <Stack.Screen name='profile' component={ProfilePage} options= {() => ({ title: "Pottymouth | Profile"})}  />
          <Stack.Screen name='legal' component={IntroPage} options= {() => ({ title: "Pottymouth | Agreement"})} />
          <Stack.Screen name='userreviews' component={UserReviewsPage} options= {() => ({ title: "Pottymouth | My Reviews"})}  />
          <Stack.Screen name='feedback' component={FeedbackPage} options= {() => ({ title: "Pottymouth | Feedback"})}  />
          <Stack.Screen name='account' component={AccountPage} options= {() => ({ title: "Pottymouth | My Account"})}  />
        </Stack.Group>
        <Stack.Group screenOptions={({ navigation }) => ({
          presentation: 'modal',
          headerShown: false
        })}>
          <Stack.Screen name='toc' component={TermsPage} options= {() => ({ title: "Pottymouth | Terms and Conditions"})}  />
          <Stack.Screen name='policy' component={PolicyPage} options= {() => ({ title: "Pottymouth | Privacy Policy"})}  />
          <Stack.Screen name='license' component={LicensePage} options= {() => ({ title: "Pottymouth | License Agreements"})} />
        </Stack.Group>
      </Stack.Navigator>
    </>
  );
};

export { HomeNavigator, WowNavigator, CreateNavigator, MyReviewsNavigator, AccountNavigator  };