import React, { useState, useEffect } from 'react'
import { StyleSheet, View, ActivityIndicator, Text, SafeAreaView, Dimensions, TextInput, Pressable, Image } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

// utility
import { defaultStyles, colors, formStyles, buttons } from '../utils/constants'
// components
import Ionicons from '@expo/vector-icons/Ionicons'
import localImage from '../components/images'

// data
import { addNewUserByEmail } from '../data/setters'
import { getExisitingEmailValidation } from '../data/getters'
import BackButton from '../components/global/buttons/Back'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.
TextInput.defaultProps = Text.defaultProps || {};
TextInput.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default ({route, navigation}) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [name, setName] = useState();
  const [emailPassedValidation, setEmailPassedValidation] = useState(false);
  const [passwordPassedValidation, setPasswordPassedValidation] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const windowHeight =  Dimensions.get("window").height;

  const emailValidation = () => {
    const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email.match(validRegex)) {
      setEmailPassedValidation(true);
    } else {
      setEmailPassedValidation(false);
    }
    checkExistingAccounts();
  }

  const formValidation = () => {
    if(passwordPassedValidation && emailPassedValidation && !formError){
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }

  const passwordValidation = () => {
    if(
      password.match(/\w*[a-z]\w*/) &&
      password.match(/\w*[A-Z]\w*/) &&
      password.match(/\d/) &&
      password.match(/[!@#$%^&*()\-_"=+{}; :,<.>]/) && 
      password.length >= 8
    ){
      setPasswordPassedValidation(true);
    } else {
      setPasswordPassedValidation(false);
    }
  }

  const checkExistingAccounts = async () => {
    setFormError(await getExisitingEmailValidation(email));
  }

  const addUserAccount = async () => {
    setIsLoading(true);
    if(formIsValid && !formError){
      await addNewUserByEmail({
        email: email,
        password: password,
        displayName: name.toLowerCase()
      });
    } else {
      setFormError(true);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    email && email.length > 8 && emailValidation(email);
  },[email]);

  useEffect(() => {
    password && password.length > 2 && passwordValidation(password);
  },[password]);

  useEffect(() => {
    formValidation();
  },[emailPassedValidation, passwordPassedValidation]);

  return (
    <SafeAreaView style={{backgroundColor:'#fff', position:'relative', height: Dimensions.get("window").height}} forceInset={{ bottom: 'never'}}>
      {!!isLoading && 
        <View style={[{position: 'absolute', top: 300, flexDirection: 'row', justifyContent: 'center', alignItems:'center', width: '100%', zIndex: 10, flexWrap: 'wrap'}]}>
          <ActivityIndicator size="large" color={colors.primary} />
          <View style={{width: '100%', marginTop: 16}}>
          <Text style={{textAlign:'center'}}>Please wait while we add your account.</Text>
          </View>
        </View> 
       }
      <View style={{flexDirection: 'row'}}>
        <View style={{width: '25%', paddingRight: 16, paddingLeft: 4}}>
          <Pressable onPress={() => navigation.goBack()} style={{flexDirection: "row", alignItems: "center"}}>
            <BackButton />
          </Pressable>
        </View>
        <View style={{width: '50%', flexDirection: 'row', justifyContent: 'center'}}>
          <Image resizeMode={'contain'} defaultSource={localImage.logo} style={styles.logo} />
        </View>
        <View style={{width: '25%', paddingHorizontal: 16}}>
        </View>
      </View>
      <KeyboardAwareScrollView 
        style={styles.container}
        keyboardOpeningTime={0}
        extraScrollHeight={30}
        enableResetScrollToCoords
        contentInset={{ bottom: 300 }}
      >
        <View style={{width: '100%'}}>
          <Text style={styles.title}>Let's get that account created.</Text>
          <Text style={{marginBottom: windowHeight > 670 ? 24 : 8, marginTop: 8}}>We need to gather some basic information from you.</Text>
          <View style={styles.formItem}>
            <Text style={styles.fieldLabel}>Your full name</Text>
            <TextInput
              value={name}
              onChangeText={(val) => setName(val)}
              placeholder={'First Last'}
              style={[formStyles.textInput]}
              clearButtonMode="unless-editing"
              autoCorrect={false}
            />
          </View>
          <View style={styles.formItem}>
            <Text style={styles.fieldLabel}>Your email address</Text>
            <View style={{position: 'relative'}}>
              <TextInput
                value={email}
                onChangeText={(val) => setEmail(val.toLowerCase())}
                onBlur={() => checkExistingAccounts()}
                placeholder={'you@somedomain.com'}
                style={[formStyles.textInput, {paddingLeft: email?.length > 0 ? 30 : 10}]}
                clearButtonMode="unless-editing"
                autoCorrect={false}
                inputmode="email"
              />
              <View style={{position: 'absolute', flexDirection: 'row', alignItems:'center', height:'100%', top: 0, left: 4}}>
                  {email && !emailPassedValidation && <Ionicons name="scan-outline" size={18} color="#000" /> }
                  {email?.length >= 2 && emailPassedValidation && formError && <Ionicons name="close" size={21} color="#f00" /> }
                  {email?.length >= 2 && emailPassedValidation && !formError && <Ionicons name="checkmark-circle" size={21} color="#5acc11" />}
              </View>
            </View>
          </View>

          <View style={styles.formItem}>
            <Text style={styles.fieldLabel}>Create a password</Text>
            <View style={{position: 'relative'}}>
              <TextInput
                value={password}
                onChangeText={(val) => setPassword(val)}
                placeholder={'password'}
                style={[formStyles.textInput, {paddingLeft: password?.length > 7 ? 30 : 10}]}
                clearButtonMode="unless-editing"
                autoCorrect={false}
                secureTextEntry={true}
              />
              <View style={{position: 'absolute', flexDirection: 'row', alignItems:'center', height:'100%', top: 0, left: 4}}>
                  {password?.length >= 8 && !passwordPassedValidation && <Ionicons name="close" size={21} color="#f00" /> }
                  {password?.length >= 8 && passwordPassedValidation && <Ionicons name="checkmark-circle" size={21} color="#5acc11" />}
              </View>
            </View>
            <Text style={{fontSize: 12, color: colors.graySecondary, marginVertical: 4}}>Password Requirements:</Text>
            <Text style={{fontSize: 12, color: colors.graySecondary}}>uppercase, lowercase, number, symbol and {'>'} 7 characters</Text>
          </View>
          <Pressable disabled={!formIsValid || formError} onPress={() => addUserAccount() } style={[buttons.primary, (!formIsValid || formError) && styles.disabledButton, styles.submitButton]}>
            <Text style={{color: colors.grayTertiary}}>All done</Text>
          </Pressable>
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: { 
   flex: 1,
   paddingHorizontal: 16,
   paddingBottom: 60,
  },
  logo: {
    width: 175,
    height: 30,
    marginBottom: 8
  },
  errorText: {
    fontSize: 10,
    color: 'red',
  },
  title: {
    fontFamily: defaultStyles.interSemi,
    fontSize: 36,
    marginTop: Dimensions.get("window").height > 670 ? 32 : 16,
    paddingRight: 32,
    textTransform: 'capitalize'
  },
  formItem: {
    marginTop: 24
  },
  fieldLabel: {
    fontSize: 14,
    marginBottom: 12,
    color: colors.black,
    textTransform: 'uppercase'
  },
  submitButton: {
    marginTop: 18,
    paddingVertical: 18,
    width: '100%'
  },
  disabledButton: {
    backgroundColor: colors.grayLightest,
  }
});