import React, { useContext, useState, useEffect } from 'react'
import { StyleSheet, View, ActivityIndicator, Text, Pressable, SafeAreaView, Dimensions, Image, Platform, ScrollView } from 'react-native'
import * as AuthSession from 'expo-auth-session'
import { PottyMouthAuth } from '../lib/firebase'
import { signOut } from "firebase/auth";
import { AuthContext } from '../lib/context/Auth'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { defaultStyles, buttons, colors, circleImage,  circleImageXLarge, fallbackImage } from '../utils/constants'
import Constants from 'expo-constants'

// components
import Ionicons from '@expo/vector-icons/Ionicons'
import BackButton from '../components/global/buttons/Back'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default ({route, navigation}) => {
  const versionNumber = Constants.expoConfig.extra.appVersion;
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [showLegal, setShowLegal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateFields, setUpdateFields] = useState(currentUser?.providerData[0]);
  const insets = useSafeAreaInsets();
  const uiPadding = Platform.OS === 'ios' ? 0 : 30;

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setUpdateFields([]);
      setTimeout(() => {
        setUpdateFields(currentUser?.providerData[0]);
      },100);
    });
    return unsubscribe;
  }, [navigation, route]);

  const logout = () => {
    setIsLoading(true);
    AuthSession.dismiss();
    signOut(PottyMouthAuth).then(() => {
    // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }


  return (
    <SafeAreaView style={{flex: 1, paddingTop: uiPadding, paddingBottom: (insets.bottom + 30), backgroundColor: colors.background}}>
      {!!isLoading && <View style={[{position: 'absolute', top: 300, flexDirection: 'row', justifyContent: 'center', width: '100%', zIndex: 10}]}><ActivityIndicator size="large" color={colors.primary} /></View> }
      <View style={{marginBottom: 32, paddingHorizontal: 4}}>
        <Pressable onPress={() => navigation.goBack()} style={{flexDirection: "row", alignItems: "center"}}>
          <BackButton />
        </Pressable>
      </View>
      <ScrollView 
        contentContainerStyle={{paddingBottom: 20}} 
        style={{height: (Dimensions.get("window").height - 140), position:'relative'}}
        alwaysBounceVertical={false}
        vertical={true}
      >
        <View style={{flexDirection: 'row', justifyContent: 'center', marginBottom: 24 }}>
          <View style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <View style={[circleImage, circleImageXLarge, {marginRight: 8, marginBottom: 8}]}>
              <Image 
              source={{ 
                uri: updateFields?.photoURL || fallbackImage,
                cache: 'force-cache'
                }}
              resizeMode="cover"
              style={styles.profileImage}
              />
            </View> 
            <Text style={{textAlign: 'center', fontSize: 18, color: colors.black, fontFamily: defaultStyles.interSemi}}>{updateFields?.displayName}</Text>
            <Text style={{textAlign: 'center', fontSize: 14, color: colors.grayDarkest}}>{updateFields?.handle}</Text>
          </View>
        </View>
        <View style={styles.container}>
            {showLegal &&
              <View>
                <Pressable onPress={() => setShowLegal(false)} style={{flexDirection: 'row'}}>
                  <View style={{paddingRight: 4}}>
                    <Ionicons name="chevron-back-outline" size={18} color="#000" />
                  </View>
                  <Text style={{fontSize: 16, fontFamily: defaultStyles.interSemi, paddingLeft: 8, width: '75%'}}>Menu</Text>
                </Pressable>
              <View style={{marginVertical: 24, backgroundColor: 'white', borderRadius: defaultStyles.borderRadius}}>
                <Pressable onPress={() => navigation.navigate('toc')} style={styles.section}>
                  <View style={styles.iconbox}>
                    <Ionicons name="newspaper-outline" size={18} color="#000" />
                  </View>
                  <Text style={[styles.textbox, {fontSize: 16, fontFamily: defaultStyles.interSemi}]}>Terms and Conditions</Text>
                  <View style={styles.iconbox}>
                    <Ionicons name="chevron-forward-outline" size={24} color="#000" />
                  </View>
                </Pressable>
                <Pressable onPress={() => navigation.navigate('policy')} style={styles.section}>
                  <View style={styles.iconbox}>
                    <Ionicons name="people-outline" size={18} color="#000" />
                  </View>
                  <Text style={[styles.textbox, {fontSize: 16, fontFamily: defaultStyles.interSemi}]}>Privacy Policy</Text>
                  <View style={styles.iconbox}>
                    <Ionicons name="chevron-forward-outline" size={24} color="#000" />
                  </View>
                </Pressable>
                <Pressable onPress={() => navigation.navigate('license')} style={styles.section}>
                  <View style={styles.iconbox}>
                    <Ionicons name="document-text-outline" size={18} color="#000" />
                  </View>
                  <Text style={[styles.textbox, {fontSize: 16, fontFamily: defaultStyles.interSemi}]}>Licenses</Text>
                  <View style={styles.iconbox}>
                    <Ionicons name="chevron-forward-outline" size={24} color="#000" />
                  </View>
                </Pressable>
              </View> 
            </View>
            }
            {!showLegal && 
              <View style={{marginVertical: 24, backgroundColor: 'white', borderRadius: defaultStyles.borderRadius, borderWidth: 1, borderColor: colors.grayLightest}}>
                <Pressable onPress={() => navigation.navigate('account')} style={styles.section}>
                  <View style={styles.iconbox}>
                    <Ionicons name="person-outline" size={18} color="#000" />
                  </View>
                  <Text style={[styles.textbox, {fontSize: 16, fontFamily: defaultStyles.interSemi}]}>Account Information</Text>
                  <View style={styles.iconbox}>
                    <Ionicons name="chevron-forward-outline" size={24} color="#000" />
                  </View>
                </Pressable>
                <Pressable onPress={() => navigation.navigate('feedback')} style={styles.section}>
                  <View style={styles.iconbox}>
                    <Ionicons name="paper-plane-outline" size={18} color="#000" />
                  </View>
                  <Text style={[styles.textbox, {fontSize: 16, fontFamily: defaultStyles.interSemi}]}>Feedback</Text>
                  <View style={styles.iconbox}>
                    <Ionicons name="chevron-forward-outline" size={24} color="#000" />
                  </View>
                </Pressable>
                <Pressable onPress={() => setShowLegal(true)} style={styles.section}>
                  <View style={styles.iconbox}>
                    <Ionicons name="book-outline" size={18} color="#000" />
                  </View>
                  <Text style={[styles.textbox, {fontSize: 16, fontFamily: defaultStyles.interSemi}]}>Legal Information</Text>
                  <View style={styles.iconbox}>
                    <Ionicons name="chevron-forward-outline" size={24} color="#000" />
                  </View>
                </Pressable>
              </View> 
            }
          <View style={{borderRadius: defaultStyles.borderRadius, backgroundColor:'white', padding: 16}}>
            <Pressable onPress={() => logout()} style={[buttons.primary, { paddingVertical: 18}]}>
              <Text style={{color: '#fff'}}>Log out</Text>
            </Pressable>
          </View>
        </View>
        <View style={{flexDirection:'row', justifyContent: 'center', marginTop: 20, width: '100%'}}>
            <Text style={{textAlign: 'center', color: colors.graySecondary}}>v{versionNumber}</Text>
          </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  text: {
    fontSize: 25,
    fontWeight: '500',
    color: '#fff'
  },
  profileImage: {
    height: 120,
    width: 120
  },
  section: {
    paddingHorizontal: 12,
    paddingVertical: 18,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: colors.grayLightest
  },
  image: {
    paddingBottom: 100
  },
  statbox: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderRadius: defaultStyles.borderRadius
  },
  iconbox: {
    width: '16.66%',
    paddingHorizontal: 8
  },
  textbox: {
    width: '75%',
    paddingLeft: 16
  },
  stat: {
    fontSize: 28,
    color: '#000',
    textAlign: 'center',
    marginBottom: 4,
  },
  statLabel: {
    fontSize: 14,
    color: colors.gray,
    textAlign: 'center'
  }
});