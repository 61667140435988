import { StatusBar } from 'react-native'
import { useFonts, Inter_400Regular, Inter_600SemiBold, Inter_900Black } from '@expo-google-fonts/inter'
import { GeolocationProvider } from './src/lib/context/Geolocation'
import { AuthProvider } from './src/lib/context/Auth'
import { NearbyLocationsProvider } from './src/lib/context/NearbyLocations'
import { NavigationContainer } from '@react-navigation/native'
import AppNavigator from './src/components/global/navigation'

export default function App() {
  let [fontsLoaded] = useFonts({
    Inter_400Regular,
    Inter_600SemiBold,
    Inter_900Black,
  });

  if (!fontsLoaded) {
    return null;
  }

  const config = {
    screens: {
      HomeTab: {
        screens: {
          dynamicReview: 'review-by-location-number',
        },
      }
    },
  };

  const linking = {
    prefixes: ["pottymouth://", "https://pwa.pottymouthapp.com"],
    config,
  };

  return (
    <>
      <StatusBar barStyle="dark-content" />
      <AuthProvider>
        <GeolocationProvider>
          <NearbyLocationsProvider>
            <NavigationContainer linking={linking}>
              <AppNavigator />
            </NavigationContainer>
          </NearbyLocationsProvider>
        </GeolocationProvider>
      </AuthProvider>
    </>
  );
}