import React, {useState} from 'react'
import { View, Text, Pressable } from 'react-native'
import Ionicons from '@expo/vector-icons/Ionicons'
import { colors } from '../../utils/constants';

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default (props) => {
  const {bodyText} = props;
  const [showToolTip, setShowToolTip] = useState(false);

  return (
    <View style={{position: 'relative', zIndex: 20}}>
      {showToolTip && 
        <View style={{position: 'absolute', backgroundColor: "#fff", padding: 10, zIndex: 12, top: -50, left: -70, borderWidth: 1, borderColor: colors.graySecondary, width: 160}}>
          <Text style={{fontSize: 10, color:'#000', position: 'relative', zIndex: 10}}>{bodyText}</Text>
        </View> 
      }
      <Pressable style={{position: 'relative'}} onPress={() => setShowToolTip(!showToolTip)}>
        <Ionicons name="information-circle-outline" size={16} color="#000" />
      </Pressable>
    </View>
  )
}