import React, { useContext, useState, useEffect } from 'react'
import { StyleSheet, View, Text, Pressable, ScrollView, SafeAreaView, Dimensions } from 'react-native'
import { TextInput } from 'react-native-gesture-handler'
import { formStyles, colors, defaultStyles, buttons } from '../utils/constants'
import { AuthContext } from '../lib/context/Auth'
import { updateFields } from '../data/setters'
import { getUserHandleValidation } from '../data/getters'
import Ionicons from '@expo/vector-icons/Ionicons'

// legal
import TermsAndConditions from '../components/legal/terms'
import PrivacyPolicy from '../components/legal/policy'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default ({route, navigation}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [userHandle, setUserHandle] = useState();
  const [handleIsValid, setHandleIsValid] = useState(false);
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [formComplete, setFormComplete] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const userFullName = currentUser.providerData[0]?.displayName || currentUser.displayName;
  const estimatedFirstName = userFullName.split(' ');

  const validateHandle = async () => {
    setIsloading(true);
    const handleValid = await getUserHandleValidation(userHandle);
    setHandleIsValid(handleValid);
    setIsloading(false);
  }
  const cleanup = (str) => {
    const newString = str.replace(/[^A-Z0-9]+/ig, "_");

    return newString.toLowerCase();
  }
  const updateContext = async () => {
    currentUser.providerData[0].firstTime = false;
    currentUser.providerData[0].handle = userHandle;
    setCurrentUser(currentUser);
    
    updateFields(currentUser.providerData[0].uid, { 
      handle: userHandle,
      firstTime: false,
      termsAcknowledgement: true,
      privacyAcknowledgement: true,
      acknowledgementDate: new Date().toLocaleString()
    })
    setFormComplete(true);
  }
  useEffect(() => {
    if(currentUser.providerData[0].firstTime === false) {
      navigation.navigate('homefeed');
    }
  },[formComplete]);

  useEffect(() => {
    if(userHandle?.length >= 3) {
      validateHandle();
    }
  },[userHandle]);
  
  return (
    <SafeAreaView style={{backgroundColor:'#fff', height: Dimensions.get("window").height}} forceInset={{ bottom: 'never'}}>
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          <Text style={styles.title}>Hey {estimatedFirstName[0]},</Text>
          <Text style={styles.subhead}>welcome to Pottymouth!</Text>
          <Text style={styles.subtext}>Before you get started, we have to take care of some house keeping.</Text>
        </View>
       {currentStep === 1 && 
        <View style={styles.innerContainer}>
          <Text style={styles.subheadSmaller}>First, you must agree to our Terms of Use.</Text>
          <ScrollView  style={{height: (Dimensions.get('window').height - 400)}} contentContainerStyle={{paddingBottom: 60}}>
            <TermsAndConditions />
          </ScrollView>
          <Pressable onPress={() => setCurrentStep(2)} style={[buttons.primary, {marginVertical: 16}]}>
            <Text style={{color: '#fff'}}>I agree to these terms.</Text>
          </Pressable>
        </View>
        }
        {currentStep === 2 && 
        <View style={styles.innerContainer}>
          <Text style={styles.subheadSmaller}>Second, you must view and acknowledge our Privacy Policy</Text>
          <ScrollView  style={{height: (Dimensions.get('window').height - 400)}} contentContainerStyle={{paddingBottom: 60}}>
            <PrivacyPolicy />
          </ScrollView>
          <Pressable onPress={() => setCurrentStep(3)} style={[buttons.primary, {marginVertical: 16}]}>
            <Text style={{color: '#fff'}}>I have read and understand this policy.</Text>
          </Pressable>
        </View>
        }

        {currentStep === 3 && 
          <ScrollView keyboardShouldPersistTaps="handled">
            <View style={styles.innerContainer}>
              <Text style={styles.subheadSmaller}>Lastly, please create a user handle.</Text>
            </View>
            <View style={{
              backgroundColor: colors.background, 
              paddingVertical: 16, 
              flexDirection: 'row', 
              justifyContent: 'center', 
              marginVertical: 16
            }}>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <TextInput
                  value={userHandle}
                  onChangeText={(val) => setUserHandle(cleanup(val))}
                  placeholder={'Enter your desired name'}
                  style={[formStyles.searchInput, {marginHorizontal: 8, width: '83.33%'}]}
                  clearButtonMode="unless-editing"
                  autoCorrect={false}
                />
                <View style={{width: '16.66%'}}>
                  {isLoading || (!userHandle || userHandle?.length < 3) && <Ionicons name="scan-outline" size={18} color="#000" /> }
                  {userHandle?.length >= 3 && !handleIsValid && <Ionicons name="close" size={24} color="#f00" /> }
                  {userHandle?.length >= 3 && handleIsValid && <Ionicons name="checkmark-circle" size={24} color="#5acc11" />}
                </View>
              </View>
            </View>
            <View style={styles.innerContainer}>
              <Text style={styles.footnote}>This user handle will be displayed in place of your name provided at registration</Text>
              {handleIsValid && userHandle?.length >= 3 &&
                <Pressable onPress={() => updateContext()} style={[buttons.primary, {marginVertical: 16}]}>
                  <Text style={{color: '#fff'}}>All Done!</Text>
                </Pressable> 
              }
            </View>
          </ScrollView>
        }
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    paddingTop: 8
  },
  innerContainer: {
    paddingHorizontal: 20,
  },
  text: {
    fontSize: 25,
    fontWeight: '500',
  },
  title: {
   fontSize: 42,
   fontFamily: defaultStyles.headingFont,
   lineHeight: 55,
   marginTop: 24
  },
  subhead: {
    fontSize: 21,
    lineHeight: 30,
    fontFamily: defaultStyles.interReg
  },
  subheadSmaller: {
    fontSize: 18,
    lineHeight: 26,
    fontFamily: defaultStyles.interReg,
    marginBottom: 8
  },
  subtext: {
    fontSize: 16,
    lineHeight: 21,
    color: colors.gray,
    marginVertical: 16
  },
  footnote: {
    fontSize: 11,
    lineHeight: 14,
    color: colors.graySecondary
  }
});