import React from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { defaultStyles } from '../../utils/constants'
import Unorderedlist from 'react-native-unordered-list'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default (props) => {
  return (
    <View>
      <View style={styles.section_out}>
        <Text style={styles.title}>Pottymouth Terms and Conditions</Text>
        <Text style={styles.bodyCopy}>These Terms govern</Text>
        <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>the use of this Application, and,</Text></Unorderedlist>
        <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>any other related Agreement or legal relationship with the Owner</Text></Unorderedlist>
        <Text style={styles.bodyCopy}>in a legally binding way. Capitalized words are defined in the relevant dedicated section of this document.</Text>
        <Text style={styles.bodyCopy}>The User must read this document carefully.</Text>
      </View>

      <View style={styles.section_out}>
        <Text style={styles.bodyCopy}>Although the entire contractual relationship relating to these Products is entered into solely by the Owner and Users, Users acknowledge and agree that, where this Application has been provided to them via the Apple App Store, Apple may enforce these Terms as a third-party beneficiary.</Text>
        <Text style={styles.bodyCopy}>This Application is provided by:</Text>

        <View style={{padding: 8}}>
          <View>
            <Text style={styles.bodyCopy}>The Elemeno Company, LLC</Text>
          </View>
          <View>
            <Text style={styles.bodyCopy}>3900 Crown RD SW, #16709 SMB#40256</Text>
          </View>
          <View>
            <Text style={styles.bodyCopy}>Atlanta, GA. 30304</Text>
          </View>
        </View>
        <Text style={styles.bodyCopy}>Owner contact email: contact@elemenoco.com</Text>
      </View>

      <View style={styles.section_out}>
        <Text style={styles.subsectionHead}>What the User should know at a glance</Text>
        <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>The Service/this Application is only intended for Consumers.</Text></Unorderedlist>
      </View>

      <View style={styles.section_out}>
        <Text style={styles.sectionHead}>TERMS OF USE</Text>
        <Text style={styles.bodyCopy}>Unless otherwise specified, the terms of use detailed in this section apply generally when using this Application.</Text>
        <Text style={styles.bodyCopy}>Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.</Text>
        <Text style={styles.bodyCopy}>By using this Application, Users confirm to meet the following requirements:</Text>
        <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>Users must qualify as Consumers;</Text></Unorderedlist>
        <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>Users aren't located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a "terrorist-supporting" country;</Text></Unorderedlist>
        <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>Users aren't listed on any U.S. Government list of prohibited or restricted parties;</Text></Unorderedlist>

        <View style={styles.section}>
          <Text style={styles.subsectionHead}>Account registration</Text>
          <Text style={styles.bodyCopy}>To use the Service Users must register or create a User account, providing all required data or information in a complete and truthful manner. Failure to do so will cause unavailability of the Service.</Text>
          <Text style={styles.bodyCopy}>Users are responsible for keeping their login credentials confidential and safe. For this reason, Users are also required to choose passwords that meet the highest standards of strength permitted by this Application.</Text>
          <Text style={styles.bodyCopy}>By registering, Users agree to be fully responsible for all activities that occur under their username and password.</Text>
          <Text style={styles.bodyCopy}>Users are required to immediately and unambiguously inform the Owner via the contact details indicated in this document, if they think their personal information, including but not limited to User accounts, access credentials or personal data, have been violated, unduly disclosed or stolen.</Text>
          <Text style={styles.subhead}>Conditions for account registration</Text>
          <Text style={styles.bodyCopy}>Registration of User accounts on this Application is subject to the conditions outlined below. By registering, Users agree to meet such conditions.</Text>
            <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>Accounts registered by bots or any other automated methods are not permitted.</Text></Unorderedlist>
            <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>Unless otherwise specified, each User must register only one account.</Text></Unorderedlist>
            <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>Unless explicitly permitted, a User account may not be shared with other persons.</Text></Unorderedlist>
        </View>
        <View style={styles.section}>
          <Text style={styles.subhead}>Account termination</Text>
          <Text style={styles.bodyCopy}>Users can terminate their account and stop using the Service at any time by doing the following:</Text>
          <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>By using the tools provided for account termination on this Application.</Text></Unorderedlist>
        </View>
        <View style={styles.section}>
          <Text style={styles.subhead}>Account suspension and deletion</Text>
          <Text style={styles.bodyCopy}>The Owner reserves the right to suspend or terminate the User's account at any time and without notice, at the Owner's sole discretion, in these cases:</Text>
          <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}><Text style={styles.bodyCopy}>User has violated these Terms; and/or</Text></Text></Unorderedlist>
          <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}><Text style={styles.bodyCopy}>User's access or use of this Application may cause injury to the Owner, other Users or third parties; and/or</Text></Text></Unorderedlist>
          <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}><Text style={styles.bodyCopy}>the use of this Application by the User may cause violation of law or regulations; and/or</Text></Text></Unorderedlist>
          <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}><Text style={styles.bodyCopy}>in case of an investigation by legal action or governmental involvement; and/or</Text></Text></Unorderedlist>
          <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}><Text style={styles.bodyCopy}>the account or its use is deemed to be, at the Owner's sole discretion inappropriate or offensive or in violation of these Terms.</Text></Text></Unorderedlist>
          <Text style={styles.bodyCopy}>The suspension or deletion of User accounts shall not entitle Users to any claims for compensation, damages or reimbursement.
          </Text>
          <Text style={styles.bodyCopy}>The suspension or deletion of accounts due to causes attributable to the User does not exempt the User from paying any applicable fees or prices.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>Content on this Application</Text>
          <Text style={styles.bodyCopy}>Unless where otherwise specified or clearly recognizable, all content available on this Application is owned or provided by the Owner or its licensors.</Text>
          <Text style={styles.bodyCopy}>The Owner undertakes its utmost effort to ensure that the content provided on this Application infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result.</Text>
          <Text style={styles.bodyCopy}>In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.</Text>
          <Text style={styles.subhead}>Rights regarding content on this Application - All rights reserved</Text>
          <Text style={styles.bodyCopy}>The Owner holds and reserves all intellectual property rights for any such content.</Text>
          <Text style={styles.bodyCopy}>Users may not therefore use such content in any way that is not necessary or implicit in the proper use of the Service.</Text>
          <Text style={styles.bodyCopy}>In particular, but without limitation, Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicense, edit, transfer/assign to third parties or create derivative works from the content available on this Application, nor allow any third party to do so through the User or their device, even without the User's knowledge.</Text>
          <Text style={styles.bodyCopy}>Where explicitly stated on this Application, the User may download, copy and/or share some content available through this Application for its sole personal and non-commercial use and provided that the copyright attributions and all the other attributions requested by the Owner are correctly implemented.</Text>
          <Text style={styles.bodyCopy}>Any applicable statutory limitation or exception to copyright shall stay unaffected.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>Content provided by Users</Text>
          <Text style={styles.bodyCopy}>The Owner allows Users to upload, share or provide their own content to this Application.</Text>
          <Text style={styles.bodyCopy}>By providing content to this Application, Users confirm that they are legally allowed to do so and that they are not infringing any statutory provisions and/or third-party rights.</Text>
          <Text style={styles.subhead}>Rights regarding content provided by Users</Text>
          <Text style={styles.bodyCopy}>Users acknowledge and accept that by providing their own content on this Application they grant the Owner a non-exclusive, fully paid-up and royalty-free license to process such content solely for the operation and maintenance of this Application as contractually required.</Text>
          <Text style={styles.bodyCopy}>To the extent permitted by applicable law, Users waive any moral rights in connection with content they provide to this Application.</Text>
          <Text style={styles.bodyCopy}>Users acknowledge, accept and confirm that all content they provide through this Application is provided subject to the same general conditions set forth for content on this Application.</Text>
          <Text style={styles.subhead}>Liability for provided content</Text>
          <Text style={styles.bodyCopy}>Users are solely liable for any content they upload, post, share, or provide through this Application. Users acknowledge and accept that the Owner does not filter or moderate such content.</Text>

          <Text style={styles.bodyCopy}>However, the Owner reserves the right to remove, delete, block or rectify such content at its own discretion and to, without prior notice, deny the uploading User access to this Application:</Text>

          <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>if any complaint based on such content is received;</Text></Unorderedlist>
          <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>if a notice of infringement of intellectual property rights is received;</Text></Unorderedlist>
          <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>upon order of a public authority; or</Text></Unorderedlist>
          <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>where the Owner is made aware that the content, while being accessible via this Application, may represent a risk for Users, third parties and/or the availability of the Service.</Text></Unorderedlist>

          <Text style={styles.bodyCopy}>The removal, deletion, blocking or rectification of content shall not entitle Users that have provided such content or that are liable for it, to any claims for compensation, damages or reimbursement.</Text>
          <Text style={styles.bodyCopy}>Users agree to hold the Owner harmless from and against any claim asserted and/or damage suffered due to content they provided to or provided through this Application.</Text>
          <Text style={styles.subhead}>Access to provided content</Text>
          <Text style={styles.bodyCopy}>Content that Users provide to this Application is made available according to the criteria outlined within this section.</Text>
          <Text style={[styles.subhead, { fontSize: 14 }]}>Publicly available content</Text>
          <Text style={styles.bodyCopy}>Content meant for public availability shall be automatically made public on this Application upon upload or, at the sole discretion of the Owner, at a later stage.</Text>
          <Text style={styles.bodyCopy}>Any personal data, identifier or any other information that Users upload in connection with such content (such as a User-ID, avatar or nickname etc.) shall also appear in connection with the published content.</Text>
          <Text style={styles.bodyCopy}>Users may (and are encouraged to) check on this Application to find details of who can access the content they provide.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>Access to external resources</Text>
          <Text style={styles.bodyCopy}>Through this Application Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability.</Text>
          <Text style={styles.bodyCopy}>Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties' terms and conditions or, in the absence of those, applicable statutory law.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>How to file a takedown notice (called a "DMCA notice")</Text>
          <Text style={styles.bodyCopy}>If copyright holders or their agents believe that any content on this Application infringes upon their copyrights, they may submit a notification pursuant to the Digital Millennium Copyright Act ("DMCA") by providing the Owner's Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):</Text>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>A physical or electronic signature of a person authorized to act on behalf of the holder of an exclusive right that is allegedly infringed;</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled and information reasonably sufficient to permit the Owner to locate the material;</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>Information reasonably sufficient to permit the Owner to contact the notifying party, such as an address, telephone number, and, if available, an electronic mail;</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>A statement that the notifying party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>A statement that the information in the notification is accurate, and under penalty of perjury, that the notifying party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</Text>
          </Unorderedlist>
          <Text style={styles.bodyCopy}>Failure to comply with all of the requirements outlined above may result in invalidity of the DMCA notice.</Text>
          <Text style={styles.bodyCopy}>Copyright infringement notifications may be addressed to the Owner's Copyright Agent at the contact details specified in this document.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>Acceptable use</Text>
          <Text style={styles.bodyCopy}>This Application and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law.</Text>
          <Text style={styles.bodyCopy}>Users are solely responsible for making sure that their use of this Application and/or the Service violates no applicable law, regulations or third-party rights.</Text>
          <Text style={styles.bodyCopy}>Therefore, the Owner reserves the right to take any appropriate measure to protect its legitimate interests including by denying Users access to this Application or the Service, terminating contracts, reporting any misconduct performed through this Application or the Service to the competent authorities - such as judicial or administrative authorities - whenever Users engage or are suspected to engage in any of the following activities:</Text>
          <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>violate laws, regulations and/or these Terms;</Text></Unorderedlist>
          <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>infringe any third-party rights;</Text></Unorderedlist>
          <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>considerably impair the Owner's legitimate interests;</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>offend the Owner or any third party.</Text></Unorderedlist>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>API usage terms</Text>
          <Text style={styles.bodyCopy}>Users may access their data relating to this Application via the Application Program Interface (API). Any use of the API, including use of the API through a third-party product/service that accesses this Application, is bound by these Terms and, in addition, by the following specific terms:</Text>
          <Unorderedlist style={styles.list}><Text style={styles.bodyCopy}>the User expressly understands and agrees that the Owner bears no responsibility and shall not be held liable for any damages or losses resulting from the User's use of the API or their use of any third-party products/services that access data through the API.</Text></Unorderedlist>
        </View>

        <View style={styles.section_out}>
          <Text style={styles.sectionHead}>Liability and indemnification</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>Australian Users</Text>
          <Text style={styles.subhead}>Limitation of liability</Text>
          <Text style={styles.bodyCopy}>Nothing in these Terms excludes, restricts or modifies any guarantee, condition, warranty, right or remedy which the User may have under the Competition and Consumer Act 2010 (Cth) or any similar State and Territory legislation and which cannot be excluded, restricted or modified (non-excludable right). To the fullest extent permitted by law, our liability to the User, including liability for a breach of a non-excludable right and liability which is not otherwise excluded under these Terms of Use, is limited, at the Owner's sole discretion, to the re-performance of the services or the payment of the cost of having the services supplied again.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>US Users</Text>
          <Text style={styles.subhead}>Disclaimer of Warranties</Text>
          <Text style={styles.bodyCopy}>This Application is provided strictly on an "as is" and "as available" basis. Use of the Service is at Users' own risk. To the maximum extent permitted by applicable law, the Owner expressly disclaims all conditions, representations, and warranties — whether express, implied, statutory or otherwise, including, but not limited to, any implied warranty of merchantability, fitness for a particular purpose, or non-infringement of third-party rights. No advice or information, whether oral or written, obtained by user from owner or through the Service will create any warranty not expressly stated herein.</Text>
          <Text style={styles.bodyCopy}>Without limiting the foregoing, the Owner, its subsidiaries, affiliates, licensors, officers, directors, agents, co-branders, partners, suppliers and employees do not warrant that the content is accurate, reliable or correct; that the Service will meet Users' requirements; that the Service will be available at any particular time or location, uninterrupted or secure; that any defects or errors will be corrected; or that the Service is free of viruses or other harmful components. Any content downloaded or otherwise obtained through the use of the Service is downloaded at users own risk and users shall be solely responsible for any damage to Users' computer system or mobile device or loss of data that results from such download or Users' use of the Service.</Text>
          <Text style={styles.bodyCopy}>The Owner does not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the Service or any hyperlinked website or service, and the Owner shall not be a party to or in any way monitor any transaction between Users and third-party providers of products or services.</Text>
          <Text style={styles.bodyCopy}>The Service may become inaccessible or it may not function properly with Users' web browser, mobile device, and/or operating system. The owner cannot be held liable for any perceived or actual damages arising from Service content, operation, or use of this Service.</Text>
          <Text style={styles.bodyCopy}>Federal law, some states, and other jurisdictions, do not allow the exclusion and limitations of certain implied warranties. The above exclusions may not apply to Users. This Agreement gives Users specific legal rights, and Users may also have other rights which vary from state to state. The disclaimers and exclusions under this agreement shall not apply to the extent prohibited by applicable law.</Text>
          <Text style={styles.subsectionHead}>Limitations of liability</Text>
          <Text style={styles.bodyCopy}>To the maximum extent permitted by applicable law, in no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for</Text>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>any indirect, punitive, incidental, special, consequential or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data or other intangible losses, arising out of or relating to the use of, or inability to use, the Service; and</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>any damage, loss or injury resulting from hacking, tampering or other unauthorized access or use of the Service or User account or the information contained therein;</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>any errors, mistakes, or inaccuracies of content;</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>personal injury or property damage, of any nature whatsoever, resulting from User access to or use of the Service;</Text></Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>any unauthorized access to or use of the Owner's secure servers and/or any and all personal information stored therein;</Text></Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>any interruption or cessation of transmission to or from the Service;</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service;</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>the defamatory, offensive, or illegal conduct of any User or third party. In no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for any claims, proceedings, liabilities, obligations, damages, losses or costs in an amount exceeding the amount paid by User to the Owner hereunder in the preceding 12 months, or the period of duration of this agreement between the Owner and User, whichever is shorter.</Text>
          </Unorderedlist>
          <Text style={styles.bodyCopy}>This limitation of liability section shall apply to the fullest extent permitted by law in the applicable jurisdiction whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if company has been advised of the possibility of such damage.</Text>
          <Text style={styles.bodyCopy}>Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, therefore the above limitations or exclusions may not apply to User. The terms give User specific legal rights, and User may also have other rights which vary from jurisdiction to jurisdiction. The disclaimers, exclusions, and limitations of liability under the terms shall not apply to the extent prohibited by applicable law.</Text>
          <Text style={styles.subsectionHead}>Indemnification</Text>
          <Text style={styles.bodyCopy}>The User agrees to defend, indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees harmless from and against any and all claims or demands, damages, obligations, losses, liabilities, costs or debt, and expenses, including, but not limited to, legal fees and expenses, arising from</Text>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>User's use of and access to the Service, including any data or content transmitted or received by User;</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>User's violation of these terms, including, but not limited to, User's breach of any of the representations and warranties set forth in these terms;</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>User's violation of any third-party rights, including, but not limited to, any right of privacy or intellectual property rights;</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>User's violation of any statutory law, rule, or regulation;</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>any content that is submitted from User's account, including third party access with User's unique username, password or other security measure, if applicable, including, but not limited to, misleading, false, or inaccurate information;</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>User's wilful misconduct; or</Text>
          </Unorderedlist>
          <Unorderedlist style={styles.list}>
            <Text style={styles.bodyCopy}>statutory provision by User or its affiliates, officers, directors, agents, co-branders, partners, suppliers and employees to the extent allowed by applicable law.</Text>
          </Unorderedlist>
        </View>
        <View style={styles.section_out}>
          <Text style={styles.sectionHead}>Common provisions</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>No Waiver</Text>
          <Text style={styles.bodyCopy}>The Owner's failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision. No waiver shall be considered a further or continuing waiver of such term or any other term.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>Service interruption</Text>
          <Text style={styles.bodyCopy}>To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.</Text>
          <Text style={styles.bodyCopy}>Within the limits of law, the Owner may also decide to suspend or terminate the Service altogether. If the Service is terminated, the Owner will cooperate with Users to enable them to withdraw Personal Data or information in accordance with applicable law.</Text>
          <Text style={styles.bodyCopy}>Additionally, the Service might not be available due to reasons outside the Owner's reasonable control, such as "force majeure" (eg. labor actions, infrastructural breakdowns or blackouts etc).</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>Service reselling</Text>
          <Text style={styles.bodyCopy}>Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of this Application and of its Service without the Owner's express prior written permission, granted either directly or through a legitimate reselling programme.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>Privacy policy</Text>
          <Text style={styles.bodyCopy}>To learn more about the use of their Personal Data, Users may refer to the privacy policy of this Application.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>Intellectual property rights</Text>
          <Text style={styles.bodyCopy}>Without prejudice to any more specific provision of these Terms, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights related to this Application are the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties relating to intellectual property.</Text>
          <Text style={styles.bodyCopy}>All trademarks — nominal or figurative — and all other marks, trade names, service marks, word marks, illustrations, images, or logos appearing in connection with this Application are, and remain, the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties related to intellectual property.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>Changes to these Terms</Text>
          <Text style={styles.bodyCopy}>The Owner reserves the right to amend or otherwise modify these Terms at any time. In such cases, the Owner will appropriately inform the User of these changes.</Text>
          <Text style={styles.bodyCopy}>Such changes will only affect the relationship with the User for the future.</Text>
          <Text style={styles.bodyCopy}>The continued use of the Service will signify the User's acceptance of the revised Terms. If Users do not wish to be bound by the changes, they must stop using the Service. Failure to accept the revised Terms, may entitle either party to terminate the Agreement.</Text>
          <Text style={styles.bodyCopy}>The applicable previous version will govern the relationship prior to the User's acceptance. The User can obtain any previous version from the Owner.</Text>
          <Text style={styles.bodyCopy}>If required by applicable law, the Owner will specify the date by which the modified Terms will enter into force.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>Assignment of contract</Text>
          <Text style={styles.bodyCopy}>The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms, taking the User's legitimate interests into account. Provisions regarding changes of these Terms will apply accordingly.</Text>
          <Text style={styles.bodyCopy}>Users may not assign or transfer their rights or obligations under these Terms in any way, without the written permission of the Owner.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>Contacts</Text>
          <Text style={styles.bodyCopy}>All communications relating to the use of this Application must be sent using the contact information stated in this document.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>Severability</Text>
          <Text style={styles.bodyCopy}>Should any provision of these Terms be deemed or become invalid or unenforceable under applicable law, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.</Text>
          <Text style={styles.subhead}>US Users</Text>
          <Text style={styles.bodyCopy}>Any such invalid or unenforceable provision will be interpreted, construed and reformed to the extent reasonably required to render it valid, enforceable and consistent with its original intent. These Terms constitute the entire Agreement between Users and the Owner with respect to the subject matter hereof, and supersede all other communications, including but not limited to all prior agreements, between the parties with respect to such subject matter. These Terms will be enforced to the fullest extent permitted by law.</Text>
          <Text style={styles.subhead}>EU Users</Text>
          <Text style={styles.bodyCopy}>Should any provision of these Terms be or be deemed void, invalid or unenforceable, the parties shall do their best to find, in an amicable way, an agreement on valid and enforceable provisions thereby substituting the void, invalid or unenforceable parts.</Text>
          <Text style={styles.bodyCopy}>In case of failure to do so, the void, invalid or unenforceable provisions shall be replaced by the applicable statutory provisions, if so permitted or stated under the applicable law.</Text>
          <Text style={styles.bodyCopy}>Without prejudice to the above, the nullity, invalidity or the impossibility to enforce a particular provision of these Terms shall not nullify the entire Agreement, unless the severed provisions are essential to the Agreement, or of such importance that the parties would not have entered into the contract if they had known that the provision would not be valid, or in cases where the remaining provisions would translate into an unacceptable hardship on any of the parties.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>Governing law</Text>
          <Text style={styles.bodyCopy}>These Terms are governed by the law of the place where the Owner is based, as disclosed in the relevant section of this document, without regard to conflict of laws principles.</Text>
          <Text style={styles.subhead}>Exception for European Consumers</Text>
          <Text style={styles.bodyCopy}>However, regardless of the above, if the User qualifies as a European Consumer and has their habitual residence in a country where the law provides for a higher consumer protection standard, such higher standards shall prevail.</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.subsectionHead}>Venue of jurisdiction</Text>
          <Text style={styles.bodyCopy}>The exclusive competence to decide on any controversy resulting from or connected to these Terms lies with the courts of the place where the Owner is based, as displayed in the relevant section of this document.</Text>
          <Text style={styles.subhead}>Exception for European Consumers</Text>
          <Text style={styles.bodyCopy}>The above does not apply to any Users that qualify as European Consumers, nor toConsumers based in Switzerland, Norway or Iceland.</Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  section: {

  },
  sectionHead: {
    fontSize: 21,
    lineHeight: 22,
    fontFamily: defaultStyles.interSemi,
    marginTop: 18
  },
  subsectionHead: {
    fontSize: 18,
    lineHeight: 22,
    fontFamily: defaultStyles.interSemi,
    marginTop: 18,
    marginBottom: 8
  },
  subhead: {
    fontSize: 16,
    lineHeight: 20,
    fontFamily: defaultStyles.interSemi,
    marginTop: 18
  },
  list: {
    marginLeft: 8,
    fontSize: 24,
    lineHeight: 24
  },
  bodyCopy: {
    fontSize: 14,
    lineHeight: 21,
    marginBottom: 8
  },
  section_out: {

  },
  title: {
    fontSize: 28,
    fontFamily: defaultStyles.headingFont,
    marginBottom: 18
  }
});