import React, { useState } from "react";
export const NearbyLocationsContext = React.createContext();

export const NearbyLocationsProvider = ({ children }) => {
  const [locations, setLocations] = useState({});
  return (
    <NearbyLocationsContext.Provider
      value={[locations, setLocations]}
    >
      {children}
    </NearbyLocationsContext.Provider>
  );
};