import React, {useContext} from 'react'
import { View, StyleSheet, Image } from 'react-native'
// components
import { colors, circleImage } from '../../utils/constants'
import { AuthContext } from '../../lib/context/Auth'

export default (props) => {
  const { currentUser } = useContext(AuthContext);

  return (
    <View style={[circleImage, {height: 35, width: 35, marginTop: 8, borderColor: colors.grayDarkest}]}>
      <Image 
      source={{ 
        uri: currentUser.providerData[0].photoURL || 'https://firebasestorage.googleapis.com/v0/b/primary-4a0e7.appspot.com/o/pottymouth%2Fprofiles%2Fdefault-image.jpg?alt=media&token=95612da9-0fac-44d3-96af-a889e664d42d',
        cache: 'force-cache'
      }} 
      style={styles.profileImage} />
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8
  },
  text: {
    fontSize: 25,
    fontWeight: '500',
  },
  logo: {
    width: 260,
    height: 60
  },
  profileImage: {
    height: 35,
    width: 35
  },
  input: {
    padding: 4,
    borderWidth: 1,
    borderColor: colors.grayLightest,
    height: 50
  }
});