import React, { useState, useEffect } from 'react'
import { StyleSheet, View, ActivityIndicator, Text, SafeAreaView, ScrollView, Dimensions, TextInput, Pressable } from 'react-native'

// utility
import { defaultStyles, colors, buttons } from '../utils/constants'
// components
import localImage from '../components/images'
import Ionicons from '@expo/vector-icons/Ionicons'
import { getByFilter } from '../data/getters'
import SimpleCardItem from '../components/cards/simpleCardItem'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.
TextInput.defaultProps = Text.defaultProps || {};
TextInput.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default ({route, navigation}) => {
  const [sortOption, setSortOption] = useState('review');
  const [isWorstOf, setIsWorstOf] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredResults, setFilteredResults] = useState();
  const [isReview, setIsReview] = useState();
  const [notEnoughData, setNotEnoughData] = useState();

  const updateByFilter = async (props) => {
    const {filterCategory, filterType} = props;
    const results = await getByFilter({
      category: filterCategory,
      type: filterType
    });
    if(!results) {
      setNotEnoughData(true);
      return
    }
    setFilteredResults(results.review);
    setIsReview(filterType === 'review');
  }
  useEffect(() => {
    updateByFilter({
      filterCategory: 'wow',
      filterType: 'review'
    })
  },[]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1400);
  },[isWorstOf]);
  return (
    <SafeAreaView style={{backgroundColor:'#fff', position:'relative', height: Dimensions.get("window").height}} forceInset={{ bottom: 'never'}}>
      <View style={{flexDirection: 'row'}}>
        <View style={{width: '25%', paddingRight: 16, paddingLeft: 4}}>
          <Pressable onPress={() => navigation.goBack()} style={{flexDirection: "row", alignItems: "center"}}>
            <Ionicons name="arrow-back-outline" size={24} color="#000" />
            <Text style={{fontSize: 18, marginLeft: 4, color: '#000'}}>Back</Text>
          </Pressable>
        </View>
      </View>
      <View style={styles.container}>
        <View style={[{width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}]}>
          <View>
            <Text style={styles.title}>{isWorstOf ? 'worst' : 'best'}</Text>
            <Text>of the Week.</Text>
          </View>
          <Pressable onPress={() => setIsWorstOf(!isWorstOf)} style={{flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap', width: '25%'}}>
            <Ionicons name="swap-vertical-outline" size={24} color="#000" />
            <Text style={{fontSize: 10, width: '100%', textAlign: 'center'}}>BOW / WOW</Text>
          </Pressable>
        </View>
        <View style={{flexDirection: 'row', marginVertical: 16}}>
          <Pressable onPress={() => setSortOption('review')} style={[styles.sortButton, sortOption == 'review' ? styles.selected : '']}>
            <Text style={styles.sortButtonText}>Individual Review</Text>
          </Pressable>
          <Pressable onPress={() => setSortOption('score')} style={[styles.sortButton, sortOption == 'score' ? styles.selected : '']}>
            <Text style={styles.sortButtonText}>Overall Score</Text>
          </Pressable>
          <Pressable onPress={() => setSortOption('distance')} style={[styles.sortButton, sortOption == 'distance' ? styles.selected : '']}>
            <Text style={styles.sortButtonText}>Distance</Text>
          </Pressable>
        </View>
      </View>
      <ScrollView 
        style={styles.container}
        keyboardOpeningTime={0}
        extraScrollHeight={30}
        contentInset={{ bottom: 300 }}
      >
        {!!isLoading && 
        <View style={{flexDirection: 'row', justifyContent: 'center', height: 300, alignItems: 'center'}}>
           <ActivityIndicator size="large" color={colors.primary} />
        </View>
        }
        {!notEnoughData && !isLoading &&  <Text style={{marginBottom: 24, marginTop: 8, lineHeight: 22}}>Every week we highlight the top 5 worst reviews / locations across the country.</Text> }
        {!!isReview && !isLoading && <SimpleCardItem {...filteredResults} /> }
        {!!notEnoughData && !isLoading && 
        <View style={{paddingTop: 120}}>
          <View style={{flexDirection: 'row', justifyContent: 'center'}}>
            <Ionicons name="sad-outline" size={44} color="#000" />
          </View>
          <Text style={{fontSize: 34, fontFamily: defaultStyles.interSemi, textAlign: 'center', marginTop: 8}}>Insufficient Data</Text>
          <Text style={{textAlign: 'center', fontSize: 14, lineHeight: 21}}>We don't have enough data to support this feature yet. Help us improve this feature by posting a review!</Text>
          <Pressable onPress={() => navigation.navigate('create')} style={[buttons.primary, {marginVertical: 16}]}><Text style={{lineHeight: 30, color: '#fff'}}>Add a new review</Text></Pressable>
        </View>
        }
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: { 
   paddingHorizontal: 12,
  },
  logo: {
    width: 175,
    height: 30,
    marginBottom: 8
  },
  errorText: {
    fontSize: 10,
    color: 'red',
  },
  title: {
    fontFamily: defaultStyles.headingFont,
    fontSize: 42,
    marginTop: 12,
    textTransform: 'uppercase'
  },
  formItem: {
    marginTop: 24
  },
  fieldLabel: {
    fontSize: 14,
    marginBottom: 12,
    color: colors.black,
    textTransform: 'uppercase'
  },
  sortButton: {
    paddingHorizontal: 18,
    backgroundColor: colors.graySecondary,
    borderRadius: 24,
    marginRight: 8
  },
  sortButtonText: {
    color: '#fff',
    lineHeight: 36,
    fontSize: 12
  },
  selected: {
    backgroundColor: '#000'
  },
  disabledButton: {
    backgroundColor: colors.grayLightest,
  }
});