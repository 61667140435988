export default (arr) => {
  const perChunk = 10 // items per chunk    
  if(arr){
    return arr.reduce((resultArray, item, index) => { 
      const chunkIndex = Math.floor(index/perChunk)
  
      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }
  
      resultArray[chunkIndex].push(item)
  
      return resultArray
    }, [])
  }
}