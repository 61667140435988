import React, { useState, useEffect } from 'react'
import { StyleSheet, View, ActivityIndicator, Text, SafeAreaView, Dimensions, TextInput, Pressable, Image } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

// utility
import { defaultStyles, colors, formStyles, buttons } from '../utils/constants'
// components
import localImage from '../components/images'
import BackButton from '../components/global/buttons/Back'

// firebase
import { PottyMouthAuth } from '../lib/firebase'
import { signInWithEmailAndPassword } from 'firebase/auth'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.
TextInput.defaultProps = Text.defaultProps || {};
TextInput.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default ({route, navigation}) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [formError, setFormError] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  
  const loginWithEmail = async () => {
    signInWithEmailAndPassword(PottyMouthAuth, email.toLowerCase().trim(), password).then((userCredential) => {
    })
    .catch((error) => {
      let formErrorMessage;
      switch (error.message){
        case "Firebase: Error (auth/wrong-password).":
          formErrorMessage = "Sorry, that password is incorrect. Please try again."
        break;
        case "Firebase: Error (auth/user-not-found).":
          formErrorMessage = "Sorry, we could not find an account for that email."
        break;
        default:
          formErrorMessage = error.message;
      }
      setErrorMessage(formErrorMessage);
      setFormError(true);
    });
  }
  useEffect(() => {
    if(email?.length > 5 && password?.length > 7){
      setFormError(false);
    } else {
      setFormError(true);
    }
  },[email,password]);

  return (
    <SafeAreaView style={{backgroundColor:'#fff', position:'relative', height: Dimensions.get("window").height}} forceInset={{ bottom: 'never'}}>
      {!!isLoading && 
        <View style={[{position: 'absolute', top: 300, flexDirection: 'row', justifyContent: 'center', alignItems:'center', width: '100%', zIndex: 10, flexWrap: 'wrap'}]}>
          <ActivityIndicator size="large" color={colors.primary} />
        </View> 
       }
      <View style={{flexDirection: 'row'}}>
        <View style={{width: '25%', paddingRight: 16, paddingLeft: 4}}>
          <Pressable onPress={() => navigation.goBack()} style={{flexDirection: "row", alignItems: "center"}}>
            <BackButton />
          </Pressable>
        </View>
        <View style={{width: '50%', flexDirection: 'row', justifyContent: 'center'}}>
          <Image resizeMode={'contain'} defaultSource={localImage.logo} style={styles.logo} />
        </View>
        <View style={{width: '25%', paddingHorizontal: 16}}>
        </View>
      </View>
      <KeyboardAwareScrollView 
        style={styles.container}
        keyboardOpeningTime={0}
        extraScrollHeight={30}
        enableResetScrollToCoords
        contentInset={{ bottom: 300 }}
      >
        <View style={{width: '100%', paddingTop: 8}}>
          <Text style={styles.title}>Hello there, welcome back.</Text>
          <Text style={{marginBottom: 24, marginTop: 8}}>Please enter your email and password below.</Text>
          {!!errorMessage && 
          <View>
            <Text style={{color: colors.danger, textAlign: 'center'}}>{errorMessage}</Text>
          </View>
          }
          <View style={styles.formItem}>
            <Text style={styles.fieldLabel}>Email</Text>
            <View style={{position: 'relative'}}>
              <TextInput
                value={email}
                onChangeText={(val) => setEmail(val.toLowerCase())}
                placeholder={'you@somedomain.com'}
                style={formStyles.textInput}
                clearButtonMode="unless-editing"
                autoCorrect={false}
                inputmode="email"
              />
            </View>
          </View>

          <View style={styles.formItem}>
            <Text style={styles.fieldLabel}>Password</Text>
            <TextInput
              value={password}
              onChangeText={(val) => setPassword(val)}
              placeholder={'password'}
              style={[formStyles.textInput]}
              clearButtonMode="unless-editing"
              autoCorrect={false}
              secureTextEntry={true}
            />
          </View>
          <Pressable disabled={formError} onPress={() => loginWithEmail() } style={[buttons.primary, formError && styles.disabledButton, styles.submitButton]}>
            <Text style={{color: colors.grayTertiary}}>Sign in</Text>
          </Pressable>
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: { 
   flex: 1,
   paddingHorizontal: 16,
   paddingBottom: 60,
  },
  logo: {
    width: 175,
    height: 30,
    marginBottom: 8
  },
  errorText: {
    fontSize: 10,
    color: 'red',
  },
  title: {
    fontFamily: defaultStyles.interSemi,
    fontSize: 36,
    marginTop: 32,
    paddingRight: 32,
    textTransform: 'capitalize'
  },
  formItem: {
    marginTop: 24
  },
  fieldLabel: {
    fontSize: 14,
    marginBottom: 12,
    color: colors.black,
    textTransform: 'uppercase'
  },
  submitButton: {
    marginTop: 18,
    paddingVertical: 18,
    width: '100%'
  },
  disabledButton: {
    backgroundColor: colors.grayLightest,
  }
});