import React from 'react'
import { View, Text, Pressable, Dimensions, Image } from 'react-native'
import { colors } from '../../../utils/constants'
import Ionicons from '@expo/vector-icons/Ionicons'
import ProfileButton from '../profileButton'
export default ({ state, descriptors, navigation }) => {
  const primaryBgColor = '#051923';
  const navHighlightColor = '#0582ca';
  const navDefaultColor = '#334e5b';
  const actionColorColor = '#00a6fb';
  return (
    <View style={{flexDirection: 'row', justifyContent: 'center'}}>
      <View style={{
        display: 'flex',
        width:'100%',
        flexDirection: 'row',
        backgroundColor: primaryBgColor,
        paddingHorizontal: 15,
        paddingBottom: 20,
        justifyContent: 'space-between'
      }}>
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];
          const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name;

          const isFocused = state.index === index;

          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            });

            if (!isFocused && !event.defaultPrevented) {
              // The `merge: true` option makes sure that the params inside the tab screen are preserved
              navigation.navigate({ name: route.name, merge: true });
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: 'tabLongPress',
              target: route.key,
            });
          };
          const navColor = isFocused ? navHighlightColor : navDefaultColor;
          const iconColor = isFocused ? navHighlightColor : navDefaultColor;
          const navBorderColor = isFocused ? actionColorColor : 'transparent';
          const middleIcon = {
            marginTop: -10, 
            backgroundColor: actionColorColor,
            borderRadius: 70,
            height: 70,
            width: 70,
          }
          return (
            <Pressable
              accessibilityRole="button"
              accessibilityState={isFocused ? { selected: true } : {}}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              onLongPress={onLongPress}
              style={[{flexDirection:"row", justifyContent: "center", flexWrap: 'wrap', width: '18%', borderTopColor: options.primary ? 'transparent' : navBorderColor, borderTopWidth: 4}, options.primary ? middleIcon : '']}
              key={label}
            > 
              {options.icon && 
                <View style={{alignSelf: "center", marginTop: 14}}>
                  <Ionicons name={options.icon} size={28} color={iconColor} />
                </View> 
              }
              {options.profile && 
                <ProfileButton />
              }
              {options.primary && 
                <View style={{flexDirection: 'row', alignItems: 'center', height: '100%'}}>
                  <Ionicons name={options.primary} size={40} color={isFocused ? '#fdfcdc' : colors.white} />
                </View> 
              }
              {label && <View style={{width: '100%'}}>
                <Text style={{ color: navColor, fontSize: options.primary ? 14 : 10, textAlign: 'center'}}>
                  {label}
                </Text>
              </View> }
            </Pressable>
          );
        })}
      </View>
    </View>
  );
}