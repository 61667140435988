import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import WelcomePage from '../../../pages/index'
import LoginWithEmailPage from '../../../pages/loginEmail'
import RegisterWithEmailPage from '../../../pages/registrationEmail'
import TermsPage from '../../../pages/static/terms'
import PolicyPage from '../../../pages/static/policy'
import DynamicReview from '../../../pages/dynamicReview'
const screenOptionStyle = {
  headerShown: false,
};

const NoAuthNavigator = () => {
  const Stack = createNativeStackNavigator();
  return (
    <Stack.Navigator screenOptions={screenOptionStyle} initialRouteName='welcome'>
      <Stack.Group>
        <Stack.Screen name='welcome' component={WelcomePage} options= {() => ({ title: "Pottymouth | Get Started"})} />
        <Stack.Screen name='login' component={LoginWithEmailPage} options= {() => ({ title: "Pottymouth | Log in"})} />
        <Stack.Screen name='dynamicReview' component={DynamicReview} options= {() => ({ title: "Pottymouth | Review from Sticker"})}  />
        <Stack.Screen name='emailSignup' component={RegisterWithEmailPage} options= {() => ({ title: "Pottymouth | Sign Up"})}/>
        <Stack.Screen name='toc' component={TermsPage} options= {() => ({ title: "Pottymouth | Terms and Conditions"})}  />
        <Stack.Screen name='policy' component={PolicyPage} options= {() => ({ title: "Pottymouth | Privacy Policy"})}  />
      </Stack.Group>
    </Stack.Navigator>
  );
}

export default NoAuthNavigator;