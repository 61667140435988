import React, {useEffect, useState, useContext, useRef} from 'react'
import { StyleSheet, View, Pressable, Text, TextInput, Image, Dimensions, ScrollView, ActivityIndicator, Platform} from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { AuthContext } from '../lib/context/Auth'
// Utility
import { colors, defaultStyles, formStyles, buttons } from '../utils/constants'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

// components
import * as ImagePicker from 'expo-image-picker'
import Search from '../components/global/search'
import Ionicons from '@expo/vector-icons/Ionicons'
import StarRating from 'react-native-star-rating-widget'

// data manipulation
import { addNewRating, updateFields } from '../data/setters'
import { getUserRecord } from '../data/getters'
import Tooltip from '../components/global/tooltip'
import {setLocalObjectValue, removeLocalStorageItem} from '../lib/localStorage'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.
TextInput.defaultProps = Text.defaultProps || {};
TextInput.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default ({route, navigation}) => {
  const defaultSelection = route?.params?.id ? {...route.params} : {id: null, address: null, name:null, geometry: null, gender: null};
  const { currentUser } = useContext(AuthContext);
  const [image, setImage] = useState(null);
  const [updateProp, setUpdateProp] = useState(); 
  //const [locationResults, setLocationResults] = useState();
  const [locationSelected, setLocationSelected] = useState(defaultSelection);
  const [readyToSelect, setReadyToSelect] = useState(false);
  const [reviewTitle, setReviewTitle] = useState();
  const [reviewDescription, setReviewDescription] = useState();
  const [cleaninessRating, setCleaninessRating] = useState(0);
  const [tpqualityRating, setTpqualityRating] = useState(0);
  const [stallRating, setStallRating] = useState(0);
  const [privacyRating, setPrivacyRating] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successfulPost, setSuccessfulPost] = useState(null);
  const [isFirstTime, setIsFirstTime] = useState();
  const insets = useSafeAreaInsets();
  const [restroomType, setRestroomType] = useState(null);
  const uiPadding = Platform.OS === 'ios' ? insets.top : 30;
  const scrollref = useRef(null);

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.2,
    });

    if (!result.cancelled) {
      setImage(result?.uri);
    }
  };

  const resetCreate = () => {
    setReviewTitle('');
    setReviewDescription('');
    setCleaninessRating(0);
    setTpqualityRating(0);
    setStallRating(0);
    setPrivacyRating(0);
    setHasError(false);
    setIsLoading(false);
    setIsFirstTime(false);
    setRestroomType(null);
    setLocationSelected({id: null, address: null, name: null});
    setSuccessfulPost(null);
    setSuccessfulPost(true);
  }

  const handleReviewSubmit = async () => {
    if(cleaninessRating == 0 || tpqualityRating == 0 || stallRating == 0 || privacyRating == 0 || restroomType == null){
      setHasError(true);
      if(scrollref) {
        scrollref.current.scrollTo({x: 0, y: 0, animated: true})
      }
      return;
    }
    const overAllRating = (cleaninessRating + tpqualityRating + stallRating + privacyRating) / 4;
    setIsLoading(true);
    setSuccessfulPost(null);
    const payload = {
      address: locationSelected.address,
      gender: restroomType,
      id: locationSelected.id,
      name: locationSelected.name,
      title: reviewTitle || '',
      description: reviewDescription || '',
      ratings: {
        overall: overAllRating,
        cleaniness: cleaninessRating,
        tpquality: tpqualityRating,
        stall: stallRating,
        privacy: privacyRating
      },
      image: image || null,
      user: {
        id: currentUser.providerData[0].uid
      }
    }
    setTimeout(() => {
      if(successfulPost != true) {
        setLocalObjectValue('postqueue',payload);
        setSuccessfulPost('failed');
        setIsLoading(false);
      } 
    }, 10000);

   const reviewResponse = await addNewRating(payload);
   if(reviewResponse.status === 200) {
    setSuccessfulPost(true);
    removeLocalStorageItem('@postqueue');
    setTimeout(() => {
      setIsLoading(false);
      navigation.navigate(
        'locationDetail', {
          id: reviewResponse.id,
          name: locationSelected.name,
          address: locationSelected.name,
          totalreviews: reviewResponse.total,
          geometry: locationSelected.geometry
        }
      )
    },800) 
   } else {
    setSuccessfulPost('failed');
    setIsLoading(false);
   }
  }

  useEffect(() => {
    const checkFromFirestore = async () => {
      if(route?.params?.failedRequest) {
        const unpostedReview = route.params.failedRequest;
        console.log('populate with failed request');
        setReviewTitle(unpostedReview.title);
        setReviewDescription(unpostedReview.description);
        setCleaninessRating(unpostedReview.ratings.cleaniness);
        setTpqualityRating(unpostedReview.ratings.tpquality);
        setStallRating(unpostedReview.ratings.stall);
        setPrivacyRating(unpostedReview.ratings.privacy);
        setRestroomType(unpostedReview.gender);
        return;
      }
      const userLiveRecord = await getUserRecord([{ uid: currentUser.providerData[0].uid}]);
      if(userLiveRecord.firstReview) {
        updateFields(currentUser.providerData[0].uid, {firstReview: false});
        setIsFirstTime(true);
      } else {
        setReadyToSelect(true);
      }
    }
    checkFromFirestore();
  },[]);


  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      resetCreate();
      if(!!route?.params?.id) {
        setLocationSelected({...route.params});
        setReadyToSelect(true);
      } 
      const randomNum = Math.floor(Math.random() * 500);
      setUpdateProp(randomNum);
    });
    return unsubscribe;
  }, [navigation, route]);

  useEffect(() => {
    if(scrollref) {
      scrollref.current.scrollTo({x: 0, y: 0, animated: true})
    }
    if(!restroomType && !!locationSelected?.gender) {
      setRestroomType(locationSelected.gender);
    }
  },[readyToSelect,locationSelected]);
  return (
    <>
      {successfulPost == 'failed' && 
      <View style={{position: 'absolute', top:0, left: 0, zIndex: 20, width: '100%', paddingHorizontal: 20, paddingTop: uiPadding, paddingBottom: 16, backgroundColor: colors.grayLightest}}>
        <View style={{flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap'}}>
          <Text style={{fontSize: 14}}>Something went wrong. Your review did not post.</Text>
          <Pressable onPress={() => handleReviewSubmit()} style={[buttons.primary, {marginTop: 12}]}><Text style={{fontSize: 14, color:'#fff'}}>Retry</Text></Pressable>
        </View>
      </View>}
      {!!isLoading &&
      <View style={[{position: 'absolute', top: 0, height: '100%', flexDirection: 'row', justifyContent: 'center', alignItems:'center', width: '100%', zIndex: 10, backgroundColor: colors.grayLightest}]}>
        <View>
          <ActivityIndicator size="large" color={colors.primary} />
          <View style={{width: '100%', marginTop: 16}}>
          <Text style={{textAlign:'center'}}>Please wait while we add your review.</Text>
          </View>
        </View>
      </View> }
      <ScrollView
        ref={scrollref}
        style={styles.container}
        keyboardShouldPersistTaps="handled"
        alwaysBounceVertical={false}
        scrollsToTop={true}
        scrollToOverflowEnabled={true}
        contentContainerStyle={{
          paddingTop: uiPadding,
          paddingBottom: 42,
          backgroundColor: colors.background,
          minHeight: Dimensions.get("window").height
        }}>
          <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 8, height: 50, width: '100%'}}>
            {!!readyToSelect && !!locationSelected.id && !isLoading ?
              <Pressable onPress={() => resetCreate() } style={{flexDirection: "row", alignItems: "center"}}>
                <Ionicons name="refresh-outline" size={21} color="#000" />
                <Text style={{fontSize: 16, marginLeft: 4, color: '#000'}}>Start Over</Text>
              </Pressable> : 
              <View style={{flexDirection:'row', alignItems: 'center'}}>
                <Ionicons name="create-outline" size={24} color="#000" />
                <Text style={{marginLeft: 8}}>Select an establishment</Text>
              </View>
            }
            <Pressable onPress={() => navigation.goBack()}>
              <Text style={{fontSize: 15, color: '#000', padding: 5}}>Cancel</Text>
            </Pressable>
          </View>
          {!readyToSelect && isFirstTime && 
          <View style={[{backgroundColor:'#ff0', paddingVertical: 100, paddingHorizontal: 20, top: '15%', borderRadius: defaultStyles.borderRadius, backgroundColor: '#fff'}]}>
            <Text 
            style={{
              fontFamily: defaultStyles.interSemi, 
              fontSize: 42, 
              marginBottom: 6, 
              textTransform: 'capitalize'
            }}>Ready to post a review?</Text>
            <Text>In order to post a review you need to select an establishment.</Text>
            <Pressable style={[buttons.primary, {marginTop: 24}]} onPress={() => setReadyToSelect(true)}>
              <Text style={{color: '#fff'}}>Show me establishments</Text>
            </Pressable>
          </View>
          }

          {!!readyToSelect && !!locationSelected.id && !isLoading &&
            <KeyboardAwareScrollView>
            <View style={{backgroundColor: colors.background, paddingVertical: 16, paddingHorizontal: 8, marginBottom: 16, flexDirection: 'row', justifyContent: 'space-between'}}>
              <View style={{width: '80%'}}>
                <Text style={styles.title}>{locationSelected.name}</Text>
                <Text>{locationSelected.address}</Text>
              </View>
              <Pressable onPress={pickImage} style={{flexDirection: 'row', justifyContent: 'flex-end', flexWrap: 'wrap', width:'20%'}}>
                <View style={{width: 50, height: 50, padding: 5, left: -4, backgroundColor:'#000', borderRadius: 50, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}>
                  <Ionicons name="image-outline" size={32} color="#fff" />
                </View>
                <Text style={{fontSize: 12}}>add photo</Text>
              </Pressable>
            </View>
            <View>
              {hasError && <Text style={{padding: 6, color: '#f00'}}>Restroom type and all review areas are required.</Text>}
              {image && <View style={{marginBottom: 16}}><Image source={{ uri: image }} style={styles.imageContainer} /></View>}
              <View style={{paddingHorizontal: 8, marginBottom: 16}}>
                <Text style={{marginBottom: 16, fontSize: 14}}>Restroom type:</Text>
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                  <Pressable style={[styles.genderTypeContainer]} onPress={() => setRestroomType('women')}>
                    <View style={[styles.genderTypeIcon, {backgroundColor: restroomType === 'women' ? '#000' : '#fff'}]}>
                      <Ionicons name="woman-outline" size={32} color={restroomType === 'women' ? '#fff' : colors.grayTertiary} />
                    </View>
                    <Text style={[styles.genderTypeLabel, {color: restroomType === 'women' ? '#000' : colors.grayTertiary}]}>Women</Text>
                  </Pressable>
                  <Pressable style={[styles.genderTypeContainer]} onPress={() => setRestroomType('men')}>
                    <View style={[styles.genderTypeIcon, {backgroundColor: restroomType === 'men' ? '#000' : '#fff'}]}>
                      <Ionicons name="man-outline" size={32} color={restroomType === 'men' ? '#fff' : colors.grayTertiary} />
                    </View>
                    <Text style={[styles.genderTypeLabel, {color: restroomType === 'men' ? '#000' : colors.grayTertiary}]}>Men</Text>
                  </Pressable>
                  <Pressable style={[styles.genderTypeContainer]} onPress={() => setRestroomType('unisex')}>
                    <View style={[styles.genderTypeIcon, {backgroundColor: restroomType === 'unisex' ? '#000' : '#fff'}]}>
                      <Ionicons name="people-outline" size={32} color={restroomType === 'unisex' ? '#fff' : colors.grayTertiary} />
                    </View>
                    <Text style={[styles.genderTypeLabel, {color: restroomType === 'unisex' ? '#000' : colors.grayTertiary}]}>Unisex</Text>
                  </Pressable>
                  <Pressable style={[styles.genderTypeContainer]} onPress={() => setRestroomType('family')}>
                    <View style={[styles.genderTypeIcon, {backgroundColor: restroomType === 'family' ? '#000' : '#fff'}]}>
                      <Ionicons name="basket-outline" size={32} color={restroomType === 'family' ? '#fff' : colors.grayTertiary} />
                    </View>
                    <Text style={[styles.genderTypeLabel, {color: restroomType === 'family' ? '#000' : colors.grayTertiary}]}>Family</Text>
                  </Pressable>
                </View>
              </View>
              <TextInput
                value={reviewTitle}
                onChangeText={(val) => setReviewTitle(val)}
                placeholder={'Review title'}
                style={[formStyles.textInput, {height: 60, marginBottom: 8}]}
                returnKeyType="done"
              />
              <TextInput
                value={reviewDescription}
                onChangeText={(val) => setReviewDescription(val)}
                placeholder={'Review description'}
                multiline
                numberOfLines={6}
                style={[formStyles.textInput, {height: 130}]}
              />
            </View>
            <View style={{marginTop: 16}}>
              <View style={styles.scoreSection}>
                <Text style={styles.sectionTitle}>Cleanliness:</Text>
                <StarRating
                  enableSwiping={true}
                  maxStars={5}
                  enableHalfStar={true}
                  rating={cleaninessRating}
                  starSize={30}
                  emptyStarColor={colors.gray}
                  starStyle={{width: 5, marginLeft: 0, marginRight: 28}}
                  onChange={(rating) => setCleaninessRating(rating)}
                  color={colors.primary}
                />
              </View>

              <View style={styles.scoreSection}>
                <Text style={[styles.sectionTitle]}>Toiletries:<Tooltip bodyText="Toliet paper, soap, seat covers, etc." /></Text>
                <StarRating
                  enableSwiping={true}
                  maxStars={5}
                  enableHalfStar={true}
                  rating={tpqualityRating}
                  starSize={30}
                  emptyStarColor={colors.gray}
                  starStyle={{width: 5, marginLeft: 0, marginRight: 28}}
                  onChange={(rating) => setTpqualityRating(rating)}
                  color={colors.primary}
                />
              </View>

              <View style={styles.scoreSection}>
                <Text style={styles.sectionTitle}>Stall Quality:</Text>
                <StarRating
                  enableSwiping={true}
                  maxStars={5}
                  enableHalfStar={true}
                  rating={stallRating}
                  starSize={30}
                  emptyStarColor={colors.gray}
                  starStyle={{width: 5, marginLeft: 0, marginRight: 28}}
                  onChange={(rating) => setStallRating(rating)}
                  color={colors.primary}
                />
              </View>

              <View style={[styles.scoreSection, {marginBottom: 0}]}>
                <Text style={styles.sectionTitle}>Privacy:</Text>
                <StarRating
                  enableSwiping={true}
                  maxStars={5}
                  enableHalfStar={true}
                  rating={privacyRating}
                  starSize={30}
                  emptyStarColor={colors.gray}
                  starStyle={{width: 5, marginLeft: 0, marginRight: 28}}
                  onChange={(rating) => setPrivacyRating(rating)}
                  color={colors.primary}
                />
              </View>
            </View>
            <Pressable style={[buttons.primary, {marginVertical: 24}]} onPress={() => handleReviewSubmit()}>
              <Text style={{color: '#fff', textTransform: 'capitalize'}}>Post review</Text>
            </Pressable>
            </KeyboardAwareScrollView>
          }

          {!!readyToSelect && locationSelected.id == null &&
          <>
            <View style={{position: 'relative', zIndex: 5, marginTop: -24}}>
              <Search 
                resultsType="locations" 
                background={colors.background} 
                containerBackground='#fff'
                onLocationSelect={(item) => setLocationSelected(item) } 
                updateProp={updateProp}
              />
            </View>
          </>
          }
      </ScrollView>
    </>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    padding: 8,
  },
  controls: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 16,
    marginBottom: 12
  },
  button: {
    backgroundColor: colors.primary,
    borderRadius: defaultStyles.borderRadius,
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  title: {
    fontSize: 20,
    lineHeight: 22,
    marginBottom: 6,
    fontFamily: defaultStyles.interSemi,
  },
  text: {
    fontSize: 25,
    fontWeight: '500',
  },
  imageContainer: {
    height: 200,
    width: (Dimensions.get("window").width - 100),
    marginTop: 20
  },
  profileImage: {
    height: 40,
    width: 40
  },
  sectionTitle: {
    fontSize: 16,
    marginRight: 8,
    fontFamily: defaultStyles.interSemi
  },
  scoreSection: {
    marginBottom: 12,
    paddingHorizontal: 12,
    paddingVertical: 18,
    backgroundColor: '#fcfcfc',
    marginHorizontal: 6,
    borderRadius: defaultStyles.borderRadius,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.grayLightest
  },
  imageContainer: {
    width: (Dimensions.get("window").width - 15),
    height: (Dimensions.get("window").width - 15) / 1.5
  },
  genderTypeContainer: {
    width: '16%',
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 8,
    flexWrap: 'wrap'
  },
  genderTypeLabel: {
    fontSize: 12,
    color: colors.gray
  },
  genderTypeIcon: {
    textAlign: 'center',
    height: 60,
    width: '100%',
    borderRadius: defaultStyles.borderRadius,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8
  }
});