import React, { useContext, useEffect, Suspense } from 'react'
import { ActivityIndicator, View } from 'react-native'
import NoAuthNavigator from './noAuthNavigator'
import BottomTabNavigator from './TabsNavigator'

import { GeolocationContext } from '../../../lib/context/Geolocation'
import { AuthContext } from '../../../lib/context/Auth'
import * as Location from 'expo-location'
import { setLocalItem, getLocalItem } from '../../../lib/localStorage'
import uuid from 'react-native-uuid'
import { colors } from '../../../utils/constants'

export default () => { 
  const [LocationContext, setLocationContext] = useContext(GeolocationContext); 
  //const userInfo = false;
  const { currentUser } = useContext(AuthContext);

  const DeviceCheck = async () => {
    const hasId = await getLocalItem("deviceId");
    if(hasId){ 
      return 
    } else {
      const deviceId = uuid.v4();
      await setLocalItem("deviceId", deviceId);
    }
  }

  useEffect(() => {
    const willMount = async () => {
      await DeviceCheck();
    }
    willMount();
  },[]);

  // geo location
  useEffect(() => {
    if(currentUser) {
      (async () => {
        let { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== 'granted') {
          return;
        }
  
        let location = await Location.getCurrentPositionAsync({});
        setLocationContext(location);
      })();   
    }
  }, [currentUser]);

  return (
    <Suspense fallback={
      <View style={[{position: 'absolute', top: 300, flexDirection: 'row', justifyContent: 'center', alignItems:'center', width: '100%', zIndex: 10, flexWrap: 'wrap', backgroundColor:'#fff', paddingVertical: 40}]}>
        <ActivityIndicator size="large" color={colors.primary} />
      </View> 
    }>
       {currentUser ? <BottomTabNavigator /> : <NoAuthNavigator /> }
    </Suspense>
  );
};
