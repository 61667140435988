import React, { useContext, useState } from 'react'
import { StyleSheet, View, ActivityIndicator, Text, Pressable, Image, TextInput, ScrollView, Alert, Platform } from 'react-native'
import { AuthContext } from '../lib/context/Auth'
import { PottyMouthAuth } from '../lib/firebase'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import * as ImagePicker from 'expo-image-picker'
import { signOut } from 'firebase/auth'
import Constants from 'expo-constants'
// components
// ref
import { defaultStyles, buttons, colors, circleImage,  circleImageXLarge, fallbackImage, formStyles } from '../utils/constants'

// util
import { uploadAsset } from '../data/assets'
import { updateFields, deleteAccount } from '../data/setters'
import { getUserHandleValidation } from '../data/getters'
import BackButton from '../components/global/buttons/Back'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.
TextInput.defaultProps = Text.defaultProps || {};
TextInput.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

const versionNumber = Constants.expoConfig.extra.appVersion;

export default ({route, navigation}) => {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const thisUser = currentUser.providerData[0];
  const [name, setName] = useState(thisUser.displayName);
  const [userhandle, setUserhandle] = useState(thisUser.handle);
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [handleAccepted, setHandleAccepted] = useState(true);
  const insets = useSafeAreaInsets();
  const uiPadding = Platform.OS === 'ios' ? insets.top : 30;
  const currentPhoto = currentUser?.providerData[0]?.photoURL || fallbackImage;

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 4],
      quality: 0.2,
    });

    if (!!result && !result.cancelled) {
      setIsLoading(true);
      const imageBlob = result?.uri;
      try {
        const uploadURL = await uploadAsset(imageBlob, '/profiles');
        await updateFields(thisUser.uid,{photoURL:uploadURL});
        thisUser.photoURL = uploadURL;
        setImage(imageBlob);
        setCurrentUser(currentUser);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const validateUserHandle = async (val) => {
    val = val.replace(/[^a-zA-Z0-9]/g,'_');
    if(val.length > 3) {
      const userHandleIsAvail = await getUserHandleValidation(val);
      setHandleAccepted(userHandleIsAvail);
    }
  }
  const handleUpdateField = async (field,value) => {
    if(value.length > 2) {
      setIsLoading(true);
      await updateFields(thisUser.uid,{[field]:value});
      setIsLoading(false);
      currentUser.providerData[0][field] = value;
      setCurrentUser(currentUser);
    }
  }
  const handleAccountDeletion = () => {
    Alert.alert('Are you sure?', `You can not undo this action.`, [
      {
        text: 'Cancel',
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      { text: 'OK', onPress: () => processDeletion()
      },
    ]);
  }
  const processDeletion = async () => {
    setIsLoading(true);
    await deleteAccount(thisUser.uid);
    AuthSession.dismiss();
    signOut(PottyMouthAuth).then(() => {
    // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  }
  return (
    <View style={{flex: 1, backgroundColor: colors.background, position: 'relative'}}>
      {!!isLoading && 
          <View style={[{position: 'absolute', top: 300, flexDirection: 'row', justifyContent: 'center', alignItems:'center', width: '100%', zIndex: 10, flexWrap: 'wrap', backgroundColor:'#fff', paddingVertical: 40}]}>
            <ActivityIndicator size="large" color={colors.primary} />
            <View style={{width: '100%', marginTop: 16}}>
            <Text style={{textAlign:'center'}}>updating your account.</Text>
            </View>
          </View> 
        }
      <ScrollView keyboardShouldPersistTaps="handled" vertical={true} alwaysBounceVertical={false} contentContainerStyle={{paddingBottom: 20}}>
        <View style={[styles.image, {paddingTop: uiPadding}]}>
          <View style={{paddingHorizontal: 8, marginBottom: 16}}>
            <Pressable onPress={() => navigation.navigate('profile')} style={{flexDirection: "row", alignItems: "center"}}>
              <BackButton />
            </Pressable>
          </View>
          <View style={{flexDirection: 'row', justifyContent: 'center', marginBottom: 21 }}>
            <View style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <Pressable onPress={() => pickImage()}>
                <View style={[circleImage, circleImageXLarge, {borderColor: '#fff', marginRight: 8, marginBottom: 8}]}>
                  <Image 
                  source={{ 
                    uri: image || currentPhoto,
                    cache: 'force-cache'
                    }}
                  resizeMode="cover"
                  style={styles.profileImage}
                  />
                </View> 
                <Text style={{textAlign: 'center', fontSize: 14, color: colors.gray}}>Change photo</Text>
              </Pressable>
            </View>
          </View>
        </View>
        <View style={[styles.container, {marginTop: -120}]}>
          <View style={{marginVertical: 24, backgroundColor: 'white', borderRadius: defaultStyles.borderRadius}}>
            <View style={styles.section}>
              <Text style={styles.label}>Full Name</Text>
              <TextInput
                value={name}
                onChangeText={(val) => setName(val)}
                onBlur={() => handleUpdateField('displayName', name)}
                placeholder={thisUser.displayName}
                style={[formStyles.textInput]}
                clearButtonMode="unless-editing"
                autoCorrect={false}
              />
            </View>
            <View style={styles.section}>
              <Text style={styles.label}>User Handle</Text>
              <TextInput
                value={userhandle}
                onChangeText={(val) => {setUserhandle(val.toLowerCase()); validateUserHandle(val.toLowerCase());}}
                onBlur={() => handleAccepted ? handleUpdateField('handle', userhandle) : setUserhandle(thisUser.handle)}
                placeholder={thisUser.handle}
                style={[formStyles.textInput, {borderColor: ((userhandle == thisUser.handle || userhandle.length < 3) ? colors.grayLightest : (handleAccepted ? '#3eb80e' : '#eb1111'))}]}
                clearButtonMode="unless-editing"
                autoCorrect={false}
              />
            </View>
            <View style={styles.section}>
              <Text style={styles.label}>Email</Text>
              <TextInput
                placeholder={thisUser.email}
                style={[formStyles.textInput]}
                autoCorrect={false}
                disabled={true}
                editable={false} 
                selectTextOnFocus={false}
              />
            </View>
          </View> 
          <View style={{padding: 16, marginBottom: 4}}>
            <Pressable onPress={() => handleAccountDeletion()} style={[buttons.primary, { paddingVertical: 18, backgroundColor: colors.danger}]}>
              <Text style={{color: '#fff'}}>Delete my account</Text>
            </Pressable>
          </View>
          <Text style={{textAlign: 'center', color: colors.graySecondary, marginTop: 0}}>v{versionNumber}</Text>
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  text: {
    fontSize: 25,
    fontWeight: '500',
    color: '#fff'
  },
  profileImage: {
    height: 120,
    width: 120
  },
  section: {
    paddingHorizontal: 12,
    paddingVertical: 18,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayLightest
  },
  label: {
    fontSize: 14,
    color: colors.black,
    marginBottom: 8
  },
  image: {
    paddingBottom: 100
  }
});