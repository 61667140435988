import React, {useState, useEffect } from 'react'
import { View, ScrollView, Text } from 'react-native'
// utils
import { colors } from '../../utils/constants'
import { getAllReviewedIds } from '../../data/getters'

// components
import ReviewsList from '../reviews/reviewsList'
import CardItem from './cardItem'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default (props) => {
  const { itemsData, navigation, updateCards, isSearch } = props; 
  const [reviewsIds, setReviewsIds] = useState();

  const getReviewIds = async () => {
   const resultIds = await getAllReviewedIds(itemsData);
   setReviewsIds(resultIds);
  }

  useEffect(() => {
    getReviewIds();
  },[itemsData, updateCards]);

  let c = 0;

  return (
    <View style={{paddingBottom: 24}}>
      <ScrollView alwaysBounceVertical={false}>
        {itemsData && itemsData.map((location) => {
          if(reviewsIds?.includes(location.place_id)){
            c = c + 1;
            return <CardItem key={location.place_id} id={location?.place_id} name={location.name} address={location.vicinity} geometry={location.geometry} navigation={navigation} />
          }
        })}
        {c <= 5 && c > 0 && itemsData && 
        <>
          <Text style={{textAlign:'center', marginVertical: 12, color: '#fff', backgroundColor: colors.secondary, paddingVertical: 16}}>Nearby places that match, without reviews.</Text>
          <ReviewsList itemsData={itemsData} onLocationSelect={() => { }} condensed={true} reviewsids={reviewsIds} />
        </>
        }
        {c <= 0 && !!itemsData?.length &&
          <View>
            <Text style={{fontSize:18, textAlign: 'center', marginBottom: 8}}>There are no reviews that match your search.</Text>
            <Text style={{textAlign:'center', marginBottom: 12}}>Here are some nearby places without reviews.</Text>
            <ReviewsList itemsData={itemsData} onLocationSelect={() => {} } condensed={true} />
          </View>
        }
      </ScrollView>
    </View>
  );
}