import React, {useEffect, useState, useContext, useRef} from 'react'
import { StyleSheet, View, Pressable, Text, TextInput, Image, Dimensions, ScrollView, ActivityIndicator, Platform, SafeAreaView} from 'react-native'

import { AuthContext } from '../../lib/context/Auth'
// Utility
import { colors, defaultStyles, formStyles, buttons } from '../../utils/constants'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { randomString } from '../../utils/helpers'
// components
import Search from '../../components/global/search'
import Ionicons from '@expo/vector-icons/Ionicons'
import BackButton from '../../components/global/buttons/Back'
// data manipulation
import { addNewSticker } from '../../data/setters'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.
TextInput.defaultProps = Text.defaultProps || {};
TextInput.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default ({route, navigation}) => {
  const defaultSelection = route?.params?.id ? {...route.params} : {id: null, address: null, name:null, geometry: null, gender: null};
  const { currentUser } = useContext(AuthContext);
  const [updateProp, setUpdateProp] = useState(); 
  //const [locationResults, setLocationResults] = useState();
  const [locationSelected, setLocationSelected] = useState(defaultSelection);
  const [readyToSelect, setReadyToSelect] = useState(false);
  const [stickerId, setStickerId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [successfulPost, setSuccessfulPost] = useState(null);
  const insets = useSafeAreaInsets();
  const [restroomType, setRestroomType] = useState(null);
  const uiPadding = Platform.OS === 'ios' ? insets.top : 30;

  const handleReviewSubmit = async () => {
    setIsLoading(true);
    setSuccessfulPost(null);
    const payload = {
      address: locationSelected.address,
      gender: restroomType,
      locationId: locationSelected.id,
      name: locationSelected.name,
      stickerId: stickerId
    }
    
   const stickerResponse = await addNewSticker(payload);
   if(stickerResponse.status === 200) {
    setSuccessfulPost(true);
    setTimeout(() => {
      setIsLoading(false);
      navigation.navigate('stickerLocations');
    },800) 
   } else {
    setSuccessfulPost('failed');
    setIsLoading(false);
   }
  }
  useEffect(() => {
    const getStickerId = randomString(6);
    setStickerId(getStickerId);
  },[locationSelected]);
  return (
    <>
      {successfulPost == 'failed' && 
      <View style={{position: 'absolute', top:0, left: 0, zIndex: 20, width: '100%', paddingHorizontal: 20, paddingTop: uiPadding, paddingBottom: 16, backgroundColor: colors.grayLightest}}>
        <View style={{flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap'}}>
          <Text style={{fontSize: 14}}>Something went wrong. Your review did not post.</Text>
          <Pressable onPress={() => handleReviewSubmit()} style={[buttons.primary, {marginTop: 12}]}><Text style={{fontSize: 14, color:'#fff'}}>Retry</Text></Pressable>
        </View>
      </View>}
      {!!isLoading &&
      <View style={[{position: 'absolute', top: 0, height: '100%', flexDirection: 'row', justifyContent: 'center', alignItems:'center', width: '100%', zIndex: 10, backgroundColor: colors.grayLightest}]}>
        <View>
          <ActivityIndicator size="large" color={colors.primary} />
          <View style={{width: '100%', marginTop: 16}}>
          <Text style={{textAlign:'center'}}>Please wait while we add new location.</Text>
          </View>
        </View>
      </View> }
      <View
        style={[styles.container, {
          paddingTop: uiPadding,
          paddingBottom: 42,
          backgroundColor: colors.background,
        }]}
      >
          <Pressable onPress={() => navigation.goBack()} style={{flexDirection: "row", alignItems: "center", zIndex: 10}}>
            <BackButton />
          </Pressable>
          {!!locationSelected.id && !isLoading &&
          <>
            <View style={{backgroundColor: colors.background, paddingVertical: 16, paddingHorizontal: 8, marginBottom: 16, flexDirection: 'row', justifyContent: 'space-between'}}>
              <View style={{width: '80%'}}>
                <Text style={styles.title}>{locationSelected.name}</Text>
                <Text>{locationSelected.address}</Text>
              </View>
            </View>
            <View style={{paddingHorizontal: 8}}>
              <View style={{paddingHorizontal: 8, marginBottom: 16}}>
                <Text style={{marginBottom: 16, fontSize: 14}}>Restroom type:</Text>
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                  <Pressable style={[styles.genderTypeContainer]} onPress={() => setRestroomType('women')}>
                    <View style={[styles.genderTypeIcon, {backgroundColor: restroomType === 'women' ? '#000' : '#fff'}]}>
                      <Ionicons name="woman-outline" size={32} color={restroomType === 'women' ? '#fff' : colors.grayTertiary} />
                    </View>
                    <Text style={[styles.genderTypeLabel, {color: restroomType === 'women' ? '#000' : colors.grayTertiary}]}>Women</Text>
                  </Pressable>
                  <Pressable style={[styles.genderTypeContainer]} onPress={() => setRestroomType('men')}>
                    <View style={[styles.genderTypeIcon, {backgroundColor: restroomType === 'men' ? '#000' : '#fff'}]}>
                      <Ionicons name="man-outline" size={32} color={restroomType === 'men' ? '#fff' : colors.grayTertiary} />
                    </View>
                    <Text style={[styles.genderTypeLabel, {color: restroomType === 'men' ? '#000' : colors.grayTertiary}]}>Men</Text>
                  </Pressable>
                  <Pressable style={[styles.genderTypeContainer]} onPress={() => setRestroomType('unisex')}>
                    <View style={[styles.genderTypeIcon, {backgroundColor: restroomType === 'unisex' ? '#000' : '#fff'}]}>
                      <Ionicons name="people-outline" size={32} color={restroomType === 'unisex' ? '#fff' : colors.grayTertiary} />
                    </View>
                    <Text style={[styles.genderTypeLabel, {color: restroomType === 'unisex' ? '#000' : colors.grayTertiary}]}>Unisex</Text>
                  </Pressable>
                  <Pressable style={[styles.genderTypeContainer]} onPress={() => setRestroomType('family')}>
                    <View style={[styles.genderTypeIcon, {backgroundColor: restroomType === 'family' ? '#000' : '#fff'}]}>
                      <Ionicons name="basket-outline" size={32} color={restroomType === 'family' ? '#fff' : colors.grayTertiary} />
                    </View>
                    <Text style={[styles.genderTypeLabel, {color: restroomType === 'family' ? '#000' : colors.grayTertiary}]}>Family</Text>
                  </Pressable>
                </View>
              </View>
              <TextInput
                value={stickerId}
                onChangeText={(val) => setStickerId(val)}
                placeholder={'sticker Id'}
                style={[formStyles.textInput, {height: 60, marginBottom: 8}]}
                returnKeyType="done"
              />
              <Pressable style={[buttons.primary, {marginVertical: 24}]} onPress={() => handleReviewSubmit()}>
                <Text style={{color: '#fff', textTransform: 'capitalize'}}>Done</Text>
              </Pressable>
            </View>
          </>
          }

          {locationSelected.id == null &&
          <>
            <View style={{position: 'relative', zIndex: 5, marginTop: -24}}>
              <Search 
                resultsType="locations" 
                background={colors.background} 
                containerBackground='#fff'
                onLocationSelect={(item) => setLocationSelected(item) } 
                updateProp={updateProp}
              />
            </View>
          </>
          }
      </View>
    </>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    padding: 8,
  },
  controls: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 16,
    marginBottom: 12
  },
  button: {
    backgroundColor: colors.primary,
    borderRadius: defaultStyles.borderRadius,
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  title: {
    fontSize: 20,
    lineHeight: 22,
    marginBottom: 6,
    fontFamily: defaultStyles.interSemi,
  },
  text: {
    fontSize: 25,
    fontWeight: '500',
  },
  imageContainer: {
    height: 200,
    width: (Dimensions.get("window").width - 100),
    marginTop: 20
  },
  profileImage: {
    height: 40,
    width: 40
  },
  sectionTitle: {
    fontSize: 16,
    marginRight: 8,
    fontFamily: defaultStyles.interSemi
  },
  scoreSection: {
    marginBottom: 12,
    paddingHorizontal: 12,
    paddingVertical: 18,
    backgroundColor: '#fcfcfc',
    marginHorizontal: 6,
    borderRadius: defaultStyles.borderRadius,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.grayLightest
  },
  imageContainer: {
    width: (Dimensions.get("window").width - 15),
    height: (Dimensions.get("window").width - 15) / 1.5
  },
  genderTypeContainer: {
    width: '16%',
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 8,
    flexWrap: 'wrap'
  },
  genderTypeLabel: {
    fontSize: 12,
    color: colors.gray
  },
  genderTypeIcon: {
    textAlign: 'center',
    height: 60,
    width: '100%',
    borderRadius: defaultStyles.borderRadius,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 8
  }
});