import React, {useEffect, useState} from 'react';
import * as WebBrowser from 'expo-web-browser';
import * as Google from 'expo-auth-session/providers/google';
import { GoogleAuthProvider, signInWithCredential } from 'firebase/auth'
import { PottyMouthAuth } from '../../../lib/firebase'
import LoginButton from './index'
import Constants from 'expo-constants'
WebBrowser.maybeCompleteAuthSession();

export default (props) => {
  //googleIosAPiKey
  const [accessToken, setAccessToken] = useState();
  const [request, response, promptAsync] = Google.useIdTokenAuthRequest(
    {
      expoClientId: Constants.expoConfig.extra.googleWebApiKey,
      iosClientId: Constants.expoConfig.extra.googleIosAPiKey,
      clientId: Constants.expoConfig.extra.googleWebApiKey,
    },
  );

 useEffect(() => {
    if (response?.type === 'success') {
      const { id_token } = response.params;
      const credential = GoogleAuthProvider.credential(id_token);
      signInWithCredential(PottyMouthAuth, credential);
    }
  }, [response]);

  return (
    <LoginButton
      title={props.title}
      handleOnPress={() =>  promptAsync()}
      icon="logo-google"
      schema="google"
    />
  );
}