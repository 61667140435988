import React, { useState, useEffect } from 'react'
import { View, Text, ScrollView, Pressable, Platform } from 'react-native'

// utils
import { useSafeAreaInsets } from 'react-native-safe-area-context'

// UI
import { defaultStyles, buttons, colors } from '../../utils/constants'

// components

// data
import { getStickerLocations } from '../../data/getters'
import BackButton from '../../components/global/buttons/Back'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default ({ route, navigation }) => {
  const insets = useSafeAreaInsets();
  const uiPadding = Platform.OS === 'ios' ? insets.top : 30;
  const [stickerLocations, setStickerLocations] = useState();

  const requestAllStickerLocations = async () => {
    const res = await getStickerLocations();
    let entries = Object.entries(res);
    let sorted = entries.sort((a, b) => a[1] - b[1]);
    setStickerLocations(sorted);
  }

  useEffect(() => {
    requestAllStickerLocations();
  },[]);

  return (
    <View style={{flex: 1, backgroundColor: colors.background, paddingTop: uiPadding, paddingBottom: 16}}>
      <View style={{ paddingHorizontal: 8, zIndex: 5, width: '100%', marginBottom: 16 }}>
        <Pressable onPress={() => navigation.navigate('admin')} style={{flexDirection: "row", alignItems: "center"}}>
          <BackButton />
        </Pressable>
      </View>
      <ScrollView 
        style={{ flex: 1, position: 'relative', zIndex: 1}} 
        vertical={true}
        alwaysBounceVertical={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{backgroundColor: colors.background}}
      >
        {!!stickerLocations && stickerLocations.map((item) => {
          const location = item[1];
          return (
            <View key={location.stickerId}>
              <View style={{paddingHorizontal:8, marginBottom: 16, paddingBottom: 16, borderBottomColor: colors.grayTertiary, borderBottomWidth: 1}}>
                <Text style={{fontSize: 21, fontFamily: defaultStyles.interSemi, textTransform: 'uppercase'}}>{location.stickerId}</Text>
                <Text style={{fontSize: 16}}>{location.name}</Text>
                <Text style={{fontSize: 12}}>{location.address}</Text>
                <Pressable style={[buttons.primary, {marginTop: 8}]} onPress={() => navigation.navigate('locationDetail', {
                  id: location.locationId,
                  name: location.name,
                  address: location.address,
                  totalreviews: 'am'
                })}>
                  <Text style={{color:'#fff'}}>View Location</Text>
                </Pressable>
              </View>
            </View>
          )
        })}
        {!!stickerLocations && stickerLocations.length <= 0 && 
          <View>
            <Text style={{fontSize: 21, fontFamily: defaultStyles.interSemi, textAlign: 'center'}}>There are no active stickers.</Text>
          </View>
        }
      </ScrollView>
    </View>
  )
}