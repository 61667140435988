import React, {useEffect, useState} from 'react'
import { StyleSheet, View, Text, Image, Dimensions } from 'react-native'
import { circleImage, circleImageSmall, defaultStyles, colors, fallbackImage} from '../../utils/constants'
import { getUserRecord } from '../../data/getters';
import StarRating from 'react-native-star-rating-widget'
import Ionicons from '@expo/vector-icons/Ionicons'
import TimeAgo from '@andordavoti/react-native-timeago'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default (props) => {
  const {title, ratings, description, image, timestamp, user, gender } = props;
  const [reviewerInfo, setReviewerInfo] = useState();
  const [dateFormat, setDateFormat] = useState();

  const GenderIcon = () => {
    if(!!gender) {
      let icon;
      switch(gender) {
        case "women":
          icon = "woman-outline";
        break;
        case "men":
          icon = "man-outline";
        break;
        case "unisex":
          icon = "people-outline";
        break;
        case "family":
          icon = "basket-outline";
        break;
      }

      return (
        <View style={styles.restroomTypeWrapper}>
          <View style={styles.restroomTypeContainer}>
            <Ionicons name={icon} size={18} color={colors.gray} />
          </View>
          <Text style={{fontSize: 10, textAlign: 'center', color: colors.graySecondary}}>{gender}</Text>
        </View>
      )
    }
  }

  useEffect(() => {
    const date = (timestamp?.seconds * 1000);
    setDateFormat(date);

    const getReviewerInfo = async () => {
      const userInfo = await getUserRecord([{ uid: user?.id}]);
      setReviewerInfo(userInfo);
    }
    getReviewerInfo();
  },[]);
  return (
    <View style={ {flexDirection: 'row', justifyContent: 'center'}}>
      <View style={styles.card}>
        <GenderIcon />
        <View style={{paddingTop: 8, paddingBottom: 4, flexDirection:"row", alignItems: "center"}}>
          <View style={[circleImage,circleImageSmall, {marginRight: 4}]}>
            <Image source={{ uri: reviewerInfo?.photoURL || fallbackImage }} style={styles.profileImage} />
          </View> 
          <View>
            <Text style={{fontSize: 18, textTransform: 'lowercase', fontFamily: defaultStyles.interReg}}>
            {reviewerInfo?.handle || 'account removed'}
            </Text>
            <Text style={{fontSize: 10, color: colors.graySecondary, textTransform: 'capitalize'}}>Reviews: {reviewerInfo?.total} : Average Rating: {reviewerInfo?.average.toFixed(1)}</Text>
          </View>
        </View>
        <View style={{marginVertical: 12}}>
          <StarRating
            disabled={true}
            enableSwiping={false}
            maxStars={5}
            enableHalfStar={true}
            rating={ratings?.overall}
            starSize={26}
            emptyStarColor={colors.gray}
            starStyle={{width: 5, marginLeft: 0, marginRight: 24}}
            onChange={() => {}}
            color={colors.primary}
          />
          <Text style={{marginTop: 8, fontSize: 10, color: colors.graySecondary, textTransform: 'uppercase'}}>Cleanliness: {ratings?.cleaniness} | Privacy: {ratings?.privacy} | Stall: {ratings?.stall} | Toiletries: {ratings?.tpquality}</Text>
        </View>
        {!!title && 
        <View>
          <Text style={[styles.title, {marginTop: 8}]}>{title}</Text>
        </View>
        }
        {!!image && 
        <View style={{marginVertical: 8}}>
          <Image resizeMode={'cover'} source={{ uri: image }} style={styles.imageContainer} />
        </View> }
        {!!description && 
          <Text style={{fontSize: 16, lineHeight: 24, fontFamily: defaultStyles.interReg}}>{description}</Text>
        }
        {!!dateFormat && 
        <View style={[{marginTop: 12, flexDirection: 'row', justifyContent: 'space-between'}]}>
          <Text style={{textAlign: 'right', fontSize: 10, color: colors.graySecondary, paddingHorizontal: 4}}>
            <TimeAgo dateTo={new Date(dateFormat)} />
          </Text>
        </View> }
      </View>
    </View>
  );
}

const styles = StyleSheet.create({  
  imageContainer: {
    height: 200,
    width: '100%'
  },
  profileImage: {
    height: 40,
    width: 40
  },
  subhead: {
    fontFamily: defaultStyles.headingFont,
    fontSize: defaultStyles.headerSize_3,
    textTransform: "uppercase"
  },
  card: {
    marginVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayLightest,
    padding: 16,
    borderRadius: defaultStyles.borderRadius,
    width: (Dimensions.get("window").width - 20),
    backgroundColor: '#fff',
  },
  cardBody: {
    fontSize: 21,
    lineHeight: 26
  },
  title: {
    fontSize: 28,
    lineHeight: 34,
    fontFamily: defaultStyles.interReg,
  },
  container: {
    paddingHorizontal: 8
  },
  restroomTypeContainer: {
    textAlign: 'center',
    height: 40,
    width: 40,
    borderRadius: defaultStyles.borderRadius,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.grayLightest,
  },
  restroomTypeWrapper: {
    width: 40,
    position: 'absolute',
    top: 12,
    right: 12
  }
});