import React, { useState, useEffect } from 'react'
import { View, Text, ScrollView, Pressable, Platform } from 'react-native'

// utils
import { useSafeAreaInsets } from 'react-native-safe-area-context'

// UI
import { defaultStyles, buttons, colors, pageTitle } from '../../utils/constants'

// components

// data
import { getAllUsers } from '../../data/getters'
import BackButton from '../../components/global/buttons/Back'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default ({ route, navigation }) => {
  const insets = useSafeAreaInsets();
  const uiPadding = Platform.OS === 'ios' ? insets.top : 30;
  const [userData, setUserData] = useState();
  const [appleUsers, setAppleUsers] = useState();
  const [googleUsers, setGoogleUsers] = useState();
  const [passwordUsers, setPasswordusers] = useState();

  const segmentByPlatform = () => {
    let appleUserCount = [];
    let googleUserCount = [];
    let passwordUserCount = [];

    if(!!userData) {
      userData.map((user) => {
        if(user.providerId == 'apple.com') {
          appleUserCount = [...appleUserCount, user];
        }
        if(user.providerId == 'google.com') {
          googleUserCount = [...googleUserCount, user];
        }
        if(user.providerId == 'password') {
          passwordUserCount = [...passwordUserCount, user];
        }
      });
    }
    setAppleUsers(appleUserCount);
    setGoogleUsers(googleUserCount);
    setPasswordusers(passwordUserCount);
  }

  const requestAllUsers = async () => {
    const res = await getAllUsers();
    setUserData(res);
  }

  useEffect(() => {
    requestAllUsers();
  },[]);

  useEffect(() => {
    segmentByPlatform();
  },[userData])
  return (
    <View style={{flex: 1, backgroundColor: colors.background, paddingTop: uiPadding, paddingBottom: 16}}>
      <View style={{ paddingHorizontal: 8, zIndex: 5, width: '100%', marginBottom: 16 }}>
        <Pressable onPress={() => navigation.navigate('admin')} style={{flexDirection: "row", alignItems: "center"}}>
          <BackButton />
        </Pressable>
      </View>
      <ScrollView 
        style={{ flex: 1, position: 'relative', zIndex: 1}} 
        vertical={true}
        alwaysBounceVertical={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{backgroundColor: colors.background}}
      >
        <View style={{padding: 8}}>
          {!!userData && 
            <View>
              <Text style={[pageTitle, {marginBottom: 16}]}>User Stats</Text>
              <Text style={{fontSize: 16, marginBottom: 8}}>Total Users: {userData.length}</Text>
              <Text style={{fontSize: 16, marginBottom: 8}}>Apple Users: {Object.entries(appleUsers).length}</Text>
              <Text style={{fontSize: 16, marginBottom: 8}}>Google Users: {Object.entries(googleUsers).length}</Text>
              <Text style={{fontSize: 16, marginBottom: 8}}>Password Users: {Object.entries(passwordUsers).length}</Text>
            </View>
          }
        </View>
      </ScrollView>
    </View>
  )
}