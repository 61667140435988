import React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import {  HomeNavigator, WowNavigator, CreateNavigator, MyReviewsNavigator, AccountNavigator } from './MainNavigator'
import CustomNavItem from './CustomNavItem'

const BottomTabNavigator = () => {
  const Tab = createBottomTabNavigator();
  const screenOptionStyle = {
    headerShown: false,
  };
  return (
    <>
      <Tab.Navigator screenOptions={screenOptionStyle} tabBar={props => <CustomNavItem {...props} />}>
        <Tab.Screen name='HomeTab' component={HomeNavigator} options={{
          title: 'Home',
          icon: 'home-outline',
        }} />
        <Tab.Screen name='WowTab' component={WowNavigator} options={{
          title: 'OTW',
          icon: 'calendar-outline'
        }} />
        <Tab.Screen name='CreateTab' component={CreateNavigator} options={{
          title: '',
          primary: 'add-outline',
        }} />
        <Tab.Screen 
        name='MyReviewsTab' component={MyReviewsNavigator} options={{
          title: 'My Reviews',
          icon: 'chatbubbles-outline'
        }} />
        <Tab.Screen name='ProfileTab' component={AccountNavigator} options={{
          profile: true,
          title: 'Account'
        }} />
      </Tab.Navigator>
    </>
  );
};

export default BottomTabNavigator;