import React, { useContext, useState, useEffect } from 'react'
import { StyleSheet, View, ActivityIndicator, Text, Pressable, SafeAreaView, Dimensions, Platform, ScrollView } from 'react-native'
import { AuthContext } from '../../lib/context/Auth'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { defaultStyles, buttons, colors, } from '../../utils/constants'
import Constants from 'expo-constants'

// components
import Ionicons from '@expo/vector-icons/Ionicons'
import BackButton from '../../components/global/buttons/Back'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default ({route, navigation}) => {
  const insets = useSafeAreaInsets();
  const uiPadding = Platform.OS === 'ios' ? 0 : 30;


  return (
    <SafeAreaView style={{flex: 1, paddingTop: uiPadding, paddingBottom: (insets.bottom + 30), backgroundColor: colors.background}}>
      <View style={{marginBottom: 32, paddingHorizontal: 4}}>
        <Pressable onPress={() => navigation.goBack()} style={{flexDirection: "row", alignItems: "center"}}>
          <BackButton />
        </Pressable>
      </View>
      <ScrollView 
        contentContainerStyle={{paddingBottom: 20}} 
        style={{height: (Dimensions.get("window").height - 140), position:'relative'}}
        alwaysBounceVertical={false}
        vertical={true}
      >
        <View style={{flexDirection: 'row', justifyContent: 'center', marginBottom: 24 }}>
          <View style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <Ionicons name="people-circle-outline" size={64} color={colors.black} />
              <Text>Pottymouth Admin</Text>
          </View>
        </View>
        <View style={styles.container}>
          <View style={{marginVertical: 24, backgroundColor: 'white', borderRadius: defaultStyles.borderRadius, borderWidth: 1, borderColor: colors.grayLightest}}>
            <Pressable onPress={() => navigation.navigate('newStickerLocation')} style={styles.section}>
              <View style={styles.iconbox}>
                <Ionicons name="add-circle-outline" size={18} color="#000" />
              </View>
              <Text style={[styles.textbox, {fontSize: 16, fontFamily: defaultStyles.interSemi}]}>Add Sticker Location</Text>
              <View style={styles.iconbox}>
                <Ionicons name="chevron-forward-outline" size={24} color="#000" />
              </View>
            </Pressable>
            <Pressable onPress={() => navigation.navigate('stickerLocations')} style={styles.section}>
              <View style={styles.iconbox}>
                <Ionicons name="map-outline" size={18} color="#000" />
              </View>
              <Text style={[styles.textbox, {fontSize: 16, fontFamily: defaultStyles.interSemi}]}>View Sticker Locations</Text>
              <View style={styles.iconbox}>
                <Ionicons name="chevron-forward-outline" size={24} color="#000" />
              </View>
            </Pressable>
            <Pressable onPress={() => navigation.navigate('userStats')} style={styles.section}>
              <View style={styles.iconbox}>
                <Ionicons name="person-outline" size={18} color="#000" />
              </View>
              <Text style={[styles.textbox, {fontSize: 16, fontFamily: defaultStyles.interSemi}]}>User Stats</Text>
              <View style={styles.iconbox}>
                <Ionicons name="chevron-forward-outline" size={24} color="#000" />
              </View>
            </Pressable>
            <Pressable onPress={() => navigation.navigate('reviewStats')} style={styles.section}>
              <View style={styles.iconbox}>
                <Ionicons name="chatbubble-outline" size={18} color="#000" />
              </View>
              <Text style={[styles.textbox, {fontSize: 16, fontFamily: defaultStyles.interSemi}]}>Reviews Stats</Text>
              <View style={styles.iconbox}>
                <Ionicons name="chevron-forward-outline" size={24} color="#000" />
              </View>
            </Pressable>
          </View> 
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  text: {
    fontSize: 25,
    fontWeight: '500',
    color: '#fff'
  },
  profileImage: {
    height: 120,
    width: 120
  },
  section: {
    paddingHorizontal: 12,
    paddingVertical: 18,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: colors.grayLightest
  },
  image: {
    paddingBottom: 100
  },
  statbox: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderRadius: defaultStyles.borderRadius
  },
  iconbox: {
    width: '16.66%',
    paddingHorizontal: 8
  },
  textbox: {
    width: '75%',
    paddingLeft: 16
  },
  stat: {
    fontSize: 28,
    color: '#000',
    textAlign: 'center',
    marginBottom: 4,
  },
  statLabel: {
    fontSize: 14,
    color: colors.gray,
    textAlign: 'center'
  }
});