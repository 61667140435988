import React, {useEffect, useState, useContext, useCallback, useRef } from 'react'
import { StyleSheet, View, Text, ScrollView, ActivityIndicator, RefreshControl, Pressable, Image, Platform, StatusBar} from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { colors, defaultStyles } from '../utils/constants'
import { AuthContext } from '../lib/context/Auth'
import Ionicons from '@expo/vector-icons/Ionicons'

// components
import Search from '../components/global/search'
import localImage from '../components/images'
import { useScrollToTop } from '@react-navigation/native'
import { getLocalObjectValue, removeLocalStorageItem } from '../lib/localStorage'
import * as Animatable from 'react-native-animatable'

export default ({route, navigation}) => {
  const insets = useSafeAreaInsets();
  const [updateProp, setUpdateProp] = useState();  
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const uiPadding = Platform.OS === 'ios' ? insets.top : 30;
  const [refreshing, setRefreshing] = useState(false);
  const [hasItemInQueue, setHasItemInQueue] = useState(false);
  const [savedReview, setSavedReview] = useState();
  const [isStatic, setIsStatic] = useState(true);
  const ref = useRef(null);

  useScrollToTop(ref);

  const triggerRefresh = () => {
    const randomNum = Math.floor(Math.random() * 500);
    setUpdateProp(randomNum);
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setTimeout(() => {
        triggerRefresh();
      },500)
    });
    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    function initSearch() {
      setTimeout(() => {
        triggerRefresh();
      },500)
    }
    if(currentUser?.providerData[0]?.firstTime) {
      navigation.navigate('legal');
    } else {
      initSearch()
    }
  },[currentUser]);

  useEffect(() => {
    setIsLoading(true);
    const checkReviewQueue = async () => {
      const reviewQueue = await getLocalObjectValue('postqueue');
      if(!!reviewQueue && Object.entries(reviewQueue).length) {
        setHasItemInQueue(true);
        setSavedReview(reviewQueue);
      }
    }
    setTimeout(() => {
      if(currentUser?.providerData[0].firstTime) {
        navigation.navigate('legal');
      } else {
        checkReviewQueue();
      }
      setIsLoading(false);
    },500);
  },[]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    triggerRefresh();
  }, []);

  return (
    <View style={[styles.container, {paddingTop: uiPadding, position: 'relative'}]}>
     {!!hasItemInQueue && 
      <View style={{
        position: 'relative',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 16,
        backgroundColor: colors.black
      }}>
        <Text style={{color: colors.white, fontSize: 12}}>There is an unsubmitted review in your queue.</Text>
        <View style={{flexDirection:'row', alignItems: 'center'}}>
          <Pressable onPress={() => navigation.navigate('create', { id: savedReview?.id, address: savedReview?.address, name: savedReview?.name, failedRequest: savedReview })}>
            <Text style={{color: colors.white, fontWeight: 'bold', fontSize: 13,}}>View</Text>
          </Pressable>
          <Text style={{color: colors.white, fontWeight: 'bold', fontSize: 13, marginHorizontal: 6}}>|</Text>
          <Pressable onPress={() => { removeLocalStorageItem('@postqueue'); setHasItemInQueue(false); }}>
            <Text style={{color: colors.white, fontWeight: 'bold', fontSize: 13,}}>Delete</Text>
          </Pressable>
        </View>
      </View>
      }
      {!isStatic &&
        <Animatable.View 
          animation="slideInDown" 
          iterationCount={1} 
          useNativeDriver={true}
          easing="ease-in-out-back"
          duration={1000}
          style={{
            position: 'absolute',
            zIndex: 44,
            width: '100%',
          }}
        >
          <View style={{
            backgroundColor: colors.background,
            borderBottomColor: colors.grayLightest,
            borderBottomWidth: 1,
            paddingTop: uiPadding,
            flexDirection: 'row',
            justifyContent: 'center'
          }}>
            <Image resizeMode={'contain'} defaultSource={localImage.icon_dark} style={styles.icon} />
          </View>
        </Animatable.View>
      }
      {!!currentUser && currentUser.uid === "CsqfOGHvkfgsB3So6hA2nDVOHn42" &&
      <View style={{position:'absolute', top: 58, right: 24, zIndex: 20}}>
        <Pressable onPress={() => navigation.navigate('admin')}><Ionicons name="people-circle-outline" size={30} color="#000" /></Pressable>
      </View>
      }
      {!isLoading && 
      <ScrollView
        ref={ref}
        scrollsToTop={true}
        scrollToOverflowEnabled={true}
        contentInsetAdjustmentBehavior="automatic"
        keyboardShouldPersistTaps="handled"
        vertical={true}
        alwaysBounceVertical={false}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={30}
        onScroll={(event) => {
          const offset = event.nativeEvent.contentOffset.y;

          if(offset >= 40) {
            setIsStatic(false);
          } else {
            setIsStatic(true);
          }
        }}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }>
        <View style={{paddingHorizontal: 16}}>
          <Image resizeMode={'contain'} defaultSource={localImage.logo} style={styles.logo} />
        </View>
        <View style={{paddingHorizontal: 16}}>
          <Text allowFontScaling={false} style={{fontFamily: defaultStyles.interSemi, fontSize: 32, marginBottom: 6, marginTop: 16, textTransform: 'capitalize'}}>Let's get you to a restroom!</Text>
          <Text style={{fontSize: 16, lineHeight: 22, color: colors.grayDarkest, fontFamily: defaultStyles.interReg}}>Reviews will help you find a suitable restroom for your needs.</Text>
        </View>
        <View style={{position: 'relative', zIndex: 5}}>
          <Search 
            resultsType="reviews" 
            onSearchUpdate={(res) => setLocationResults(res)} 
            onLocationSelect={(item) => setLocationSelected(item)} 
            navigation={navigation}
            updateProp={updateProp}
            onUpdateComplete={() => setRefreshing(false)}
          />
        </View>
      </ScrollView> }
      {isLoading && 
      <View style={[{
        position: 'absolute',
        top: 300,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems:'center',
        width: '100%',
        zIndex: 10,
        flexWrap: 'wrap'
      }]}>
        <ActivityIndicator size="large" color={colors.primary} />
      </View> 
      }
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background
  },
  text: {
    fontSize: 25,
    fontWeight: '500',
  },
  profileImage: {
    height: 120,
    width: 120
  },
  logo: {
    width: 180,
    height: 35,
    marginBottom: 8
  },
  icon: {
    width: 60,
    height: 35,
    marginBottom: 8
  },
});