import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Text, Image, Dimensions, Pressable, Platform, ScrollView } from 'react-native'
// utils
import { colors, defaultStyles } from '../utils/constants'
import { getLocalItem } from '../lib/localStorage'

// ref
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import localImage from '../components/images'

// components
import GoogleAuthButton from '../components/global/buttons/Google'
import EmailAuthButton from '../components/global/buttons/Email'
import AppleAuthButton from '../components/global/buttons/Apple'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default ({route, navigation}) => {
  const insets = useSafeAreaInsets();
  const uiPadding = Platform.OS === 'ios' ? insets.top : 40;
  const [deviceId, setDeviceId] = useState();
  const windowHeight =  Dimensions.get("window").height;
  const getDeviceId = async () => {
    const did = await getLocalItem("deviceId");
    setDeviceId(did);
  }
  useEffect(() => {
    getDeviceId();
  //   signOut(PottyMouthAuth).then(() => {
  //     // Sign-out successful.
  //     console.log("signed out")
  //     }).catch((error) => {
  //       // An error happened.
  //     });
  console.log(route);
  },[]);
  return (
    <ScrollView style={[styles.container,{paddingTop: uiPadding }]}>
      <View style={{flexDirection: 'row', justifyContent: 'center'}}>
        <Image resizeMode={'contain'} defaultSource={localImage.logo} style={styles.logo} />
      </View>
      <View style={{paddingHorizontal: 32, marginVertical: 16, flexDirection: 'row', justifyContent: 'center'}}>
        <Image resizeMode={'contain'} defaultSource={localImage.artwork} style={styles.imageContainer} />
      </View>
      <Text style={{fontFamily: defaultStyles.interSemi, fontSize: windowHeight > 670 ? 28 : 24, paddingHorizontal: 20, textAlign: 'center'}}>Reviews for public restrooms in your area.</Text>
      <Text style={{
        fontFamily: defaultStyles.interReg, 
        fontSize: 14, 
        lineHeight: 21, 
        paddingHorizontal: 10, 
        textAlign: 'center', 
        color: colors.gray, 
        marginTop: windowHeight > 670 ? 16 : 8
      }}>Check out reviews written by individuals who like you, used public restrooms, and rated their quality.</Text>
      <View style={{paddingHorizontal: 24, marginTop: windowHeight > 670 ? 16 : 8}}>
        <GoogleAuthButton title="Sign in with Google" />
        {Platform.OS === 'ios' && <AppleAuthButton title="Sign in with Apple" deviceId={deviceId} /> }
        <EmailAuthButton title="Sign in with Email" handleOnPress={() => navigation.navigate('login')} />
        <Text style={{
          marginVertical: 4,
          textAlign: 'center',
          fontFamily: defaultStyles.interReg,
          fontSize: 16,
          color: colors.graySecondary
        }}>- Or -</Text>
        <Pressable onPress={() => navigation.navigate('emailSignup')}>
          <View>
            <Text style={{textAlign: 'center', fontSize: 16, color: colors.secondaryDark}}>Sign up with Email</Text>
          </View>
        </Pressable>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff'
  },
  text: {
    fontSize: 25,
    fontWeight: '500',
  },
  profileImage: {
    height: 120,
    width: 120
  },
  imageContainer: {
    width:  Dimensions.get("window").height > 670 ? Dimensions.get("window").width - 80 : Dimensions.get("window").width / 1.4,
    height: Dimensions.get("window").height > 670 ? Dimensions.get("window").width / 1.4 : Dimensions.get("window").width / 2
  },
  logo: {
    width: 180,
    height: 35,
    marginBottom: 8
  },
  button: {
    flexDirection: 'row', 
    alignItems: 'center',
    backgroundColor: colors.secondary, 
    borderRadius: defaultStyles.borderRadius, 
    borderWidth: 1, 
    borderColor: colors.secondaryDark,
    marginVertical: 12,
    paddingHorizontal: 24,
  },
  buttonIcon: {
    flexDirection: 'row',
    justifyContent:'center',
    backgroundColor:'#ff0',
    height: 35,
    width: 35,
    alignItems: 'center',
    marginVertical: 8,
    borderRadius: 35,
    backgroundColor: colors.secondaryDark
  }
});