import React, {useEffect, useState} from 'react'
import { StyleSheet, View, ActivityIndicator, Text, TextInput, Pressable, Platform, Image } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { getLocationByStickerId } from '../data/getters'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { colors, defaultStyles, pageTitle, formStyles, buttons } from '../utils/constants'
import localImage from '../components/images'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.
TextInput.defaultProps = Text.defaultProps || {};
TextInput.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale

export default ({ navigation, route }) => {
  const insets = useSafeAreaInsets();
  const [isLoading, setIsLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [stickerId, setStickerId] = useState();
  const [navigationComplete, setNavigationComplete] = useState(false);
  const uiPadding = Platform.OS === 'ios' ? insets.top : 30;

  const requestStickerId = async () => {
    setIsLoading(true);
    if(!!stickerId) {
      const stickerResults = await getLocationByStickerId(stickerId);
      if(!stickerResults) {
        setNotFound(true);
        setIsLoading(false);
        return;
      }
      setIsLoading(false);
      setNavigationComplete(true);
      navigation.navigate('create', { id: stickerResults.locationId, address: stickerResults.address, name: stickerResults.name, gender: stickerResults.gender })
    } else {
      setIsLoading(false);
      setNotFound(true);
    }
  }
  

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
     console.log("back in focus", navigationComplete);
      if(!!navigationComplete) {
        navigation.navigate('homefeed');
      }
    });
    return unsubscribe;
  }, [navigation, route, navigationComplete]);

  return (
    <View style={{flex: 1, backgroundColor: colors.background, paddingTop: uiPadding, paddingHorizontal: 16}}>
      {!!isLoading && 
        <View style={[{position: 'absolute', top: 300, flexDirection: 'row', justifyContent: 'center', width: '100%', zIndex: 15}]}>
          <ActivityIndicator size="large" color={colors.primary} />
        </View> 
      }
      <View style={{}}>
        <Image resizeMode={'contain'} defaultSource={localImage.logo} style={styles.logo} />
      </View>
      <Text style={[pageTitle, { textTransform: 'uppercase',  marginTop: 24 }]}>Ready to post a review?</Text>
      <Text style={{marginTop: 12}}>Somewhere on that sticker there should be an ID, something like 12M. Please enter that ID below.</Text>
      <KeyboardAwareScrollView 
        keyboardOpeningTime={0}
        extraScrollHeight={30}
        enableResetScrollToCoords
        contentInset={{ bottom: 300 }}
      >
        <View style={{width: '100%', paddingTop: 8}}>
          <View style={styles.formItem}>
            <Text style={styles.fieldLabel}>Sticker ID</Text>
            <View style={{position: 'relative'}}>
              <TextInput
                value={stickerId}
                onChangeText={(val) => setStickerId(val.toLowerCase())}
                placeholder={'12M'}
                style={formStyles.textInput}
                clearButtonMode="unless-editing"
                autoCorrect={false}
              />
            </View>
            { !!notFound && <Text style={{color: colors.danger, marginTop: 8}}>That ID was not found. Please try again.</Text> }
          </View>
          <Pressable onPress={() => requestStickerId() } style={[buttons.primary, styles.submitButton]}>
            <Text style={{color: colors.grayTertiary}}>Look up ID</Text>
          </Pressable>
          <Pressable style={{marginTop: 16}} onPress={() => navigation.navigate('create', { id: null, address: null, name: null })}><Text>Can't find the ID?</Text></Pressable>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { 
   flex: 1,
   paddingHorizontal: 16,
   paddingBottom: 60,
  },
  logo: {
    width: 180,
    height: 35,
    marginBottom: 8
  },
  errorText: {
    fontSize: 10,
    color: 'red',
  },
  title: {
    fontFamily: defaultStyles.interSemi,
    fontSize: 36,
    marginTop: 32,
    paddingRight: 32,
    textTransform: 'capitalize'
  },
  formItem: {
    marginTop: 24
  },
  fieldLabel: {
    fontSize: 14,
    marginBottom: 12,
    color: colors.black,
    textTransform: 'uppercase'
  },
  submitButton: {
    marginTop: 18,
    paddingVertical: 18,
    width: '100%'
  },
  disabledButton: {
    backgroundColor: colors.grayLightest,
  }
});