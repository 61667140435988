import React from 'react';
import LoginButton from './index';

async function onEmailButtonPress() {
 
}

export default (props) => {
  return (
    <LoginButton
      title={props.title}
      handleOnPress={() => props.handleOnPress()}
      icon="mail"
      schema="email"
    />
  );
}