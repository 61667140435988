import React, { useState, useEffect } from 'react'
import { View, Text, ScrollView, Pressable, Platform } from 'react-native'

// utils
import { useSafeAreaInsets } from 'react-native-safe-area-context'

// UI
import { colors, pageTitle } from '../../utils/constants'

// components

// data
import { getAllReviewLocations } from '../../data/getters'
import BackButton from '../../components/global/buttons/Back'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default ({ route, navigation }) => {
  const insets = useSafeAreaInsets();
  const uiPadding = Platform.OS === 'ios' ? insets.top : 30;
  const [reviewsData, setReviewsData] = useState();
  const [totalReviews, setTotalReviews] = useState(0);

  const requestAllReviewLocations = async () => {
    const res = await getAllReviewLocations();
    setReviewsData(res);
  }
  const calculateTotalReviews = () => {
    let total = 0;
    if(!!reviewsData){
      reviewsData.map((location) => {
        total = total + Object.entries(location.reviews).length;
      });
    }
    setTotalReviews(total);
  }
  useEffect(() => {
    requestAllReviewLocations();
  },[]);

  useEffect(() => {
    calculateTotalReviews();
  },[reviewsData]);

  return (
    <View style={{flex: 1, backgroundColor: colors.background, paddingTop: uiPadding, paddingBottom: 16}}>
      <View style={{ paddingHorizontal: 8, zIndex: 5, width: '100%', marginBottom: 16 }}>
        <Pressable onPress={() => navigation.navigate('admin')} style={{flexDirection: "row", alignItems: "center"}}>
          <BackButton />
        </Pressable>
      </View>
      <ScrollView 
        style={{ flex: 1, position: 'relative', zIndex: 1}} 
        vertical={true}
        alwaysBounceVertical={false}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{backgroundColor: colors.background}}
      >
        <View style={{padding: 8}}>
          {!!reviewsData && 
            <View>
              <Text style={[pageTitle, {marginBottom: 16}]}>Reviews Stats</Text>
              <Text style={{fontSize: 16, marginBottom: 8}}>Total Locations: {reviewsData.length}</Text>
              <Text style={{fontSize: 16, marginBottom: 8}}>Total Reviews: {totalReviews}</Text>
            </View>
          }
        </View>
      </ScrollView>
    </View>
  )
}