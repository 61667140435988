import { PottyMouthAuth } from '../../../lib/firebase'
import * as AppleAuthentication from 'expo-apple-authentication'
import { signInAnonymously, updateProfile } from 'firebase/auth'

export default (props) => {
  return (
    <AppleAuthentication.AppleAuthenticationButton
      buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
      buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
      cornerRadius={10}
      style={{ height: 50, width: '100%' }}
      onPress={async () => {
        const appleCredential = await AppleAuthentication.signInAsync({
          requestedScopes: [
            AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
            AppleAuthentication.AppleAuthenticationScope.EMAIL
          ],
        });
        const { identityToken } = appleCredential;

        if (identityToken) {
          signInAnonymously(PottyMouthAuth)
            .then((res) => {
              updateProfile(PottyMouthAuth.currentUser, {
                uid: props.deviceId
              }).then(() => {
                // Profile updated!
                // ...
              }).catch((error) => {
                // An error occurred
                // ...
              });
            })
            .catch((error) => {
              console.log(error)
              // ...
            });
        }
      }}
    />
  )
}