import React, { useContext, useState } from 'react'
import { StyleSheet, View, ActivityIndicator, Text, Pressable, SafeAreaView, Dimensions, Image, TextInput, Platform } from 'react-native'
import { AuthContext } from '../lib/context/Auth';
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { defaultStyles, buttons, colors, formStyles, fallbackImage, circleImage, circleImageXSmall } from '../utils/constants'

// components
import Ionicons from '@expo/vector-icons/Ionicons'
import { addFeedback } from '../data/setters'
import BackButton from '../components/global/buttons/Back'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.
TextInput.defaultProps = Text.defaultProps || {};
TextInput.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default ({route, navigation}) => {
  const { currentUser } = useContext(AuthContext);
  const [feedbackText, setFeedbackText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackComplete, setFeedbackComplete] = useState(false);
  const insets = useSafeAreaInsets();
  const uiPadding = Platform.OS === 'ios' ? 0 : 30;

  const handleSubmit = async () => {
    setIsLoading(true);
    const user = currentUser.providerData[0];
    const payload = {
      uid: user.uid,
      handle: user.handle,
      displayName: user.displayName,
      message: feedbackText
    }
    await addFeedback(payload);
    setIsLoading(false);
    setFeedbackComplete(true);
  }
  return (
    <SafeAreaView style={{flex: 1, paddingBottom: (insets.bottom + 30), paddingTop: uiPadding, backgroundColor: colors.background }}>
      {!!isLoading && <View style={[{position: 'absolute', top: 300, flexDirection: 'row', justifyContent: 'center', width: '100%'}]}><ActivityIndicator size="large" color={colors.primary} /></View> }
      <View style={{paddingHorizontal: 8, marginBottom: 16}}>
        <Pressable onPress={() => navigation.goBack()} style={{flexDirection: "row", alignItems: "center"}}>
          <BackButton />
        </Pressable>
      </View>
      <View style={{height: (Dimensions.get("window").height - 140), position:'relative'}}>
        {!!feedbackComplete &&
          <View style={styles.container}>
            <Text style={styles.title}>We appreciate your feedback!</Text>
            <Text style={{marginVertical: 16}}>Thank you for taking the time to write us and share your thoughts.</Text>
            <Pressable 
              onPress={() => {setFeedbackText(''); setFeedbackComplete(false); }} 
              style={[buttons.primary, {
                width: '100%',
                marginTop: 16,
                paddingVertical: 16
              }]}>
              <Text style={{color: '#fff'}}>Provide additional feedback</Text>
            </Pressable>
          </View>
        }
        {!feedbackComplete &&
        <View style={styles.container}>
          <Text style={styles.title}>We want to hear from you!</Text>
          <Text>Share your thoughts, comments, questions, etc.</Text>
          <TextInput
            value={feedbackText}
            onChangeText={(val) => setFeedbackText(val)}
            placeholder={'Be honest, we can take it.'}
            multiline
            numberOfLines={6}
            style={[formStyles.textInput, {height: 250, marginTop: 32}]}
          />
          <View style={{marginTop: 16, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
            <View style={[circleImage, circleImageXSmall, {marginRight: 8}]}>
              <Image 
              source={{ 
                uri: currentUser?.providerData[0]?.photoURL || fallbackImage,
                cache: 'force-cache'
                }}
              resizeMode="cover"
              style={styles.profileImage}
              />
            </View> 
            <Text style={{fontSize: 14, color: colors.gray}}>{currentUser?.providerData[0]?.handle}</Text>
          </View>
          <Pressable 
          onPress={() => handleSubmit()} 
          style={[buttons.primary, {
            width: '100%',
            marginTop: 16,
            paddingVertical: 16
          }]}>
            <Text style={{color: '#fff'}}>Submit</Text>
          </Pressable>
        </View>
        }
      </View>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  text: {
    fontSize: 25,
    fontWeight: '500',
    color: '#fff'
  },
  profileImage: {
    height: 25,
    width: 25
  },
  section: {
    paddingHorizontal: 12,
    paddingVertical: 18,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: colors.grayLightest
  },
  title: {
    fontFamily: defaultStyles.interSemi,
    fontSize: 36,
    marginTop: 32,
    paddingRight: 32,
    textTransform: 'capitalize'
  },
});