import AsyncStorage from '@react-native-async-storage/async-storage';

const setLocalItem = async (key, value) => {
  try {
    await AsyncStorage.setItem(`${key}`, value)
  } catch (e) {
    // saving error
    console.log(e);
  }
}

const getLocalItem = async (key) => {
  try {
    const value = await AsyncStorage.getItem(`@${key}`)
    return value;
  } catch(e) {
    console.log(e);
    // error reading value
  }
}

const setLocalObjectValue = async (key, value) => {
  try {
    const jsonValue = JSON.stringify(value)
    await AsyncStorage.setItem(`@${key}`, jsonValue)
  } catch(e) {
    // save error
  }

  //console.log(`local storage set for ${key}.`, value);
}
const getLocalObjectValue = async (key) => {
  try {
    const jsonValue = await AsyncStorage.getItem(`@${key}`)
    return jsonValue != null ? JSON.parse(jsonValue) : null
  } catch(e) {
    // read error
  }

  console.log(`get local object ${key}`)
}

const removeLocalStorageItem = async (key) => {
  try {
      await AsyncStorage.removeItem(key);
      return true;
  }
  catch(exception) {
      return false;
  }
}
export { setLocalItem, getLocalItem, setLocalObjectValue, getLocalObjectValue, removeLocalStorageItem };