import React, { useState, useEffect, useContext } from 'react'
import { StyleSheet, View, Text, ScrollView, Pressable, Dimensions, Image, StatusBar, Linking, Platform } from 'react-native'

// utils
import { useSafeAreaInsets } from 'react-native-safe-area-context'

// UI
import { defaultStyles, buttons, colors } from '../utils/constants'

// components
import StarRating from 'react-native-star-rating-widget'
import localImage from '../components/images'
import { getDistance, convertDistance } from 'geolib'

// data
import { getRatingScoresById } from '../data/getters'
import Tooltip from '../components/global/tooltip'
import { GeolocationContext } from '../lib/context/Geolocation'
import BackButton from '../components/global/buttons/Back'

Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.maxFontSizeMultiplier = 1; // the maximum amount the font size will scale.

export default ({ route, navigation }) => {
  const insets = useSafeAreaInsets();
  const { id, name, address, geometry } = route.params;
  const gradingStarSize = 18;
  const gradingStarMargin = 16;
  const [ratings, setRatings] = useState();
  const [totalreviews, setTotalreviews] = useState();
  const uiPadding = Platform.OS === 'ios' ? insets.top : 30;
  const [LocationContext, setLocationContext] = useContext(GeolocationContext);
  let distance;
  if(!!geometry) {
    distance = getDistance(
      { latitude: LocationContext.coords.latitude, longitude: LocationContext.coords.longitude },
      { latitude: geometry.location.lat, longitude: geometry.location.lng }
    );
  }

  const EstablishmentRatings = async () => {
    const averageScores = await getRatingScoresById(id);
    setRatings(averageScores.ratings);
    setTotalreviews(averageScores.total);
  }

  useEffect(() => {
    EstablishmentRatings();
  }, [route, navigation]);
  return (
    <View style={{flex: 1, backgroundColor: colors.background}}>
      <Image defaultSource={localImage.detail_background} style={[styles.image]} />
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 8, position: 'absolute', zIndex: 5, width: '100%', top: uiPadding }}>
        <Pressable onPress={() => navigation.goBack()} style={{flexDirection: "row", alignItems: "center"}}>
          <BackButton />
        </Pressable>
      </View>
      <ScrollView 
        style={{ flex: 1, position: 'relative', zIndex: 1}} 
        vertical={true}
        alwaysBounceVertical={false}
        showsVerticalScrollIndicator={false}
      >
        <View style={[styles.container, { paddingHorizontal: 12, marginTop: 180 }]}>
          <View style={{ flexDirection: 'row', justifyContent: 'center', marginTop: 8, marginBottom: 24 }}>
            <Pressable
              onPress={() => navigation.navigate('reviews', { id: id, name: name })}
              style={buttons.primary}>
              <Text style={{ color: '#fff', textAlign: 'center' }}>{totalreviews} Review{totalreviews > 1 || totalreviews == 0 ? 's' : ''}</Text>
            </Pressable>
            <Pressable onPress={() => Linking.openURL(`maps://?address=${encodeURIComponent(address)}&dirflg=r`)} style={[buttons.primary, { backgroundColor: colors.grayLightest }]}>
              <Text style={{ color: colors.black, textAlign: 'center' }}>Get Directions</Text>
            </Pressable>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', width: '100%', backgroundColor: '#000', paddingVertical: 12, borderRadius: defaultStyles.borderRadius, marginBottom: 16  }}>
            <View style={{width: '75%', paddingHorizontal: 12}}>
              <Text style={styles.title} numberOfLines={1}>{name}</Text>
              <Text style={{ fontSize: 12, marginTop: 4, color: '#fff' }}>{address}</Text>
              {geometry && <Text style={{ fontSize: 10, color: colors.grayTertiary, marginTop: 4}}>{convertDistance(distance, 'mi').toFixed(1)} mi</Text> }
            </View>
            <View style={{ width: '25%', paddingHorizontal: 12 }}>
              <Text style={{ color: '#fff', fontSize: 28, textAlign: 'center' }}>{totalreviews > 0 ? (Math.round((ratings?.overall / 5) * 100)) : 'N/A'}</Text>
              <Text style={{ textAlign: 'center', fontFamily: defaultStyles.interSemi, color: '#fff', fontSize: 10 }}>Overall Score</Text>
            </View>
          </View>
        </View>
        <View style={{ backgroundColor: '#fff', width: '100%', position: 'relative', zIndex: 2, padding: 16, borderRadius: defaultStyles.borderRadius }}>
          {totalreviews > 0 &&
            <>
              <Text style={styles.subtitle}>Score Breakdown <Text style={{ fontSize: 12, fontFamily: defaultStyles.interReg }}>(average)</Text></Text>
              <View style={styles.scoreSection}>
                <Text style={styles.sectionTitle}>Cleanliness:</Text>
                <StarRating
                  disabled={true}
                  enableSwiping={false}
                  maxStars={5}
                  enableHalfStar={true}
                  rating={ratings?.cleaniness}
                  starSize={gradingStarSize}
                  emptyStarColor={colors.gray}
                  starStyle={{ width: 5, marginLeft: 0, marginRight: gradingStarMargin }}
                  onChange={() => { }}
                  color={colors.primary}
                />
              </View>

              <View style={styles.scoreSection}>
                <Text style={styles.sectionTitle}>Toiletries:<Tooltip bodyText="Toliet paper, soap, seat covers, etc." /></Text>
                <StarRating
                  disabled={true}
                  enableSwiping={false}
                  maxStars={5}
                  enableHalfStar={true}
                  rating={ratings?.tpquality}
                  starSize={gradingStarSize}
                  emptyStarColor={colors.gray}
                  starStyle={{ width: 5, marginLeft: 0, marginRight: gradingStarMargin }}
                  onChange={() => { }}
                  color={colors.primary}
                />
              </View>

              <View style={styles.scoreSection}>
                <Text style={styles.sectionTitle}>Privacy:</Text>
                <StarRating
                  disabled={true}
                  enableSwiping={false}
                  maxStars={5}
                  enableHalfStar={true}
                  rating={ratings?.privacy}
                  starSize={gradingStarSize}
                  emptyStarColor={colors.gray}
                  starStyle={{ width: 5, marginLeft: 0, marginRight: gradingStarMargin }}
                  onChange={() => { }}
                  color={colors.primary}
                />
              </View>

              <View style={styles.scoreSection}>
                <Text style={styles.sectionTitle}>Stalls / Urinals:</Text>
                <StarRating
                  disabled={true}
                  enableSwiping={false}
                  maxStars={5}
                  enableHalfStar={true}
                  rating={ratings?.stall}
                  starSize={gradingStarSize}
                  emptyStarColor={colors.gray}
                  starStyle={{ width: 5, marginLeft: 0, marginRight: gradingStarMargin }}
                  onChange={() => { }}
                  color={colors.primary}
                />
              </View>
              <View>
                <Pressable style={[
                  buttons.primary, {
                    paddingVertical: 18,
                    marginBottom: 12
                  }
                ]} onPress={() => navigation.navigate('reviews', { id: id, name: name })}><Text style={{ color: "#fff" }}>View All Reviews</Text></Pressable>
              </View>
            </>
          }
          {totalreviews == 0 &&
            <View style={{height: (Dimensions.get("window").height / 2.25), paddingTop: '20%'}}>
              <View style={{ marginTop: -10, marginBottom: 12, padding: 16, borderRadius: defaultStyles.borderRadius }}>
                <View style={{ paddingTop: 32 }}><Text style={{ textAlign: 'center' }}>There are no reviews available.</Text></View>
              </View>
              <View style={styles.container}>
                <Pressable style={[
                  buttons.primary, {
                    paddingVertical: 18,
                    marginBottom: 24,
                    marginRight: 0
                  }
                ]} onPress={() => navigation.navigate('create', { id: id, address: address, name: name })}><Text style={{ color: "#fff" }}>Add a review</Text></Pressable>
              </View>
            </View>
          }
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  text: {
    fontSize: 25,
    fontWeight: '500',
    color: '#fff'
  },
  title: {
    fontSize: 24,
    lineHeight: 24,
    fontFamily: defaultStyles.interReg,
    color: '#fff',
  },
  subtitle: {
    fontSize: 18,
    lineHeight: 28,
    fontFamily: defaultStyles.headingFont,
    marginBottom: 12
  },
  profileImage: {
    height: 40,
    width: 40
  },
  sectionTitle: {
    fontSize: 16,
    marginRight: 8,
    fontFamily: defaultStyles.interReg
  },
  scoreSection: {
    marginBottom: 12,
    paddingHorizontal: 12,
    paddingVertical: 18,
    backgroundColor: '#fcfcfc',
    marginHorizontal: 6,
    borderRadius: defaultStyles.borderRadius,
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.grayLightest
  },
  card: {
    marginVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: colors.grayLightest,
    padding: 16,
    borderRadius: defaultStyles.borderRadius,
    width: (Dimensions.get("window").width - 20),
    backgroundColor: '#fff',
  },
  image: {
    paddingBottom: 100,
    height: (Dimensions.get("window").height / 2),
    width: Dimensions.get("window").width,
    position: 'absolute',
    zIndex: 0
  }
});