import { Dimensions } from 'react-native'

const versionNumber = 'v1.0.0';
const colors = {
  primary: "#ff7d00",
  secondary: "#009cff",
  secondaryDark: "#008fe9",
  secondaryLight: "#d8f0ff",
  tertiary: "#ee7fab",
  black: "#333333",
  grayDarkest: "#767676",
  gray: "#969696",
  graySecondary: "#b1b1b1",
  grayTertiary: "#dcdcdc",
  grayLightest: "#f5f5f5",
  text: "#969696",
  white: "#ffffff",
  background: "#fbfaff",
  danger: "#eb1111"
}

const buttons = {
  primary: {
    borderRadius: 10,
    paddingVertical: 12,
    paddingHorizontal: 24,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#fff",
    lineHeight: 30,
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "#000",
    marginRight: 8
  },
  alt: {
    borderRadius: 10,
    paddingVertical: 12,
    paddingHorizontal: 24,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#000",
    lineHeight: 30,
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "transparent",
    marginRight: 8,
    borderColor: '#000',
    borderWidth: 1
  }
}

const defaultStyles = {
  borderRadius: 10,
  gutter: 20,
  padding: 10,
  fontSize: 16,
  headerSize_1: 36,
  headerSize_2: 24,
  headerSize_3: 14,
  headingFont: 'Inter_900Black',
  interReg: 'Inter_400Regular',
  interSemi: 'Inter_600SemiBold'
}

const pageTitle = {
  fontFamily: 'Inter_900Black',
  fontSize: 32
}

const wrapperStyles = {
  flex: 1,
  minHeight: Dimensions.get("window").height,
  backgroundColor: "#fff",
  paddingVertical: 16,
  width: "100%"
}

const circleImage = {
  height: 60,
  width: 60,
  borderRadius: 60,
  backgroundColor: "#f1f1f1",
  marginBottom: 2,
  overflow: "hidden",
  borderColor: colors.graySecondary,
  borderWidth: 1
}

const circleImageLarge = {
  height: 80,
  width: 80,
}

const circleImageXLarge = {
  height: 120,
  width: 120
}

const circleImageSmall = {
  height: 40,
  width: 40,
}

const circleImageXSmall = {
  height: 25,
  width: 25,
}
const noTabsWrapperStyles = {
  minHeight: Dimensions.get("window").height,
  backgroundColor: "#fff",
  paddingVertical: 16,
  width: "100%",
  alignSelf: 'flex-start',
}
const totalGutter = defaultStyles.gutter * 2;

const formStyles = {
  textInput: {
    borderRadius: defaultStyles.borderRadius,
    borderWidth: 1,
    borderColor: colors.grayLightest,
    padding: 10,
    height: 60,
    width: '100%',
    paddingVertical: 6,
    backgroundColor: '#fff',
  },
  dateInput: {
    padding: 10,
    width: Dimensions.get('window').width - totalGutter,
    marginVertical: 16,
    borderRadius: defaultStyles.borderRadius,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingVertical: 14,
    backgroundColor: colors.grayLightest,
    fontSize: 16
  },
  searchInput: {
    padding: 10,
    height: 60,
    width: '100%',
    paddingVertical: 6,
    backgroundColor: '#fff',
  },
  selectInput: {
    padding: 10,
    width: "100%",
    marginVertical: 16,
    borderRadius: defaultStyles.borderRadius,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingVertical: 14,
    backgroundColor: '#fff',
    fontSize: 16,
    borderColor: colors.grayTertiary,
    borderWidth: 1,
    fontWeight: 'bold'
  }
}

const bodyCopy = {
  fontSize: 14,
  color: colors.black,
  lineHeight: 22,
  marginBottom: 16
}

const fallbackImage = 'https://firebasestorage.googleapis.com/v0/b/primary-4a0e7.appspot.com/o/pottymouth%2Fprofiles%2Fdefault-image.jpg?alt=media&token=95612da9-0fac-44d3-96af-a889e664d42d';
const states = [
"Alabama",
"Alaska",
"Arizona",
"Arkansas",
"California",
"Colorado",
"Connecticut",
"Delaware",
"District Of Columbia",
"Florida",
"Georgia",
"Guam",
"Hawaii",
"Idaho",
"Illinois",
"Indiana",
"Iowa",
"Kansas",
"Kentucky",
"Louisiana",
"Maine",
"Maryland",
"Massachusetts",
"Michigan",
"Minnesota",
"Mississippi",
"Missouri",
"Montana",
"Nebraska",
"Nevada",
"New Hampshire",
"New Jersey",
"New Mexico",
"New York",
"North Carolina",
"North Dakota",
"Ohio",
"Oklahoma",
"Oregon",
"Pennsylvania",
"Puerto Rico",
"Rhode Island",
"South Carolina",
"South Dakota",
"Tennessee",
"Texas",
"Utah",
"Vermont",
"Virgin Islands",
"Virginia",
"Washington",
"West Virginia",
"Wisconsin",
"Wyoming"
]
export { 
  versionNumber,
  colors,
  buttons,
  defaultStyles,
  wrapperStyles,
  formStyles,
  noTabsWrapperStyles,
  states,
  bodyCopy,
  circleImageXLarge,
  circleImageLarge,
  circleImageSmall,
  circleImage,
  pageTitle,
  fallbackImage,
  circleImageXSmall
}